import * as Sentry from '@sentry/react'
import { API_URL, SENTRY_RELEASE } from 'env'
import { SnackbarProvider } from 'notistack'
import React from 'react'
import { createRoot } from 'react-dom/client'
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom'
import { App } from './App'
import * as serviceWorker from './serviceWorker'
import { LicenseInfo } from '@mui/x-license-pro'

function sanitizeSensitiveData(input: string) {
  if (input && typeof input === 'string') {
    const parsedInput = JSON.parse(input)
    if (parsedInput.ssnLastFour) {
      parsedInput.ssnLastFour = '****'
      return JSON.stringify(parsedInput)
    }
  }
  return input
}

LicenseInfo.setLicenseKey(
  '3f188ffd0fc120161f3527ece12edefcTz02OTI2MSxFPTE3MTkwODI3Njk0NjksUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI='
)

// See: https://docs.sentry.io/platforms/javascript/performance/instrumentation/automatic-instrumentation/
if (process.env.NODE_ENV === 'production' && SENTRY_RELEASE) {
  const regexString = `^${(API_URL || '').replaceAll('.', '\\.')}`

  Sentry.init({
    dsn: 'https://7a85d927ee0f43ac867c20dc48e517d8@o473102.ingest.sentry.io/5507655',
    release: SENTRY_RELEASE,
    integrations: [
      new Sentry.BrowserTracing({
        tracingOrigins: [new RegExp(regexString)],
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes
        ),
      }),
      new Sentry.Replay(),
    ],
    beforeBreadcrumb: (breadcrumb, hint) => {
      if (breadcrumb.category === 'xhr') {
        const data = {
          requestBody: sanitizeSensitiveData(hint?.xhr.__sentry_xhr__.body),
          response: sanitizeSensitiveData(hint?.xhr.response),
          responseUrl: hint?.xhr.responseURL,
        }
        return { ...breadcrumb, data }
      }
      return breadcrumb
    },
    beforeSend(event, hint) {
      try {
        const setFingerprintTo: string[] = []

        if (event.extra && event.extra.httpStatusCode) {
          setFingerprintTo.push(event.extra.httpStatusCode.toString())
        }

        if (event.request && event.request.url) {
          setFingerprintTo.push(event.request.url)
        }

        if (
          event.exception &&
          event.exception.values &&
          event.exception.values[0]
        ) {
          const exceptionType = event.exception.values[0].type
          if (exceptionType) {
            setFingerprintTo.push(exceptionType)
            event.tags = { ...event.tags, Exception: exceptionType }
          }
        }

        event.fingerprint = setFingerprintTo
      } catch (exception) {
        // In case of an error in the fingerprint logic, still return the event
      }

      return event
    },
    // Enable session replay for 50% of all sessions
    replaysSessionSampleRate: 0.5,
    // Enable session replay for 100% of sessions with errors
    replaysOnErrorSampleRate: 1,
    tracesSampleRate: 0.5,
  })
}

const container = document.getElementById('root')
if (container) {
  const root = createRoot(container)
  root.render(
    <SnackbarProvider
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
    >
      <App />
    </SnackbarProvider>
  )
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
