import {
  Alert,
  AlertColor,
  AlertTitle,
  CircularProgress,
  Link,
} from '@mui/material'
import { getIsReinstatement } from 'api'
import { StepButtons } from 'components/steps/StepButtons'
import { ReinstatementEligibilityInfoMessage } from 'pages/InfoMessage'
import { useMemo } from 'react'
import { useQuery } from 'react-query'
// Exporting default to allow lazy import
// eslint-disable-next-line import/no-default-export
export default function RehireReinstatementEligibility({
  data,
  handleNext,
  navigate,
  setData,
  handlePrev = () => navigate('/'),
}) {
  const { isLoading, isFetching } = useQuery(
    ['isReinstatement', data.employee],
    () => {
      return getIsReinstatement(
        data.employee.id,
        data.effectiveDate,
        data.location.companyCode
      )
    },
    {
      refetchOnWindowFocus: false,
      enabled: !!data.employee || !!data.effectiveDate,
      onSuccess: (data) => {
        setData(data.data.isReinstatement)
      },
    }
  )

  const [
    alertSeverity,
    titleTextPrompt,
    textPrompt,
    textPromptFontSize,
    nextButtonText,
    handleNextButton,
  ] = useMemo((): [
    AlertColor,
    string | null,
    JSX.Element,
    string,
    string,
    () => any,
  ] => {
    if (data.isReinstatement && data.employee?.isRehireable) {
      return [
        'warning',
        null,
        <>
          {' '}
          <ReinstatementEligibilityInfoMessage />
        </>,
        '0.875rem',
        'Next',
        handleNext,
      ]
    }

    if (data.employee?.isRehireable) {
      return [
        'success',
        'This candidate is eligible for rehire.',
        <>
          Please complete{' '}
          <Link
            href="https://jobappnetwork.com/"
            color="inherit"
            underline="always"
          >
            TalentReef
          </Link>{' '}
          again BEFORE starting work
        </>,
        '1.25rem',
        'Return to Homepage',
        () => navigate('/'),
      ]
    }
    return [
      'warning',
      null,
      <>
        Not Eligible for Rehire. <br />
        Termination Reason: {data.employee?.terminationReasonCodeAndDesc}.{' '}
        <br />
        <br />
        If you would like to request an exception, please continue to submit
        this request. <br />
        If both your DL and HR approve the request, you will get an email
        advising you that you may make an offer to the candidate.
      </>,
      '0.875rem',
      'Next',
      handleNext,
    ]
  }, [
    data.isReinstatement,
    data.employee?.isRehireable,
    data.employee?.terminationReasonCodeAndDesc,
    handleNext,
    navigate,
  ])

  return (
    <>
      {(isLoading || isFetching) && (
        <>
          <CircularProgress size="1rem" /> Loading...
        </>
      )}
      {!(isLoading || isFetching) && (
        <>
          <Alert
            style={{ display: 'inline-flex' }}
            variant="outlined"
            severity={alertSeverity}
            sx={{
              maxWidth: '400px',
              fontSize: textPromptFontSize,
            }}
          >
            {titleTextPrompt && (
              <AlertTitle sx={{ fontSize: 20 }}>{titleTextPrompt}</AlertTitle>
            )}
            {textPrompt}
          </Alert>
          <StepButtons
            disabled={false}
            handlePrev={handlePrev}
            handleNext={handleNextButton}
            nextText={nextButtonText}
          />
        </>
      )}
    </>
  )
}
