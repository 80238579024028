import { Autocomplete, Grid, TextField } from '@mui/material'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardHeader from '@mui/material/CardHeader'
import CircularProgress from '@mui/material/CircularProgress'
import Step from '@mui/material/Step'
import StepButton from '@mui/material/StepButton'
import StepLabel from '@mui/material/StepLabel'
import Typography from '@mui/material/Typography'
import useMediaQuery from '@mui/material/useMediaQuery'
import { StaticDatePicker } from '@mui/x-date-pickers-pro'
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { getRosterCorrectionCsv } from 'api'
import StepperWorkflow from 'components/StepperWorkflow'
import { getDefaultEffectiveDate } from 'components/steps/EffectiveDate'
import SelectBetweenTwoRadioButtons from 'components/steps/SelectBetweenTwoRadioButtons'
import { UploadAdpRosterFileDropZone } from 'components/steps/UploadAdpRosterFileDropZone'
import YesOrNoQuestion from 'components/steps/YesOrNoQuestion'
import { useUser } from 'context/Authenticate'
import { Dayjs } from 'dayjs'
import { useClasses } from 'hooks/useClasses'
import {
  KeysToClear,
  useStateClearingReducer,
} from 'hooks/useStateClearingReducer'
import { useTitle } from 'hooks/useTitle'
import { HOME_PATH, UPLOAD_ADP_ROSTER_CSV } from 'link-paths'
import { useSnackbar } from 'notistack'
import { handleNextStep } from 'pages/Home'
import NoRoleAccess from 'pages/NoRoleAccess'
import React, { useCallback, useEffect, useState } from 'react'
import { useMutation } from 'react-query'
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { theme, useContentStyle } from 'theme'
import { StepInfo } from 'types/StepProps'
import { downloadFileOnBrowser, getTimestampedFileName } from 'utils'

const styles = {
  root: {
    minWidth: 450,
    minHeight: 200,
  },
  cardHeader: {
    paddingTop: 0,
  },
  listType: {
    listStyle: 'circle',
  },
  leftAlign: {
    marginLeft: 60,
  },
  fixedWidth: {
    width: '100%',
  },
  bulletList: {
    fontSize: 'medium',
    listStyleType: 'circle',
    textAlign: 'left',
    marginLeft: 40,
    width: 600,
  },
}

const StyledDiv = styled.div`
  height: 1px;
  background-color: #e6e6e6;
  width: 26em;
  padding: 2px 0px 2px;
  margin: 1px auto 1px;
  background-image: linear-gradient(left, white 2%, #e6e6e6 50%, white 98%);
  background-image: -o-linear-gradient(left, white 2%, #e6e6e6 50%, white 98%);
  background-image: -moz-linear-gradient(
    left,
    white 2%,
    #e6e6e6 50%,
    white 98%
  );
  background-image: -webkit-linear-gradient(
    left,
    white 2%,
    #e6e6e6 50%,
    white 98%
  );
  background-image: -ms-linear-gradient(left, white 2%, #e6e6e6 50%, white 98%);
  background-image: -webkit-gradient(
    linear,
    left bottom,
    right bottom,
    color-stop(0.02, white),
    color-stop(0.5, gray),
    color-stop(0.98, white)
  );
`

export enum RosterScheduleKeys {
  Daily = 'Daily',
  BiWeekly = 'Bi-Weekly',
}
export enum BrandGroup {
  WenKkd = `WEN/KKD`,
  EplEveryoneELse = `EPL/Everyone else`,
}

const companies = [
  { name: 'All', code: 'All' },
  { name: 'WKS Frosty Corporation', code: 'FRO' },
  { name: 'W.K.S. Krispy Kreme, LLC', code: 'KKC' },
  { name: 'Sunrise Restaurants', code: 'SUN' },
  { name: 'WKS Restaurant Co', code: 'WKS' },
  { name: 'WKS Pizza Corp', code: 'WPC' },
]

export const selectRosterScheduleStep: StepInfo = {
  stepLabel: 'Roster Schedule',
  cardLabel: 'Do you want to upload the daily or bi-weekly employee roster?',
  url: 'select-roster-schedule',
}

export const selectBrandStep: StepInfo = {
  stepLabel: 'Brand',
  cardLabel: 'Select the brand',
  url: 'select-brand',
}

export const selectLastProcessedCheckDateStep: StepInfo = {
  stepLabel: 'Last Processed Check Date',
  cardLabel: '',
  url: 'last-processed-check-date',
}

export const uploadRosterCsvStep: StepInfo = {
  stepLabel: 'Upload Employee Roster',
  cardLabel: '',
  url: 'upload-roster-csv',
}

export const selectCompanyStep: StepInfo = {
  stepLabel: 'Create Roster Correction CSV',
  cardLabel: 'Select Company',
  url: 'create-roster-correction-csv',
}

export const importCsvIntoAdpStep: StepInfo = {
  stepLabel: 'Import Correction CSV Into ADP',
  cardLabel: '',
  url: 'import-csv-into-adp',
}

export const hasReDownloadedRosterCsvStep: StepInfo = {
  stepLabel: 'Re-Download Roster CSV',
  cardLabel: '',
  url: 're-download-roster-csv',
}

export const reUploadRosterStep: StepInfo = {
  stepLabel: 'Upload New Roster CSV',
  cardLabel: 'Upload New Roster CSV',
  url: 'second-upload',
}

export function calculateSteps(
  steps: ConditionalSteps,
  state: UploadAdpRosterState | undefined
): StepInfo[] {
  return steps.reduce((prev, curr) => {
    if (typeof curr === 'function') {
      const result = curr(state)
      if (result) {
        prev.push(result)
      }
    } else {
      prev.push(curr)
    }
    return prev
  }, [] as StepInfo[])
}
type ConditionalSteps = (
  | StepInfo
  | ((data: UploadAdpRosterState | undefined) => StepInfo | null)
)[]

const stepsInit: ConditionalSteps = [
  selectRosterScheduleStep,
  (data) =>
    data?.rosterSchedule === RosterScheduleKeys.BiWeekly
      ? selectBrandStep
      : null,
  (data) =>
    data?.rosterSchedule === RosterScheduleKeys.BiWeekly
      ? selectLastProcessedCheckDateStep
      : null,
  uploadRosterCsvStep,
  selectCompanyStep,
  importCsvIntoAdpStep,
  hasReDownloadedRosterCsvStep,
  reUploadRosterStep,
]

export interface UploadAdpRosterState {
  companyCode: string
  hasImportedIntoAdp: string
  hasReuploadedRoster: string
  hasReDownloadedRoster: string
  brand: string | undefined
  lastProcessedCheckDate: Dayjs | null
  rosterSchedule:
    | RosterScheduleKeys.Daily
    | RosterScheduleKeys.BiWeekly
    | undefined
}

const initialState: UploadAdpRosterState = {
  companyCode: '',
  hasImportedIntoAdp: '',
  hasReuploadedRoster: '',
  hasReDownloadedRoster: '',
  rosterSchedule: undefined,
  brand: undefined,
  lastProcessedCheckDate: getDefaultEffectiveDate(),
}

const keysToClear: KeysToClear = {
  rosterSchedule: {
    keys: [
      'rosterSchedule',
      'brand',
      'companyCode',
      'hasImportedIntoAdp',
      'hasReuploadedRoster',
    ],
    url: selectRosterScheduleStep.url,
  },
  brand: {
    keys: ['brand'],
    url: selectBrandStep.url,
  },
  companyCode: {
    keys: ['companyCode', 'hasImportedIntoAdp', 'hasReuploadedRoster'],
    url: selectCompanyStep.url,
  },
  hasImportedIntoAdp: {
    keys: ['hasImportedIntoAdp', 'hasReuploadedRoster'],
    url: importCsvIntoAdpStep.url,
  },
  hasReuploadedRoster: {
    keys: ['hasReuploadedRoster'],
    url: reUploadRosterStep.url,
  },
}

// used in lazy import
// eslint-disable-next-line import/no-default-export
export default function UploadAdpRoster() {
  useTitle('Upload ADP Roster')
  const [steps, setSteps] = useState(calculateSteps(stepsInit, undefined))
  const [completed, setCompleted] = useState<Record<string, boolean>>({})
  const { enqueueSnackbar } = useSnackbar()
  const [data, dispatch] = useStateClearingReducer(
    keysToClear,
    steps,
    initialState,
    setCompleted
  )
  const {
    mutateAsync: getCSV,
    isError,
    isLoading,
    isSuccess,
  } = useMutation(getRosterCorrectionCsv, {
    onSuccess: (data) => {
      if (!data.includes(',,')) {
        enqueueSnackbar(`No records to process.`, {
          variant: 'error',
        })
      } else {
        const fileName = getTimestampedFileName('Roster_Correction')
        // Browser downloads file.
        downloadFileOnBrowser(data, fileName)
      }
    },
    onError: (data) => {
      enqueueSnackbar(`${data}`, {
        variant: 'error',
      })
    },
  })

  const handleCreateCsv = async () => {
    getCSV(data.companyCode)
  }

  const clx: any = useClasses(styles)
  const contentStyle: any = useClasses(useContentStyle)
  const matches = useMediaQuery(theme.breakpoints.up('sm'))

  const loc = useLocation()
  const navigate = useNavigate()
  const getURL = useCallback(
    (path?: string) => `${UPLOAD_ADP_ROSTER_CSV}/${path}`,
    []
  )

  /**
   * If we navigate directly to a step in the flow but do not have a valid employee selected,
   * need to shortciruit back to first step.
   */
  useEffect(() => {
    if (
      (!loc.pathname.includes(selectRosterScheduleStep.url) &&
        data.rosterSchedule === undefined) ||
      loc.pathname === UPLOAD_ADP_ROSTER_CSV
    ) {
      navigate(getURL(selectRosterScheduleStep.url), { replace: true })
    }
  }, [loc.pathname, getURL, navigate, data.rosterSchedule])

  useEffect(() => {
    const newSteps = calculateSteps(stepsInit, data)
    setSteps(newSteps)
  }, [data])
  /**
   * Manage current step based on path name and derive numerical step number for Stepper component
   * (URL is source of truth)
   */

  const [step, setStep] = useState(0)
  useEffect(() => {
    setStep(steps.findIndex((val) => loc.pathname.includes(val.url)))
  }, [loc.pathname, step, steps])

  /**
   * Leaving it to the individual step logic below to determine whether to show back/next
   * buttons instead of handling index out of bounds here
   */
  const getNextPage = useCallback(() => {
    if (steps[step].url === reUploadRosterStep.url) return HOME_PATH
    return getURL(steps[step + 1].url)
  }, [step, steps, getURL])
  const getPrevPage = useCallback(() => {
    if (step === 0) return '/'
    return getURL(steps[step - 1].url)
  }, [step, steps, getURL])

  /**
   * Every time user goes next, mark that step as completed. This will allow
   * users to visit previously completed steps.
   */
  const handleNext = () => {
    handleNextStep(completed, setCompleted, steps, step, navigate, getNextPage)
  }

  const { roles } = useUser()
  if (!roles.canExportRosterCorrectionFile) {
    return (
      <NoRoleAccess
        roleName={'Menu - Payroll - ADP and TalentReef Roster Correction'}
      />
    )
  }
  return (
    <StepperWorkflow
      activeStep={step}
      nonLinear={data.rosterSchedule !== undefined}
      stepperChildren={steps.map((stepInfo, idx) => {
        const isCompleted = completed[steps[idx].url]
        return (
          <Step key={idx}>
            {/* Only allow completed steps to be selected */}
            <StepButton
              disabled={step < idx && !isCompleted}
              onClick={() => navigate(getURL(steps[idx].url))}
              // completed={isCompleted}
            >
              <StepLabel>{matches ? stepInfo.stepLabel : ''}</StepLabel>
            </StepButton>
          </Step>
        )
      })}
    >
      <Box className={contentStyle.content}>
        <Typography variant="h4" gutterBottom>
          Upload ADP Roster
        </Typography>
        <Card className={clx.root}>
          <CardHeader title={steps[step]?.cardLabel ?? ''} />
          <CardContent>
            <React.Suspense fallback={<CircularProgress />}>
              <Routes>
                <Route
                  path={selectRosterScheduleStep.url}
                  element={
                    <>
                      <SelectBetweenTwoRadioButtons
                        valueToUpdate={data.rosterSchedule}
                        setData={(value) => dispatch({ rosterSchedule: value })}
                        handleNext={handleNext}
                        getPrevPage={getPrevPage}
                        optionOneText={`${RosterScheduleKeys.Daily} Roster Schedule`}
                        optionTwoText={`${RosterScheduleKeys.BiWeekly} Roster Schedule`}
                        optionOneValue={RosterScheduleKeys.Daily}
                        optionTwoValue={RosterScheduleKeys.BiWeekly}
                      />
                    </>
                  }
                />
                <Route
                  path={selectBrandStep.url}
                  element={
                    <>
                      <SelectBetweenTwoRadioButtons
                        valueToUpdate={data.brand}
                        setData={(value) => dispatch({ brand: value })}
                        handleNext={handleNext}
                        getPrevPage={getPrevPage}
                        optionOneText={BrandGroup.WenKkd}
                        optionTwoText={BrandGroup.EplEveryoneELse}
                        optionOneValue={BrandGroup.WenKkd}
                        optionTwoValue={BrandGroup.EplEveryoneELse}
                      />
                    </>
                  }
                />
                <Route
                  path={selectLastProcessedCheckDateStep.url}
                  element={
                    <>
                      <Typography
                        variant="h5"
                        color="textPrimary"
                        style={{ marginBottom: '20px' }}
                      >
                        Specific <b>{data.brand}</b> Check Date
                      </Typography>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <StaticDatePicker<Dayjs>
                          // variant="static"
                          openTo="day"
                          disableHighlightToday={true}
                          value={
                            data && data.lastProcessedCheckDate === null
                              ? null
                              : data.lastProcessedCheckDate
                          }
                          onChange={(value) => {
                            dispatch({
                              lastProcessedCheckDate: value ? value : null,
                            })
                          }}
                          orientation="landscape"
                          shouldDisableDate={(day) => {
                            if (data.brand === BrandGroup.WenKkd) {
                              return day?.day() !== 5
                            } else {
                              return day?.day() !== 3
                            }
                          }}
                          slotProps={{ actionBar: { actions: [] } }}
                          data-testid="date-picker"
                        />
                      </LocalizationProvider>
                      <Box my={3} />
                      <Box display="flex" justifyContent="space-between">
                        <Button onClick={() => navigate(getPrevPage())}>
                          Back
                        </Button>
                        <Button
                          variant="contained"
                          color="primary"
                          disabled={
                            data && data.lastProcessedCheckDate === null
                          }
                          onClick={handleNext}
                        >
                          Next
                        </Button>
                      </Box>
                    </>
                  }
                />
                <Route
                  path={uploadRosterCsvStep.url}
                  element={
                    <>
                      <UploadAdpRosterFileDropZone
                        handleNext={handleNext}
                        getPrevPage={getPrevPage}
                        data={data}
                        nextButtonText={'Next'}
                      />
                    </>
                  }
                />
                <Route
                  path={selectCompanyStep.url}
                  element={
                    <>
                      <Box alignItems="center" ml={22}>
                        <Autocomplete
                          id="combo-box-demo"
                          options={companies}
                          defaultValue={companies[0]}
                          value={companies.find(
                            (p) => p.code === data.companyCode
                          )}
                          isOptionEqualToValue={(option) =>
                            option?.code === data.companyCode
                          }
                          onInputChange={() => dispatch({ companyCode: 'All' })}
                          onChange={(_, newValue) =>
                            dispatch({ companyCode: newValue?.code })
                          }
                          getOptionLabel={(option) => option.name}
                          style={{ width: 300 }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Choose a company"
                              variant="outlined"
                            />
                          )}
                        />
                      </Box>
                      <Box my={3} />
                      <Box alignItems="center">
                        <Typography variant="body1">
                          The following changes are included in the file:
                        </Typography>
                        <Grid
                          container
                          justifyContent="center"
                          alignItems="center"
                          className={clx.fixedWidth}
                        >
                          <ul className={clx.bulletList}>
                            <StyledDiv />
                            <li>
                              Updates all restaurant employees{"'"} Home Cost
                              Number and EEO Establishment to match the ADP
                              Location Code. 2735, 2736, 2735N, 9998, and 9999
                              are not included
                            </li>
                            <StyledDiv />
                            <li>
                              AM-ITs, Acting AMs, AMs, and GM-ITs created in ADP
                              today or yesterday are set to IsManagement = false
                            </li>
                            <StyledDiv />
                            <li>
                              All GMs I, II, III, Acting GMs, and Sr GMs are set
                              to IsManagement = true
                            </li>
                            <StyledDiv />
                            <li>Personal and Work Emails are corrected</li>
                            <StyledDiv />
                          </ul>
                        </Grid>
                      </Box>
                      <Box my={3} />
                      <Button
                        variant="contained"
                        color="secondary"
                        type="submit"
                        fullWidth={false}
                        onClick={handleCreateCsv}
                        disabled={
                          data.companyCode === '' ||
                          data.companyCode === undefined ||
                          isLoading
                        }
                      >
                        {data.companyCode === '' ||
                        data.companyCode === undefined ||
                        !isLoading
                          ? 'Create CSV'
                          : 'processing...'}
                      </Button>
                      <Box my={3} />
                      <Box display="flex" justifyContent="space-between">
                        <Button onClick={() => navigate(getPrevPage())}>
                          Back
                        </Button>
                        <Button
                          variant="contained"
                          color="primary"
                          disabled={isError || !isSuccess}
                          onClick={handleNext}
                        >
                          Next
                        </Button>
                      </Box>
                    </>
                  }
                />
                <Route
                  path={importCsvIntoAdpStep.url}
                  element={
                    <>
                      <Typography
                        variant="h5"
                        color="textPrimary"
                        style={{ marginBottom: '20px' }}
                      >
                        Have you <b>imported</b> the roster correction CSV into
                        ADP?
                      </Typography>
                      <YesOrNoQuestion
                        hasTakenAction={data.hasImportedIntoAdp}
                        setData={(value) =>
                          dispatch({ hasImportedIntoAdp: value })
                        }
                        handleNext={handleNext}
                        navigate={navigate}
                        getPrevPage={getPrevPage}
                        isFinalState={false}
                      />
                    </>
                  }
                />
                <Route
                  path={hasReDownloadedRosterCsvStep.url}
                  element={
                    <>
                      <Typography
                        variant="h5"
                        color="textPrimary"
                        style={{ marginBottom: '20px' }}
                      >
                        Have you <b>re-downloaded</b> the roster CSV from ADP?
                      </Typography>
                      <YesOrNoQuestion
                        hasTakenAction={data.hasReDownloadedRoster}
                        setData={(value) =>
                          dispatch({ hasReDownloadedRoster: value })
                        }
                        handleNext={handleNext}
                        navigate={navigate}
                        getPrevPage={getPrevPage}
                        isFinalState={false}
                      />
                    </>
                  }
                />
                <Route
                  path={reUploadRosterStep.url}
                  element={
                    <>
                      <UploadAdpRosterFileDropZone
                        handleNext={handleNext}
                        getPrevPage={getPrevPage}
                        data={data}
                        nextButtonText={'Finished'}
                      />
                    </>
                  }
                />
              </Routes>
            </React.Suspense>
          </CardContent>
        </Card>
      </Box>
    </StepperWorkflow>
  )
}
