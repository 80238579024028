export abstract class EplToSageAccount {
  id?: number
  brandItemGlCode?: string
  brandItemGlCodeDescription?: string
  dcItemNumber?: string
  dcItemNumberDescription?: string
  sageAcct?: string
  sageAcctDescription?: string
  createdDateTimeUtc?: Date
}

export class EplToSageAccountRow extends EplToSageAccount {
  isAddedRow = false
  newMappingType?: string
  newMappingValue?: string
  newMappingDescription?: string
}

export const brandItemGlCode = 'brandItemGlCode'
export const dcItemNumber = 'dcItemNumber'
