import ArrowDownward from '@mui/icons-material/ArrowDownward'
import { LineItem } from 'components/LineItem'
import { useUser } from 'context/Authenticate'
import { RateUnit, RATE_UNIT_LABEL } from 'types/RateUnit'
import { StatusChangeResponseBase } from 'types/StatusChangeResponse'

interface TitleInfo {
  code: string
  description: string
  rate: number
  jobFunctionCode?: string
  jobFunctionCodeDesc?: string
  jobFunctionPosCode?: string
}

interface JobTitleDetails {
  oldTitle: TitleInfo
  newTitle: TitleInfo
  rateUnit: RateUnit
  oldRateUnit: RateUnit
}

export function JobTitleInfo({
  oldTitle,
  newTitle,
  rateUnit,
  oldRateUnit,
}: JobTitleDetails) {
  const { roles } = useUser()
  const oldJobFunctionDisplayText = roles.canViewPractitionerChangeReasons
    ? `(${oldTitle?.jobFunctionCode}) ${oldTitle?.jobFunctionPosCode} ${oldTitle?.jobFunctionCodeDesc} (primary)`
    : `${oldTitle?.jobFunctionPosCode} ${oldTitle?.jobFunctionCodeDesc} (primary)`
  const newJobFunctionDisplayText = roles.canViewPractitionerChangeReasons
    ? `(${newTitle?.jobFunctionCode}) ${newTitle?.jobFunctionPosCode} ${newTitle?.jobFunctionCodeDesc} (primary)`
    : `${newTitle?.jobFunctionPosCode} ${newTitle?.jobFunctionCodeDesc} (primary)`
  return (
    <>
      <LineItem
        textAlign="center"
        value={`${oldTitle.code} - ${oldTitle.description}`}
        dataTestId="old-job-code-current"
      />
      {oldTitle.jobFunctionCode &&
        oldTitle.jobFunctionCodeDesc &&
        oldTitle.jobFunctionPosCode && (
          <LineItem
            textAlign="center"
            value={oldJobFunctionDisplayText}
            dataTestId="old-job-code-current"
          />
        )}
      <LineItem
        textAlign="center"
        dataTestId="current-rate-fixed"
        value={
          <>
            ${oldTitle.rate.toFixed(2)}
            <br />
            {RATE_UNIT_LABEL[oldRateUnit]}
          </>
        }
      />
      <ArrowDownward />
      <LineItem
        textAlign="center"
        value={
          newTitle.code && newTitle.description
            ? `${newTitle.code} - ${newTitle.description}`
            : ``
        }
        dataTestId="new-job"
      />
      {newTitle.jobFunctionCode &&
        newTitle.jobFunctionCodeDesc &&
        newTitle.jobFunctionPosCode && (
          <LineItem
            textAlign="center"
            value={newJobFunctionDisplayText}
            dataTestId="new-job-code-current"
          />
        )}
      <LineItem
        textAlign="center"
        dataTestId="rate-fixed"
        value={
          <>
            ${newTitle.rate.toFixed(2)}
            {newTitle.rate === oldTitle.rate && ' (unchanged)'}
            <br />
            {RATE_UNIT_LABEL[rateUnit]}
          </>
        }
      />
    </>
  )
}

export function JobTitleDetail({
  statusChangeResponse: response,
}: {
  statusChangeResponse: StatusChangeResponseBase
}) {
  const details = response.details

  return (
    <JobTitleInfo
      oldTitle={{
        code: details.existingAdpJobTitleCode,
        description: details.existingJobTitleDescription,
        rate: details.existingRate,
      }}
      newTitle={{
        code: details.newAdpJobTitleCode,
        description: details.newJobTitleDescription,
        rate: details.newRate,
      }}
      oldRateUnit={details.existingRateUnit}
      rateUnit={details.newRateUnit}
    />
  )
}
