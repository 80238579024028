import { Button, Grid } from '@mui/material'
import { postRequestCompleteManuallyInPOS } from 'api'
import { useUser } from 'context/Authenticate'
import { useSnackbar } from 'notistack'
import { goBackToRequestListPage } from 'pages/StatusChangeDetail'
import { useMutation, useQueryClient } from 'react-query'
import { useLocation, useNavigate } from 'react-router-dom'
import { QueryNames } from 'types/Queries'
import { StatusChangeResponseBase } from 'types/StatusChangeResponse'

export function CompleteManuallyInPOSSection({
  details,
  disabled,
}: {
  details: StatusChangeResponseBase
  disabled: boolean
}) {
  return (
    <>
      <Grid item>
        <ManuallyCompleteInPOS id={details.id} disabled={disabled} />
      </Grid>
    </>
  )
}

/**
 * Logic and requests for completed button (shown only to users with correct role)
 */
function ManuallyCompleteInPOS({
  id,
  disabled,
}: {
  id: StatusChangeResponseBase['id']
  disabled: boolean
}) {
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const location = useLocation()
  const user = useUser()
  const { enqueueSnackbar } = useSnackbar()

  const { mutateAsync: complete, isLoading } = useMutation(
    postRequestCompleteManuallyInPOS,
    {
      onSuccess: () => {
        enqueueSnackbar(`Request (id: ${id}) audit trail has been updated`, {
          variant: 'success',
        })
        queryClient.removeQueries(QueryNames.Requests)
        queryClient.removeQueries(QueryNames.RequestDetail)

        goBackToRequestListPage(navigate, location, user)
      },
    }
  )
  return (
    <Button
      variant="contained"
      color="secondary"
      fullWidth
      disabled={isLoading || disabled}
      onClick={() => complete(id)}
    >
      Complete Manually in POS
    </Button>
  )
}
