import { Link } from '@mui/material'
import Button from '@mui/material/Button'
import Menu, { MenuProps } from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { styled } from '@mui/material/styles'
import { useUser } from 'context/Authenticate'
import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { theme } from 'theme'

const StyledMenu = styled((props: MenuProps) => (
  <Menu elevation={0} {...props} />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    backgroundColor: theme.palette.secondary.main,
  },
}))

export type BaseMenuItemObject = {
  name: string
  path: string
  role?: string
  external?: boolean
}
export type MenuItemObject = BaseMenuItemObject & {
  subMenuItems: BaseMenuItemObject[]
}

type Props = { menuItem: MenuItemObject }

// eslint-disable-next-line import/no-default-export
export default function MenuItemBar({ menuItem }: Props) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const { roles } = useUser()

  const MenuItemLink = ({ item }: { item: BaseMenuItemObject }) => {
    if (item.external) {
      return (
        <Link
          target="_blank"
          href={item.path}
          sx={{
            textDecoration: 'none',
            color: 'white',
          }}
        >
          <MenuItem sx={{ width: '100%' }}>{item.name}</MenuItem>
        </Link>
      )
    }
    return (
      <Link
        component={RouterLink}
        to={item.path}
        onClick={() => handleClose()}
        sx={{
          textDecoration: 'none',
          color: 'white',
        }}
      >
        <MenuItem sx={{ width: '100%' }}>{item.name}</MenuItem>
      </Link>
    )
  }

  return (
    <>
      {(!menuItem.role || roles[menuItem.role]) && (
        <>
          {menuItem.subMenuItems && menuItem.subMenuItems.length > 0 ? (
            <>
              <Button
                data-testid={`navigation-${menuItem.name}`}
                aria-controls="customized-menu"
                aria-haspopup="true"
                variant="contained"
                color="primary"
                onClick={handleClick}
                sx={{
                  borderRadius: 1,
                  textTransform: 'none',
                  '&:hover': {
                    background: theme.palette.secondary.main,
                  },
                  boxShadow: 'none',
                }}
              >
                {menuItem.name}
              </Button>
              <StyledMenu
                id="customized-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                MenuListProps={{ onMouseLeave: handleClose }}
              >
                {menuItem.subMenuItems.map((item, i) => {
                  return (
                    (!item.role || roles[item.role]) && (
                      <MenuItemLink item={item} key={`${i}-${item.path}`} />
                    )
                  )
                })}
              </StyledMenu>
            </>
          ) : (
            <>
              <Button
                data-testid={`navigation-${menuItem.name}`}
                aria-controls="customized-menu"
                aria-haspopup="true"
                variant="contained"
                color="primary"
                sx={{
                  borderRadius: 1,
                  textTransform: 'none',
                  '&:hover': {
                    background: theme.palette.secondary.main,
                  },
                }}
              >
                <Link
                  component={RouterLink}
                  to={menuItem.path}
                  style={{
                    textDecoration: 'none',
                    color: 'white',
                  }}
                >
                  {menuItem.name}
                </Link>
              </Button>
            </>
          )}
        </>
      )}
    </>
  )
}
