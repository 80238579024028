import TrendingDown from '@mui/icons-material/TrendingDown'
import TrendingFlat from '@mui/icons-material/TrendingFlat'
import TrendingUp from '@mui/icons-material/TrendingUp'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { LineItem } from 'components/LineItem'
import { RateUnit, RATE_UNIT_LABEL } from 'types/RateUnit'

export function RateTrendDisplay({
  newRate,
  oldRate,
  rateUnit,
}: {
  newRate: number
  oldRate: number
  rateUnit: RateUnit
}) {
  const rateFixed = newRate.toFixed(2)
  const currentRateFixed = oldRate.toFixed(2)

  let icon, color
  if (oldRate === newRate) {
    icon = <TrendingFlat />
    color = 'inherit'
  } else if (oldRate < newRate) {
    icon = <TrendingUp />
    color = 'success.main'
  } else {
    icon = <TrendingDown />
    color = 'warning.main'
  }

  return (
    <Box display="flex" flexDirection="column">
      <Box
        display="flex"
        flexDirection="row"
        alignContent="center"
        justifyContent="center"
      >
        <Box component="span" color="grey.700" data-testid="current-rate-fixed">
          ${currentRateFixed}
        </Box>
        <Box mx={1} />
        <Box component="span" color={color} data-testid="rate-trend-icon">
          {icon}
        </Box>
        <Box mx={1} />
        <Box component="span" color={color} data-testid="rate-fixed">
          ${rateFixed}
        </Box>
      </Box>
      <LineItem
        label="Pay Type"
        value={RATE_UNIT_LABEL[rateUnit]}
        dataTestId="pay-type"
        textAlign="center"
      />
      <Typography></Typography>
    </Box>
  )
}
