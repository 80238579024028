import { createTheme } from '@mui/material/styles'
const colors = {
  gray100: 'rgb(200, 200, 200)',
  gray200: 'rgb(58, 58, 58)',
  green100: '#85d58c',
  lightBlue100: 'rgba(203,223,255, 0.5)',
  brewersMedBlue: '#4C8CEE',
  brewersLightBlue: '#95D0F0',
  brewersLightRed: '#f8877f',
  brewersMedRed: '#f63d00',
  brewersDarkRed: '#C23000',
  brewersLightGreen: '#90e59a',
  brewersMedGreen: '#24A133',
  brewersDarkGreen: '#20912e',
  wksLogoRed: '#CF3339',
}
export const discountTheme = createTheme({
  typography: {
    fontFamily: 'Montserrat',
    fontSize: 18,
  },
  palette: {
    text: {
      primary: '#8D8D8D',
      secondary: '#DCDCDC',
    },
    primary: {
      main: '#FFFFFF',
      light: '#B0B0B0',
      dark: '#000000',
    },
    secondary: { main: '#cf3339' },
    background: {
      default: '#333333',
    },
    colors,
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        body2: {
          fontSize: 16,
        },
      },
    },
  },
})

export const discountButtonStyle = {
  border: `2px solid ${discountTheme.palette.primary.main}`,
  color: discountTheme.palette.primary.main,
  width: `280px`,
}

export const footerStyle = {
  flexShrink: 0,
  minHeight: '90px',
  maxWidth: '800px',
  width: '95%',
  marginLeft: 'auto',
  marginRight: 'auto',
  textAlign: 'center' as const,
}
