import { Box, Button } from '@mui/material'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormGroup from '@mui/material/FormGroup'
import Radio from '@mui/material/Radio/Radio'
import RadioGroup from '@mui/material/RadioGroup/RadioGroup'
import React, { useMemo } from 'react'
import { useNavigate } from 'react-router'

export type SelectBetweenTwoRadioButtonsProps = {
  handleNext: () => void
  getPrevPage: () => string
  setData: (string) => void
  valueToUpdate: string | undefined
  optionOneText: string
  optionTwoText: string
  optionOneValue: string
  optionTwoValue: string
}

// eslint-disable-next-line import/no-default-export
export default function SelectBetweenTwoRadioButtons({
  setData,
  valueToUpdate,
  handleNext,
  getPrevPage,
  optionOneText,
  optionTwoText,
  optionOneValue,
  optionTwoValue,
}: SelectBetweenTwoRadioButtonsProps) {
  const radioGroupKey = useMemo(() => {
    if (typeof valueToUpdate === 'undefined') {
      return undefined
    } else if (valueToUpdate === optionTwoValue) {
      return optionTwoText
    } else if (valueToUpdate === optionOneValue) {
      return optionOneText
    } else {
      return undefined
    }
  }, [
    valueToUpdate,
    optionTwoValue,
    optionOneValue,
    optionTwoText,
    optionOneText,
  ])

  const navigate = useNavigate()
  return (
    <>
      <FormControl component="fieldset">
        <FormGroup style={{ alignItems: 'left' }}>
          <RadioGroup
            aria-label="make-primary"
            name="make-primary"
            value={radioGroupKey}
            onChange={(e) => {
              e.target.value === optionOneText
                ? setData(optionOneValue)
                : setData(optionTwoValue)
            }}
          >
            <FormControlLabel
              value={optionOneText}
              control={<Radio />}
              label={optionOneText}
            />
            <FormControlLabel
              value={optionTwoText}
              control={<Radio />}
              label={optionTwoText}
            />
          </RadioGroup>
        </FormGroup>
      </FormControl>
      <Box display="flex" justifyContent="space-between">
        <Button onClick={() => navigate(getPrevPage())}>Back</Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleNext}
          disabled={radioGroupKey === undefined}
        >
          Next
        </Button>
      </Box>
    </>
  )
}
