import { Box } from '@mui/material'
import { StaticDatePicker } from '@mui/x-date-pickers-pro'
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { useUser } from 'context/Authenticate'
import dayjs, { Dayjs } from 'dayjs'
import { DateRange } from 'types/Employee'

export interface DatePickerProperties {
  minDate: Dayjs | undefined
  maxDate: Dayjs | undefined
}

interface StandardDatePickerProps {
  dateValue?: Dayjs | null
  fiscalWeekStartDate?: string
  DatePickerProps?: DatePickerProperties
  restrictToFiscalWeekStartDate?: boolean
  onChange: (any) => void
  dateRange?: DateRange | undefined
}

export function StandardDatePicker({
  dateValue,
  fiscalWeekStartDate,
  DatePickerProps,
  restrictToFiscalWeekStartDate,
  onChange,
  dateRange,
}: StandardDatePickerProps) {
  const { roles } = useUser()
  const defaultDatePickerProps = {
    minDate: dateRange ? dayjs(dateRange?.minDateRange) : undefined,
    maxDate: dateRange ? dayjs(dateRange?.maxDateRange) : undefined,
  }
  const mergedDatePickerProps: DatePickerProperties = {
    ...defaultDatePickerProps,
    ...DatePickerProps,
  }
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box
        sx={{
          '.PrivateDatePickerToolbar-penIcon': {
            visibility: 'hidden',
          },
        }}
        data-testid="date-picker"
      >
        <StaticDatePicker<Dayjs>
          orientation="landscape"
          openTo="day"
          value={dateValue}
          onChange={onChange}
          slotProps={{ actionBar: { actions: [] } }}
          disableHighlightToday={true}
          defaultCalendarMonth={
            mergedDatePickerProps.minDate !== undefined &&
            mergedDatePickerProps.minDate > dayjs()
              ? mergedDatePickerProps.minDate
              : dayjs()
          }
          shouldDisableDate={(day: Dayjs) => {
            if (!restrictToFiscalWeekStartDate) return false
            if (roles.canSelectExtendedEffectiveDateRanges) return false
            const payPeriodDay = fiscalWeekStartDate === 'Monday' ? 1 : 4
            return day?.day() !== payPeriodDay
          }}
          {...mergedDatePickerProps}
        />
      </Box>
    </LocalizationProvider>
  )
}
