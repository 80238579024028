import { createTheme } from '@mui/material/styles'

const colors = {
  gray100: 'rgb(200, 200, 200)',
  gray200: 'rgb(58, 58, 58)',
  green100: '#85d58c',
  lightBlue100: 'rgba(203,223,255, 0.5)',
  brewersMedBlue: '#4C8CEE',
  brewersLightBlue: '#95D0F0',
  brewersLightRed: '#f8877f',
  brewersMedRed: '#f63d00',
  brewersDarkRed: '#C23000',
  brewersLightGreen: '#90e59a',
  brewersMedGreen: '#24A133',
  brewersDarkGreen: '#20912e',
  wksLogoRed: '#CF3339',
}
declare module '@mui/material/styles/createPalette' {
  interface Palette {
    colors: typeof colors
  }
  interface PaletteOptions {
    colors: typeof colors
  }
}

export const theme = createTheme({
  typography: {
    fontFamily: 'Lato, sans-serif',
  },
  palette: {
    primary: {
      light: '#002DA5', // instead of UI pick '#405177',
      main: '#13293b',
      dark: '#000022',
      contrastText: '#fff',
    },
    secondary: {
      light: '#fffa4d',
      main: '#ffc721',
      dark: '#7f8c8d',
      contrastText: '#037777777777',
    },
    background: {
      default: '#E1E8F0',
    },
    error: {
      light: colors.brewersLightRed,
      main: colors.brewersMedRed,
      dark: colors.brewersDarkRed,
    },
    success: {
      light: colors.brewersLightGreen,
      main: colors.brewersMedGreen,
      dark: colors.brewersDarkGreen,
    },
    colors,
  },
  components: {
    MuiFormLabel: {
      styleOverrides: {
        root: {
          '&.MuiFocused': {
            color: '#000000',
          },
          color: '#000000',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          '&.MuiFocused': {
            color: '#000000',
          },
          color: '#000000',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          '&.MuiFocused': {
            color: '#000000',
          },
          color: '#000000',
        },
      },
    },
    MuiLink: {
      defaultProps: {
        underline: 'hover',
      },
    },
    MuiSnackbar: {
      styleOverrides: {
        root: {
          textAlign: 'center',
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          fontWeight: 700,
        },
      },
    },
    // Wasn't able to extend types from https://material-ui-pickers.dev/guides/css-overrides#typescript
    // could be outdated
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    MuiPickersStaticWrapper: {
      staticWrapperRoot: {
        border: '1px solid #13293b',
        borderRadius: 4,
      },
    },
    MuiPickersCalendar: {
      transitionContainer: {
        height: '250px',
      },
      week: {
        display: 'flex',
        justifyContent: 'center',
        height: '40px',
      },
    },
  },
})

export const useContentStyle = {
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginTop: '3rem',
  },
}

export const useMainContainer = {
  mainContainer: {
    minHeight: 'calc(100vh - 220px)',
  },
}
