import { Grid } from '@mui/material'
import { ReactNode } from 'react'

export function TopToolbarGrid({ children }: { children: ReactNode }) {
  return (
    <Grid
      container
      justifyContent="space-between"
      alignItems="center"
      style={{ flexWrap: 'nowrap' }}
    >
      {children}
    </Grid>
  )
}
