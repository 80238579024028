import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import MuiTableCell from '@mui/material/TableCell'
import { styled } from '@mui/material/styles'
import { GridLineItem } from 'components/LineItem'
import dayjs from 'dayjs'
import { useClasses } from 'hooks/useClasses'
import React from 'react'
import { theme } from 'theme'
import { RateUnit } from 'types/RateUnit'
import {
  StatusChangeDetailsTermination,
  StatusChangeResponseBase,
} from 'types/StatusChangeResponse'
import { GroupedWorkedDays, WorkedDay } from 'types/TerminationChangeRequest'
import { HoursFromPos, VacationAndSickHour } from 'types/TerminationDetails'
const FinalCheckRules = React.lazy(
  () => import('components/termination/FinalCheckRules')
)

const styles = (theme) => ({
  root: {
    width: 712,
    position: 'relative',
  },
  boldBackground: {
    fontWeight: 700,
    backgroundColor: '#d6dbdf',
    '& th': {
      textAlign: 'center',
    },
    '& td': {
      textAlign: 'center',
    },
  },
  centerAlignedDiv: {
    '& th': {
      textAlign: 'center',
    },
    '& td': {
      textAlign: 'center',
    },
  },
  leftAlignedDiv: {
    '& td': {
      textAlign: 'left',
    },
  },
  bold: {
    fontWeight: 700,
  },
  hoursToPos: {
    maxHeight: 670,
    overflow: 'auto',
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  break: {
    display: 'flex',
  },
  [`@media print`]: {
    root: {
      display: 'block',
    },
    wrapper: {
      display: 'block',
    },
    break: {
      pageBreakBefore: 'always',
    },
  },
})

const BorderLessCell = styled(MuiTableCell)(({ theme }) => ({
  root: {
    borderBottom: 'none',
    width: '80%',
  },
}))

const BorderLessRightAlignedCell = styled(MuiTableCell)(({ theme }) => ({
  root: {
    borderBottom: 'none',
    textAlign: 'right',
  },
}))

// eslint-disable-next-line import/no-default-export
export default function FinalCheck({
  data,
}: {
  data: StatusChangeResponseBase
}) {
  const clx: any = useClasses(styles(theme))
  const details = data.details as StatusChangeDetailsTermination
  const finalCheckWorkedDays = details.finalCheckWorkedDays
  const hoursFromPos = details.hoursFromPos
  const vacationAndSickHour = details.vacationAndSickHour
  const remainingWorkHours = details.remainingWorkHours
  return (
    <Card className={clx.root}>
      <CardHeader />
      <CardContent>
        <Box className={clx.wrapper}>
          <FinalCheckRules
            finalCheckRules={details.finalCheckRules}
            state={data.location?.state}
            isVoluntary={details.isVoluntary}
          />
          &nbsp;
          {finalCheckWorkedDays &&
            details.existingRateUnit === RateUnit.HOURLY && (
              <RequestorSubmittedHours days={finalCheckWorkedDays} />
            )}
          {finalCheckWorkedDays &&
            details.existingRateUnit === RateUnit.SALARY && (
              <RequestorSubmittedSalary days={finalCheckWorkedDays} />
            )}
          {!finalCheckWorkedDays && remainingWorkHours !== undefined && (
            <GridLineItem
              label="Remaining work hours"
              value={remainingWorkHours}
              dataTestId="hours-remaining"
              textAlign="center"
            />
          )}
          &nbsp;
          {hoursFromPos && <HoursFromPosDetail hours={details.hoursFromPos} />}
          &nbsp;
          <Mileage
            notExportedTotal={details.mileageNotExportedTotal}
            exportedRecentlyTotal={details.mileageExportedRecentlyTotal}
          />
          &nbsp;
          {vacationAndSickHour && (
            <VacationAndSickHourDetail
              vacationAndSickHour={details.vacationAndSickHour}
            />
          )}
          <PaymentSelctionTypeDetail
            paySelectionType={details.paySelectionType}
          />
        </Box>
        <div className={clx.break}></div>
      </CardContent>
    </Card>
  )
}

function RequestorSubmittedHours({ days }: { days: GroupedWorkedDays[] }) {
  const clx: any = useClasses(styles)

  return (
    <>
      <Typography variant="h6" color="textSecondary">
        Requestor Submitted Hours
      </Typography>
      <TableContainer>
        <Table stickyHeader size="small">
          <TableHead>
            <TableCell align="center">Date</TableCell>
            <TableCell align="center">Regular Hours</TableCell>
            <TableCell align="center">Overtime Hours</TableCell>
          </TableHead>
          {days.map((week: GroupedWorkedDays, rowIndex: number) => (
            <TableBody key={rowIndex}>
              {week.workedDays.map((day: WorkedDay) => (
                <TableRow key={dayjs(day.date as Date).format('MM/DD/YYYY')}>
                  <TableCell align="center">
                    {dayjs(day.date as Date).format('MM/DD/YYYY')}
                  </TableCell>
                  <TableCell align="center">{day.regularHours}</TableCell>
                  <TableCell align="center">{day.overTimeHours}</TableCell>
                </TableRow>
              ))}
              <TableRow className={clx.boldBackground}>
                <TableCell>
                  Week Ending {dayjs(week.weekEndDate as Date).format('MM/DD')}
                </TableCell>
                <TableCell>{week.regularHoursSum}</TableCell>
                <TableCell>{week.otHoursSum}</TableCell>
              </TableRow>
            </TableBody>
          ))}
        </Table>
      </TableContainer>
    </>
  )
}

function RequestorSubmittedSalary({ days }: { days: GroupedWorkedDays[] }) {
  const clx: any = useClasses(styles(theme))

  return (
    <>
      <Typography variant="h6" color="textSecondary">
        Requestor Submitted Hours
      </Typography>
      <TableContainer>
        <Table stickyHeader size="small">
          <TableHead>
            <TableCell align="center">Date</TableCell>
            <TableCell align="center">Regular Hours</TableCell>
          </TableHead>
          {days.map((week: GroupedWorkedDays, rowIndex: number) => (
            <TableBody key={rowIndex}>
              {week.workedDays.map((day: WorkedDay) => (
                <TableRow key={dayjs(day.date as Date).format('MM/DD/YYYY')}>
                  <TableCell align="center">
                    {dayjs(day.date as Date).format('MM/DD/YYYY')}
                  </TableCell>
                  <TableCell align="center">{day.regularHours}</TableCell>
                </TableRow>
              ))}
              <TableRow className={clx.boldBackground}>
                <TableCell>
                  Week Ending {dayjs(week.weekEndDate as Date).format('MM/DD')}
                </TableCell>
                <TableCell>{week.regularHoursSum}</TableCell>
              </TableRow>
            </TableBody>
          ))}
        </Table>
      </TableContainer>
    </>
  )
}
function HoursFromPosDetail({ hours }: { hours: HoursFromPos[] }) {
  const clx: any = useClasses(styles(theme))
  return (
    <>
      <Typography variant="h6" color="textSecondary">
        Hours From POS
      </Typography>

      <TableContainer className={clx.hoursToPos} component={Paper}>
        <Table
          stickyHeader
          size="small"
          padding="none"
          style={{ tableLayout: 'fixed' }}
        >
          <TableHead>
            <TableRow className={clx.centerAlignedDiv}>
              <TableCell>Business Date</TableCell>
              <TableCell>Store ID</TableCell>
              <TableCell>Emp No</TableCell>
              <TableCell>Job Code</TableCell>
              <TableCell>Edited By</TableCell>
              <TableCell>Edited</TableCell>
              <TableCell>Clock In</TableCell>
              <TableCell>Clock Out</TableCell>
              <TableCell>Regular Hours</TableCell>
              <TableCell>OverTime Hours</TableCell>
            </TableRow>
          </TableHead>
          {hours.map((week: any, rowIndex: number) => (
            <TableBody key={rowIndex}>
              {week.hoursFromPOS.map((hour) => (
                <TableRow
                  key={dayjs(hour.dateOfBusiness as Date).format('MM/DD/YY')}
                  className={clx.centerAlignedDiv}
                >
                  <TableCell>
                    {dayjs(hour.dateOfBusiness as Date).format('MM/DD/YY')}
                  </TableCell>
                  <TableCell>{hour.fkStoreId}</TableCell>
                  <TableCell>{hour.fkEmployeeNumber}</TableCell>
                  <TableCell>{hour.aloha_JobCodeId}</TableCell>
                  <TableCell>{hour.editedBy}</TableCell>
                  <TableCell>
                    {hour.editedDateTime &&
                      dayjs(hour.editedDateTime as Date).format('MM/DD/YY')}
                  </TableCell>
                  <TableCell>{hour.clockInSysDateTime}</TableCell>
                  <TableCell>{hour.clockOutSysDateTime}</TableCell>
                  <TableCell>{hour.regularHours}</TableCell>
                  <TableCell>{hour.otHours}</TableCell>
                </TableRow>
              ))}
              <TableRow className={clx.boldBackground}>
                <TableCell colSpan={6}></TableCell>
                <TableCell colSpan={2}>
                  Week Ending {dayjs(week.weekEndDate as Date).format('MM/DD')}
                </TableCell>
                <TableCell>{week.regularHoursSum}</TableCell>
                <TableCell>{week.otHoursSum}</TableCell>
              </TableRow>
            </TableBody>
          ))}
        </Table>
      </TableContainer>
    </>
  )
}

function Mileage({
  notExportedTotal,
  exportedRecentlyTotal,
}: {
  notExportedTotal: number
  exportedRecentlyTotal: number | null
}) {
  const clx: any = useClasses(styles(theme))

  return (
    <>
      <Typography variant="h6" color="textSecondary">
        Mileage
      </Typography>
      <TableContainer>
        <Table size="small">
          <TableBody>
            <TableRow>
              <BorderLessCell className={clx.leftAlignedDiv}>
                Miles submitted (Not Exported)
              </BorderLessCell>
              <BorderLessRightAlignedCell>
                {notExportedTotal.toFixed(2)}
              </BorderLessRightAlignedCell>
            </TableRow>
            {exportedRecentlyTotal !== null && (
              <TableRow>
                <BorderLessCell className={clx.leftAlignedDiv}>
                  Miles submitted (Exported in the last 14 days)
                </BorderLessCell>
                <BorderLessRightAlignedCell>
                  {exportedRecentlyTotal.toFixed(2)}
                </BorderLessRightAlignedCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

function PaymentSelctionTypeDetail({
  paySelectionType,
}: {
  paySelectionType: string
}) {
  const clx: any = useClasses(styles(theme))
  return (
    <>
      &nbsp;
      <Typography variant="h6" color="textSecondary">
        Pay Selection Type
      </Typography>
      <TableContainer>
        <Table size="small">
          <TableBody>
            <TableRow>
              <BorderLessCell className={clx.leftAlignedDiv}>
                {paySelectionType ?? 'No Data Found'}
              </BorderLessCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

function VacationAndSickHourDetail({
  vacationAndSickHour,
}: {
  vacationAndSickHour: VacationAndSickHour
}) {
  const clx: any = useClasses(styles(theme))
  return (
    <>
      <Typography variant="h6" color="textSecondary">
        Vacation Hours
      </Typography>
      <TableContainer>
        <Table size="small">
          <TableBody>
            <TableRow>
              <BorderLessCell className={clx.leftAlignedDiv}>
                Vacation/PTO Available
              </BorderLessCell>
              <BorderLessRightAlignedCell>
                {vacationAndSickHour.vacationPtoAvailable.toFixed(2)}
              </BorderLessRightAlignedCell>
            </TableRow>
            <TableRow>
              <BorderLessCell className={clx.leftAlignedDiv}>
                Vacation/PTO Hrs Submitted (Not Exported)
              </BorderLessCell>
              <BorderLessRightAlignedCell>
                {vacationAndSickHour.vacationPtoSubmittedNotExported.toFixed(2)}
              </BorderLessRightAlignedCell>
            </TableRow>
            <TableRow>
              <BorderLessCell className={clx.leftAlignedDiv}>
                Vacation/PTO Hrs Submitted (Exported but Not Paid)
              </BorderLessCell>
              <BorderLessRightAlignedCell>
                {vacationAndSickHour.vacationPtoSubmittedExportedNotPaid.toFixed(
                  2
                )}
              </BorderLessRightAlignedCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      &nbsp;
      <Typography variant="h6" color="textSecondary">
        Sick Hours
      </Typography>
      <TableContainer>
        <Table size="small">
          <TableBody>
            <TableRow>
              <BorderLessCell className={clx.leftAlignedDiv}>
                Sick/Paid Leave Hrs Submitted (Not Exported)
              </BorderLessCell>
              <BorderLessRightAlignedCell>
                {vacationAndSickHour.sickPaidLeaveSubmittedNotExported.toFixed(
                  2
                )}
              </BorderLessRightAlignedCell>
            </TableRow>
            <TableRow>
              <BorderLessCell className={clx.leftAlignedDiv}>
                Sick/Paid Leave Hrs Submitted (Exported but Not Paid)
              </BorderLessCell>
              <BorderLessRightAlignedCell>
                {vacationAndSickHour.sickPaidLeaveSubmittedExportedNotPaid.toFixed(
                  2
                )}
              </BorderLessRightAlignedCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}
