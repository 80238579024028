// used in lazy import

import FileUploadIcon from '@mui/icons-material/CloudUploadOutlined'
import { Box, Button, Typography } from '@mui/material'
import LinearProgress from '@mui/material/LinearProgress'
import { postRosterFileUpload } from 'api'
import { UploadDropzone } from 'components/UploadDropzone'
import { useClasses } from 'hooks/useClasses'
import { UploadAdpRosterState } from 'pages/UploadAdpRoster'
import { useEffect, useState } from 'react'
import { useMutation } from 'react-query'
import { useNavigate } from 'react-router-dom'

const styles = {
  root: {
    width: '100%',
  },
}

type UploadAdpRosterFileDropZoneProps = {
  handleNext: () => void
  getPrevPage: () => string
  data: UploadAdpRosterState
  nextButtonText: string
}

const fatalErrorText = `Fatal Error`

// eslint-disable-next-line import/no-default-export
export function UploadAdpRosterFileDropZone({
  handleNext,
  getPrevPage,
  data,
  nextButtonText,
}: UploadAdpRosterFileDropZoneProps) {
  const navigate = useNavigate()
  const classes: any = useClasses(styles)
  const [progress, setProgress] = useState(0)
  const [files, setFiles] = useState<File[]>([])
  const [userMessages, setUserMessages] = useState<string[]>([])
  const {
    mutateAsync: uploadRosterFile,
    isLoading,
    isSuccess,
  } = useMutation(postRosterFileUpload, {
    onSuccess: (data?) => {
      const warningsText = data?.data?.data?.warnings
      if (data?.data?.data?.error?.length > 0) {
        setUserMessages([data?.data?.data?.error, ...warningsText])
      } else {
        setUserMessages([`Successfully uploaded contents`, ...warningsText])
      }
    },
    onError: (data?: any) => {
      setUserMessages([`${data}`])
    },
  })

  useEffect(() => {
    if (isLoading) {
      const timer = setInterval(() => {
        setProgress((oldProgress) => {
          if (oldProgress === 100) {
            return 0
          }
          const diff = Math.random() * 10
          return Math.min(oldProgress + diff, 100)
        })
      }, 5000)

      return () => {
        clearInterval(timer)
      }
    }
  }, [isLoading])

  const processFile = async (files: File[]) => {
    if (files?.length > 0) {
      setProgress(0)
      await uploadRosterFile({
        file: files[0],
        brand: data.brand,
        lastProcessedCheckDate: data.lastProcessedCheckDate,
      })
    }
  }

  return (
    <>
      <Box mb="10">
        <UploadDropzone
          onChange={setFiles}
          initialFiles={files}
          filesLimit={1}
          acceptedFileExtensions={[`.csv`]}
        />
      </Box>
      <Box my={1}>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => processFile(files)}
          disabled={files?.length === 0 || isLoading}
        >
          <FileUploadIcon />
          &nbsp;
          {!isLoading ? 'Upload' : 'processing...'}
        </Button>
      </Box>
      <Box my={3}>
        {isLoading && (
          <div className={classes.root}>
            <LinearProgress variant="determinate" value={progress} />
          </div>
        )}
      </Box>
      <Box my={3}>
        {userMessages.map((msg, idx) => {
          return (
            <Typography key={idx} variant="body2" color="textSecondary">
              {msg}
            </Typography>
          )
        })}
      </Box>
      <Box display="flex" justifyContent="space-between">
        <Button onClick={() => navigate(getPrevPage())}>Back</Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleNext}
          disabled={
            isLoading ||
            !isSuccess ||
            (userMessages.length > 0 &&
              userMessages[0].includes(fatalErrorText))
          }
        >
          {nextButtonText}
        </Button>
      </Box>
    </>
  )
}
