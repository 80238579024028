import { Dropzone, ExtFile } from '@dropzone-ui/react'
import { Box, CircularProgress } from '@mui/material'
import { getUploadConfig } from 'api'
import { useQuery } from 'react-query'
import { QueryNames } from 'types/Queries'

interface UploadDropzoneProps {
  onChange: (files: File[]) => void // Modified prop type to accept File[]
  initialFiles?: File[]
  filesLimit?: number
  acceptedFileExtensions?: string[]
}

export function UploadDropzone({
  onChange,
  initialFiles = [],
  filesLimit = 10,
  acceptedFileExtensions = [],
}: UploadDropzoneProps) {
  const { data: configData } = useQuery(
    QueryNames.UploadConfig,
    getUploadConfig
  )

  if (acceptedFileExtensions.length === 0) {
    acceptedFileExtensions = configData?.data.data.allowedExtensions.map(
      (extension) => `.${extension}`
    ) as string[]
  }

  const DEFAULT_MAX_SIZE = 1000000 // 1 MB
  const maxSize = configData?.data?.data?.maxSizeInBytes || DEFAULT_MAX_SIZE

  const accept = acceptedFileExtensions.join(',')

  const handleFilesChange = (files: ExtFile[]) => {
    const validFiles = files
      .map((extFile) => extFile.file)
      .filter((file): file is File => file !== undefined)
      .slice(0, filesLimit)

    onChange(validFiles)
  }

  return configData?.data ? (
    <Dropzone
      data-testid="file-dropzone"
      maxFileSize={maxSize}
      accept={accept}
      multiple={true}
      onChange={handleFilesChange}
    >
      {initialFiles &&
        initialFiles.map((file) => <p key={file.name}>{file.name}</p>)}
      <p>Drag and drop some files here, or click to select files</p>
    </Dropzone>
  ) : (
    <Box minHeight="50px">
      <CircularProgress />
    </Box>
  )
}
