import { StepInfo, StepProps } from 'types/StepProps'
import { useQuery } from 'react-query'
import { getReasonCodes, Reason } from 'api'
import TextField from '@mui/material/TextField'
import { Employee } from 'types/Employee'
import Autocomplete from '@mui/material/Autocomplete'
import { CircularProgress } from '@mui/material'
import { useUser } from 'context/Authenticate'

export const reasonStep: StepInfo = {
  cardLabel: 'Select reason',
  stepLabel: 'Reason',
  url: 'reason',
}

type SelectReasonProps = StepProps<
  { reasonCode?: Reason; employee?: Employee },
  Reason
> & { type: string } & { jobTitleChange?: Reason } & {
  effectiveDateLOA?: Date | null
  filterReasonId?: number | undefined
}

// eslint-disable-next-line import/no-default-export
export default function SelectReason({
  data: { reasonCode, employee },
  setData,
  type,
  jobTitleChange,
  effectiveDateLOA,
  filterReasonId,
}: SelectReasonProps) {
  const { data: codes, isFetching } = useQuery(
    [
      'reason-codes',
      employee?.id,
      type,
      jobTitleChange,
      effectiveDateLOA,
      filterReasonId,
    ],
    () =>
      getReasonCodes(
        employee?.id,
        type,
        jobTitleChange,
        effectiveDateLOA,
        filterReasonId
      )
  )
  const { roles } = useUser()
  return !codes?.data && isFetching ? (
    <CircularProgress />
  ) : (
    <Autocomplete<Reason>
      id="select-termination-reason"
      options={codes?.data ?? []}
      value={reasonCode ?? null}
      onChange={(_, value) => setData(value ?? undefined)}
      fullWidth
      getOptionLabel={(opt) =>
        roles.canViewPractitionerChangeReasons
          ? `${opt.code} - ${opt.description}`
          : opt.description
      }
      autoHighlight
      renderInput={(params) => (
        <TextField
          autoFocus
          variant="outlined"
          label="Reason codes"
          {...params}
          InputLabelProps={{ shrink: true }}
        />
      )}
    />
  )
}
