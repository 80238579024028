import * as Sentry from '@sentry/react'
import {
  getIsAuthenticatedDiscount,
  IsAuthenticatedDiscountResponse,
  addApiInterceptors,
} from 'api'
import LoadingPage from 'components/LoadingPage'
import React, { createContext, useMemo } from 'react'
import { useQuery } from 'react-query'
import DiscountLandingPage from './components/LandingPage'

export interface User {
  name: IsAuthenticatedDiscountResponse['name']
  id: IsAuthenticatedDiscountResponse['employeeId']
  associateId: IsAuthenticatedDiscountResponse['associateId']
  locationName: IsAuthenticatedDiscountResponse['locationName']
  discountCode: IsAuthenticatedDiscountResponse['discountCode']
}

const AuthenticationContext = createContext<User | undefined>(undefined)

export function AuthenticationProvider({
  children,
}: React.PropsWithChildren<unknown>) {
  const canAuthenticate = useMemo(
    () => !window.location.hostname.includes('wksdiscount.com'), // CORS will refuse logins unless they are from wksconnect.com
    []
  )

  const { isLoading, isSuccess, data } = useQuery(
    'is-authenticated-discount',
    getIsAuthenticatedDiscount,
    {
      retry: false,
      refetchOnWindowFocus: false,
      onError: undefined,
      enabled: canAuthenticate,
    }
  )

  if (isLoading) return <LoadingPage />

  if (isSuccess) {
    const { name, employeeId, associateId, locationName, discountCode } = data
      ?.data.data as IsAuthenticatedDiscountResponse

    addApiInterceptors()
    const context: User = {
      name,
      id: employeeId,
      associateId,
      locationName,
      discountCode,
    }

    Sentry.setUser({ username: name, id: employeeId?.toString() })

    return (
      <AuthenticationContext.Provider value={context}>
        {children}
      </AuthenticationContext.Provider>
    )
  } else {
    return <DiscountLandingPage />
  }
}

export const AuthenticationConsumer = AuthenticationContext.Consumer

export function useUser() {
  const context = React.useContext(AuthenticationContext)
  if (context === undefined) {
    throw new Error('useUser must be used within a AuthenticationProvider')
  }
  return context
}
