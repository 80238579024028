import { Card, CardActionArea, Grid, Link, Typography } from '@mui/material'
import { styled, ThemeProvider } from '@mui/system'
import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { theme } from 'theme'

const StyledCard = styled(Card)(({ theme }) => ({
  backgroundColor: theme?.palette?.primary?.light,
  alignItems: 'center',
  display: 'flex',
  margin: '5px',
  color: theme?.palette?.common?.white,
}))

const FlexDiv = styled('div')(() => ({
  alignItems: 'center',
  justifyContent: 'center',
  height: '20vh',
  minHeight: '30px',
  padding: '30px',
  display: 'flex',
}))

const StyledButtonText = styled(Typography)`
  margin-left: 5px;
`

export function HomePageCard({
  icon,
  buttonText,
  to = '/',
  isNewFeature = false,
}) {
  return (
    <ThemeProvider theme={theme}>
      <StyledCard>
        <CardActionArea>
          <Grid container direction="column">
            <Link component={RouterLink} to={to} color="inherit">
              <FlexDiv>
                <Grid item>{icon}</Grid>
                <Grid item>
                  {buttonText.split('\n').map((values, index) => {
                    // Use index as a key for the React fragment
                    return (
                      <React.Fragment key={index}>
                        <StyledButtonText variant="h6">
                          {values}
                        </StyledButtonText>
                      </React.Fragment>
                    )
                  })}
                  {isNewFeature && (
                    <Typography
                      variant="h6"
                      sx={{ fontStyle: 'italic' }}
                      color={theme.palette.secondary.light}
                    >
                      Now Available
                    </Typography>
                  )}
                </Grid>
              </FlexDiv>
            </Link>
          </Grid>
        </CardActionArea>
      </StyledCard>
    </ThemeProvider>
  )
}
