import {
  Box,
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material'
import React, { useState } from 'react'
import { StepInfo } from 'types/StepProps'

export const pendingRequestsStep: StepInfo = {
  stepLabel: 'Pending ADP Requests',
  cardLabel: 'Pending ADP Requests',
  url: 'pending-requests',
}

// Exporting default to allow lazy import
// eslint-disable-next-line import/no-default-export
export default function PendingRequests({
  data,
  setData,
  handleNext,
  navigate,
  getPrevPage,
}) {
  const [hideNextButton, setHideNextButton] = useState<boolean>(true)
  return (
    <>
      {data.employee &&
        data.employee.employeePendingActionsInAdp.length > 0 && (
          <>
            <Typography color="textSecondary">
              The following requests are already Completed in ADP with a future
              Effective Date
            </Typography>
            <Grid container direction="row" justifyContent="center">
              <Box
                bgcolor="grey.100"
                border={1}
                mt={2}
                mb={2}
                borderColor="grey.300"
                borderRadius="3px"
                alignItems="center"
                alignContent="center"
                width="50%"
              >
                <TableContainer>
                  <Table size="small">
                    <TableBody>
                      {data.employee.employeePendingActionsInAdp.map((t) => (
                        <TableRow key={t.requestType}>
                          <TableCell>{t.requestType}</TableCell>
                          <TableCell>{t.requestInfo}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Grid>
            <Typography color="textSecondary">
              I still want to proceed with this request.
            </Typography>
            <Button
              variant="contained"
              onClick={() => navigate('/')}
              size="large"
              data-testid="supporting-doc-no"
            >
              CANCEL
            </Button>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <Button
              color={!hideNextButton ? 'secondary' : undefined}
              variant="contained"
              onClick={() => {
                setHideNextButton(false)
                setData({
                  userAcknowledgedPendingRequestsInAdp: true,
                })
              }}
              size="large"
              data-testid="supporting-doc-yes"
            >
              YES
            </Button>
            <Box display="flex" justifyContent="space-between">
              <Button onClick={() => navigate(getPrevPage())}>Back</Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleNext}
                disabled={hideNextButton}
                data-testid="proceed-in-workflow"
              >
                Next
              </Button>
            </Box>
          </>
        )}
    </>
  )
}
