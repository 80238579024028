import { Box, Modal, Paper, Typography } from '@mui/material'
import { UploadFileDisplay } from 'components/request-detail/UploadFileDisplay'
import { useLocation, useParams } from 'react-router-dom'

export function UploadFileDisplayPage() {
  const { requestId, fileId } = useParams<{
    requestId: string
    fileId: string
  }>()
  const location = useLocation()
  const fileName = new URLSearchParams(location.search).get('name') ?? ''
  return (
    <Modal open={true}>
      <Box
        component={Paper}
        width="100%"
        height="100%"
        display="flex"
        alignItems="center"
        flexDirection="column"
      >
        <Typography>{fileName}</Typography>
        <UploadFileDisplay
          requestId={Number(requestId)}
          fileId={fileId || ''}
          fileName={fileName}
        />
      </Box>
    </Modal>
  )
}
