import { AppBar, Hidden, Toolbar } from '@mui/material'
import { SignOutButton } from 'discount/components/SignOutButton'
import { useClasses } from 'hooks/useClasses'
import thinLogoUrl from '../../assets/WKS_LOGO_PRIMARY_mono_white.svg'
import wideLogoUrl from '../../assets/WKS_LOGO_WORDMARK_A_mono_white.svg'
const styles = {
  toolbar: {
    height: '70px',
  },
  thinLogo: {
    height: '55px',
  },
  wideLogo: {
    height: '30px',
  },
  wideCenteredLogo: {
    height: '30px',
    flexGrow: 1,
    textAlign: 'center',
  },
}

interface Props {
  displaySignOutButton?: boolean
}

// eslint-disable-next-line import/no-default-export
export default function Header({ displaySignOutButton = true }: Props) {
  const classes: any = useClasses(styles)
  const logoAltText = 'WKS Restaurant Group'

  return (
    <AppBar position="static" color="secondary">
      <Toolbar className={classes.toolbar}>
        {displaySignOutButton ? (
          <>
            <Hidden mdUp>
              <img
                src={thinLogoUrl}
                alt={logoAltText}
                className={classes.thinLogo}
              />
            </Hidden>
            <Hidden mdDown>
              <img
                src={wideLogoUrl}
                alt={logoAltText}
                className={classes.wideLogo}
              />
            </Hidden>
            <SignOutButton />
          </>
        ) : (
          <img
            src={wideLogoUrl}
            alt={logoAltText}
            className={classes.wideCenteredLogo}
          />
        )}
      </Toolbar>
    </AppBar>
  )
}
