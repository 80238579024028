import { isXsrfProtectionEnabled } from 'api'
import { xsrfTokenFormFieldName } from 'appConstants'
import { readXsrfTokenFromCookie } from 'utils'

export function XsrfTokenInput() {
  if (!isXsrfProtectionEnabled) return null

  return (
    <input
      type="hidden"
      name={xsrfTokenFormFieldName}
      value={readXsrfTokenFromCookie()}
    />
  )
}
