import { BASE_URL } from 'api'
import { XsrfTokenInput } from 'components/xsrf/XsrfTokenInput'
import React from 'react'

export function LogoutForm({
  children,
  returnUrl,
  logoutUrl = 'logout',
}: {
  children: React.ReactNode
  returnUrl?: string
  logoutUrl?: string
}) {
  return (
    <form
      action={`${BASE_URL}/api/${logoutUrl}${
        returnUrl !== undefined ? '?returnUrl=' + returnUrl : ''
      }`}
      method="post"
    >
      <XsrfTokenInput />
      {children}
    </form>
  )
}
