import { RateTrendDisplay } from 'components/RateTrendDisplay'
import { RateUnit } from 'types/RateUnit'
import { StatusChangeResponseBase } from 'types/StatusChangeResponse'

export function RateChangeInfo({
  newRate,
  oldRate,
  rateUnit,
}: {
  newRate: number
  oldRate: number
  rateUnit: RateUnit
}) {
  return (
    <RateTrendDisplay newRate={newRate} oldRate={oldRate} rateUnit={rateUnit} />
  )
}

export function RateChangeDetail({
  statusChangeResponse: response,
}: {
  statusChangeResponse: StatusChangeResponseBase
}) {
  const details = response.details
  return (
    <RateChangeInfo
      newRate={details.newRate ?? 0}
      oldRate={details.existingRate ?? 0}
      rateUnit={details.existingRateUnit ?? RateUnit.HOURLY}
    />
  )
}
