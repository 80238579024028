import { Typography } from '@mui/material'
import Box from '@mui/material/Box'
import Collapse from '@mui/material/Collapse'
import Link from '@mui/material/Link'
import Paper from '@mui/material/Paper'
import TermsAndConditions from 'discount/components/TermsAndConditions'
import React, { useRef } from 'react'

const sidePaddingStyle = {
  padding: '0 10px',
}
const pointerCursorStyle = { cursor: 'pointer' }

// eslint-disable-next-line import/no-default-export
export default function CollapsibleTerms() {
  const [collapsed, setCollapsed] = React.useState(false)

  const handleChange = () => {
    setCollapsed((prev) => !prev)
    if (!collapsed) executeScrollAfterTime(150) // Wait for animation
  }
  const clickableTextRef = useRef<HTMLLinkElement>(null)

  const executeScrollAfterTime = (time: number) => {
    setTimeout(function () {
      executeScroll()
    }, time)
  }
  const executeScroll = () =>
    clickableTextRef.current &&
    clickableTextRef.current.scrollIntoView({ behavior: 'smooth' })

  return (
    <Box display="flex" flexDirection="column" alignItems="center" m={2}>
      <Typography variant="body2">
        <Link
          href="#"
          onClick={handleChange}
          // ref={clickableTextRef}
          style={pointerCursorStyle}
        >
          Terms & Conditions
        </Link>
      </Typography>
      <Collapse in={collapsed}>
        <Paper elevation={4} style={sidePaddingStyle}>
          <TermsAndConditions />
        </Paper>
      </Collapse>
    </Box>
  )
}
