import { Box } from '@mui/material'
import { useClasses } from 'hooks/useClasses'
import { ReactNode } from 'react'

export function Footer({
  children,
  height,
}: {
  children: ReactNode
  height?: number
}) {
  const styles = {
    footer: {
      position: 'relative',
      bottom: 0,
      left: 0,
      width: '100%',
      height: height || 56,
      textAlign: 'center',
    },
    [`@media print`]: {
      footer: {
        position: 'relative',
      },
    },
  }
  const clx: any = useClasses(styles)
  return (
    <Box
      component="footer"
      bgcolor="primary.main"
      p={1}
      color="primary.contrastText"
      className={clx.footer}
    >
      {children}
    </Box>
  )
}
