import { Button, CircularProgress, Link, Typography } from '@mui/material'
import { getFile } from 'api'
import { UploadPdfDisplay } from 'components/request-detail/UploadPdfDisplay'
import { useQuery } from 'react-query'
import { QueryNames } from 'types/Queries'

interface UploadedFileDisplayProps {
  fileId: string
  fileName: string
  requestId: number
}

export function UploadFileDisplay({
  fileId,
  fileName,
  requestId,
}: UploadedFileDisplayProps) {
  const { isLoading, data } = useQuery(
    [QueryNames.FileBlobUrls, requestId, fileId],
    () => getFile(requestId, fileId),
    {
      refetchOnWindowFocus: false, // Avoid blinking pdf effect if navigate away from window and back
    }
  )

  if (isLoading) {
    return <CircularProgress style={{ marginTop: '20px' }} />
  }

  const displayedItemUrl = data?.url

  if (displayedItemUrl) {
    switch (data?.contentType) {
      case 'application/pdf':
        return <UploadPdfDisplay url={displayedItemUrl} fileName={fileName} />
      case 'image/gif':
      case 'image/jpeg':
      case 'image/png':
        return (
          <img
            style={{ maxWidth: '100%' }}
            src={displayedItemUrl}
            alt={fileName}
          />
        )
      default:
        return (
          <>
            <Typography>No Preview Available</Typography>
            <Link
              href={displayedItemUrl}
              download={fileName}
              component={Button}
            >
              Download
            </Link>
          </>
        )
    }
  }
  return null
}
