import { StepInfo } from 'types/StepProps'

export const locationStep = (
  cardLabel = 'Something went wrong.. try again'
): StepInfo => {
  return {
    stepLabel: 'Location',
    cardLabel: cardLabel,
    url: 'location',
  }
}
