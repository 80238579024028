import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Grid,
  Link,
  Typography,
} from '@mui/material'
import { useUser } from 'context/Authenticate'
import { REQUEST_LIST } from 'link-paths'
import { useMemo } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { theme } from 'theme'
import { RateUnit } from 'types/RateUnit'
import { EmployeeRecordRequestType } from 'types/StatusChangeResponse'
// Exporting default to allow lazy import
// eslint-disable-next-line import/no-default-export
export default function BlockExistingRequest({
  data,
  handleNext,
  navigate,
  employeeRecordRequestType,
  isLoading = false,
  handlePrev = () => navigate('/'),
}) {
  const user = useUser()

  const infoPrompt = useMemo((): JSX.Element | null => {
    if (!data.employee) {
      return null
    }

    if (data.employee.hasUserExceededRehireEmployeeSearchLimit) {
      return (
        <Alert
          severity="warning"
          key="warning-hasUserExceededRehireEmployeeSearchLimit"
        >
          You have exceeded the maximum number of allowed lookups per day. This
          has been recorded. If you believe this is a technical issue, please
          get in touch with helpdesk@wksusa.com
        </Alert>
      )
    }
    return null
  }, [data.employee])

  const [errorPrompt, hideNextButton, showReturnToHomeButton] = useMemo((): [
    JSX.Element | null,
    boolean,
    boolean,
  ] => {
    if (!data.employee) {
      return [null, true, false]
    }

    if (data.employee.existingPendingRequest !== null) {
      return [
        <Alert severity="error" key="error" data-testid="existing-request">
          There is an existing WKS Connect request for this employee.
        </Alert>,
        true,
        false,
      ]
    } else if (!data.employee.positionStatusValidForRequestType) {
      return [
        <Alert severity="error" key="error-positionStatusValidForRequestType">
          Employee position status is{' '}
          <strong>{data.employee.positionStatus ?? 'not available'}</strong>.
          Request cannot proceed.
        </Alert>,
        true,
        true,
      ]
    } else if (isLoading) {
      return [
        <>
          <CircularProgress />
        </>,
        true,
        false,
      ]
    } else if (
      employeeRecordRequestType === EmployeeRecordRequestType.POSJobCode &&
      !data.employeePosJobCode
    ) {
      return [
        <Alert severity="error" key="error-EmployeeRecordRequestTypePOSJobCode">
          {
            "We're sorry, you cannot update this user at this time. Please try to update tomorrow."
          }
        </Alert>,
        true,
        true,
      ]
    } else if (
      employeeRecordRequestType === EmployeeRecordRequestType.Rehire &&
      !data.employee.isMatchForSSN
    ) {
      return [
        <Alert severity="error" key="error-EmployeeRecordRequestTypeRehire">
          {`The entered SSN ${data.ssn} does not match the selected Employee. Please try selecting another employee. If there are no other employees to select, you may make a job offer to the candidate.`}
        </Alert>,
        true,
        true,
      ]
    } else if (
      data.employee.primaryRate === 0 ||
      !data.employee.rateUnit ||
      data.employee.rateUnit.length === 0 ||
      !(
        data.employee.rateUnit === RateUnit.HOURLY ||
        data.employee.rateUnit === RateUnit.SALARY
      )
    ) {
      if (user.roles.canViewEmployeeSensitive) {
        let email = 'payroll@wksusa.com'
        if (data.employee.brandDesc === "Denny's")
          email = 'dennyspayroll@wksusa.com'
        const subject = `Employee has no rate or rate unit in ADP - ${data.employee.employeeName} ${data.employee.adpPositionId}`
        return [
          <Alert severity="error" key="error-noRateOrRateUnit">
            Employee has no rate or rate unit in <strong>ADP</strong>. Please
            contact{' '}
            <Link href={`mailto:${email}?subject=${subject}`}>
              <strong>{email}</strong>
            </Link>
            . Thank you.
          </Alert>,
          true,
          false,
        ]
      } else {
        const email = 'helpdesk@wksusa.com'
        const subject = `User Missing 'Sensitive' Roles - ${user.name} ${user.associateId}`
        return [
          <Alert severity="error" key="error-sensitiveRoles">
            Sorry, your account does not have permission to see Rates of Pay.
            Please contact{' '}
            <Link href={`mailto:${email}?subject=${subject}`}>
              <strong>{email}</strong>
            </Link>
            . Thank you.
          </Alert>,
          true,
          false,
        ]
      }
    } else {
      return [
        <>Is this the team member you want to make a change for?</>,
        false,
        false,
      ]
    }
  }, [
    data.employee,
    data.employeePosJobCode,
    data.ssn,
    isLoading,
    employeeRecordRequestType,
    user.roles.canViewEmployeeSensitive,
    user.name,
    user.associateId,
  ])

  return (
    <>
      {data.employee && (
        <>
          <Box display="flex" py={2}>
            <Grid
              container
              justifyContent="center"
              spacing={1}
              alignItems="center"
              direction="column"
            >
              {infoPrompt && (
                <Grid item>
                  <Typography
                    color="textPrimary"
                    variant="body1"
                    align="center"
                  >
                    {infoPrompt}
                  </Typography>
                </Grid>
              )}
              {errorPrompt && (
                <Grid item>
                  <Typography
                    color="textPrimary"
                    variant="body1"
                    align="center"
                  >
                    {errorPrompt}
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Box>
          <Box display="flex" justifyContent="space-between">
            <Button onClick={handlePrev}>Back</Button>
            {showReturnToHomeButton && (
              <>
                {' '}
                <Button
                  onClick={() => navigate('/')}
                  variant="contained"
                  style={{
                    backgroundColor: theme.palette.primary.light,
                    color: theme.palette.primary.contrastText,
                  }}
                >
                  Return to home page
                </Button>
              </>
            )}
            {data.employee.existingPendingRequest !== null && (
              <>
                <Button
                  variant="contained"
                  component={RouterLink}
                  to={`${REQUEST_LIST}/${data.employee.existingPendingRequest.id}`}
                  style={{
                    backgroundColor: theme.palette.primary.light,
                    color: theme.palette.primary.contrastText,
                  }}
                >
                  View WKS Connect Request
                </Button>
              </>
            )}
            <Button
              variant="contained"
              color="primary"
              onClick={handleNext}
              disabled={hideNextButton}
              data-testid="proceed-in-workflow"
            >
              Yes
            </Button>
          </Box>
        </>
      )}
    </>
  )
}
