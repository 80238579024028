import Paper from '@mui/material/Paper'
import { Theme } from '@mui/material/styles'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Typography from '@mui/material/Typography'
import { JobTitleInfo } from 'components/request-detail/JobTitleDetail'
import { useClasses } from 'hooks/useClasses'
import { theme } from 'theme'
import { ExistingJobFunction } from 'types/Employee'
import { RateUnit } from 'types/RateUnit'
import {
  StatusChangeDetailsJobFunction,
  StatusChangeResponseBase,
} from 'types/StatusChangeResponse'

const styles = (theme: Theme) => ({
  added: {
    '& td': {
      color: theme.palette.primary.dark,
      backgroundColor: theme.palette.colors.lightBlue100,
    },
  },
})

interface ProposedFunctionsTableProps {
  existing: ExistingJobFunction[]
  proposed: ExistingJobFunction[]
}

export function ProposedFunctionsTable({
  existing,
  proposed,
}: ProposedFunctionsTableProps) {
  const clx: any = useClasses(styles(theme))
  const oldPrimary = existing.find((jf) => jf.isPrimary)?.jobCode
  const newPrimary = proposed.find((jf) => jf.isPrimary)?.jobCode

  const getIsPrimaryValue = (jobCode: string) => {
    if (newPrimary === oldPrimary) {
      return (
        <Typography
          variant="body2"
          style={{ color: theme.palette.colors.green100 }}
        >
          Primary
        </Typography>
      ) // no change to primary
    } else if (jobCode === oldPrimary) {
      return (
        <Typography variant="body2" color="error">
          Old Primary
        </Typography>
      )
    } else if (jobCode === newPrimary) {
      return (
        <Typography variant="body2" className={clx.success}>
          New Primary
        </Typography>
      )
    }
    return null
  }

  return (
    <>
      <Typography
        variant="h6"
        align="center"
        color="inherit"
        style={{ marginBottom: '10px' }}
      >
        Proposed job functions
      </Typography>
      <TableContainer component={Paper} elevation={0}>
        <Table aria-label="job functions" size="small">
          <TableHead>
            <TableRow>
              <TableCell>Code</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Primary?</TableCell>
              <TableCell>Rate</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {proposed.map((jf) => (
              <TableRow
                className={
                  !existing.find((existing) => jf.jobCode === existing.jobCode)
                    ? clx.added
                    : undefined
                }
                key={jf.jobCode}
              >
                <TableCell>{jf.jobCode}</TableCell>
                <TableCell>{jf.description}</TableCell>
                <TableCell>
                  {(jf.jobCode === oldPrimary || jf.jobCode === newPrimary) &&
                    getIsPrimaryValue(jf.jobCode)}
                </TableCell>
                <TableCell>${jf.rate.toFixed(2)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

// export function JobFunctionDetail({ details }: { details: RequestDetail }) {
//   const { details: typeDetails } = details as JobFunctionRequestDetail
//   return (
//     <>
//       <div>{typeDetails.rate}</div>
//       <ProposedFunctionsTable
//         existing={typeDetails.existingJobFunctions ?? []}
//         proposed={typeDetails.proposedJobFunctions ?? []}
//       />
//     </>
//   )
// }

export function JobFunctionDetail({
  statusChangeResponse: response,
}: {
  statusChangeResponse: StatusChangeResponseBase
}) {
  const details = response.details as StatusChangeDetailsJobFunction
  const oldJobFunction =
    details.existingJobFunctions.length > 0
      ? details.existingJobFunctions[0]
      : undefined
  const newJobFunction =
    details.proposedJobFunctions.length > 0
      ? details.proposedJobFunctions[0]
      : undefined
  return (
    <>
      <JobTitleInfo
        oldTitle={{
          code: details.existingAdpJobTitleCode ?? '',
          description: details.existingJobTitleDescription ?? '',
          rate: details.existingRate ?? 0,
          jobFunctionCode: oldJobFunction?.jobCode,
          jobFunctionPosCode: oldJobFunction?.posJobCode,
          jobFunctionCodeDesc: oldJobFunction?.description,
        }}
        newTitle={{
          code: details.newAdpJobTitleCode ?? '',
          description: details.newJobTitleDescription ?? '',
          rate: details.newRate ?? 0,
          jobFunctionCode: newJobFunction?.jobCode,
          jobFunctionPosCode: newJobFunction?.posJobCode,
          jobFunctionCodeDesc: newJobFunction?.description,
        }}
        oldRateUnit={details.existingRateUnit ?? RateUnit.HOURLY}
        rateUnit={details.newRateUnit ?? RateUnit.HOURLY}
      />
    </>
  )
}
