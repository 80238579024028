import NotInterestedIcon from '@mui/icons-material/NotInterested'
import { Box, Button } from '@mui/material'
import Typography from '@mui/material/Typography'
import { AdpLoginButton } from 'components/loginButtons/AdpLoginButton'
import { FullScreenBox } from 'components/FullScreenBox'
import { LogoutForm } from 'components/LogoutForm'
import { useUser } from 'context/Authenticate'
import { useMemo } from 'react'
import { NoAccessPageType } from 'api'

// eslint-disable-next-line import/no-default-export
export default function NoEnvironmentAccess() {
  const user = useUser()
  const mailToLink = `mailto:helpdesk@wksusa.com?subject=WKS Connect 2.0&body=Name:%20${user.name}%0D%0AAssociate Id:%20${user.associateId}%0D%0A%0D%0A`

  const envBasedAppName = useMemo(() => {
    return (
      {
        prod: 'this application',
        dev: 'the development environment',
        test: 'the testing environment',
      }[process.env.REACT_APP_ENV_TARGET ?? ''] || 'this environment'
    )
  }, [])

  return (
    <FullScreenBox>
      <NotInterestedIcon style={{ fontSize: 72 }} />
      {user.noAccessPageType === NoAccessPageType.NoAccessToThisApplication ? (
        <>
          <Typography variant="h4">{`You don't have access to ${envBasedAppName}.`}</Typography>
          <br />
          <Typography variant="h5">
            For assistance please email{' '}
            <a href={mailToLink}>helpdesk@wksusa.com</a> or call/text
            562-222-0990.
          </Typography>
          <br />
          {user.supportNotes && (
            <>
              <Typography variant="h4">Support Notes:</Typography>
              <Typography variant="h5">{user.supportNotes}</Typography>
            </>
          )}
          <br />
          <Box>
            <LogoutForm>
              <Button variant="contained" color="primary" type="submit">
                Logout
              </Button>
            </LogoutForm>
          </Box>
        </>
      ) : (
        <>
          <Typography variant="h4">Please log in with ADP</Typography>
          <br />
          <Typography variant="h5">
            To log into WKS Connect 2.0, please log in with your personal ADP
            account.
          </Typography>
          <AdpLoginButton />
        </>
      )}
    </FullScreenBox>
  )
}
