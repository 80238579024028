import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import { Theme } from '@mui/material/styles'
import { Reason } from 'api'
import ChangeApprover from 'components/ChangeApprover'
import { InfoBoxNotification } from 'components/InfoBoxNotification'
import Notes from 'components/steps/Notes'
import SelectReason from 'components/steps/SelectReason'
import { useUser } from 'context/Authenticate'
import dayjs from 'dayjs'
import { useClasses } from 'hooks/useClasses'
import {
  RehireReviewInfoMessage,
  ReinstatementReviewInfoMessage,
} from 'pages/InfoMessage'
import { DetailCallout, IEditData } from 'pages/StatusChangeDetail'
import React, { Dispatch, PropsWithChildren, SetStateAction } from 'react'
import { theme } from 'theme'
import { Employee } from 'types/Employee'
import { spaceBetween } from 'types/PropProperty'
import { Status } from 'types/StatusChangeResponse'
import { LineItem } from './LineItem'
import { StatusChip } from './StatusChip'

interface SharedRequestProps {
  location?: string
  status?: {
    label: string
    type: Status
  }
  reason?: Reason | undefined
  positionId?: string
  // for some reason we have null instead of undefined dates in many flows
  effectiveDate?: string | Date | null
  notes?: string
  primaryJob?: string
  isDetail?: boolean | undefined
  type?: string
  requestId?: number | undefined
  isEditActive?: boolean
  changeApprover?: Employee | undefined
  setChangeApprover?: Dispatch<SetStateAction<Employee | undefined>> | undefined
  employeeId?: number
  editData?: IEditData | undefined
  setEditData?: (value) => void
  canEdit?: boolean
  canChangeApprover?: boolean
  notesLabel?: string
  isReinstatement?: boolean | null
}

const styles = (theme: Theme) => ({
  autocomplete: {
    width: 300,
    height: 55,
    paddingTop: 10,
    '& .MuiInputBase-input': {
      height: 1.5,
    },
  },
  grid: {
    justifyContent: 'space-between' as const,
  },
})

/**
 * Displays common elements of a request in a consistent location
 */
export function SharedRequestInfo({
  location,
  status,
  reason,
  primaryJob,
  positionId,
  effectiveDate,
  notes,
  isDetail,
  type,
  children,
  requestId,
  isEditActive,
  employeeId,
  editData,
  setEditData,
  canEdit = false,
  canChangeApprover = false,
  notesLabel = 'Special Instructions',
  isReinstatement,
}: PropsWithChildren<SharedRequestProps>) {
  const { roles } = useUser()
  const clx: any = useClasses(styles(theme))
  const editEffectiveDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditData &&
      setEditData({
        ...editData,
        ...{ effectiveDate: e.target.value },
      })
  }
  const notesValue = editData?.notes ?? notes ?? '-'

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item component={Box} display="flex" justifyContent={spaceBetween}>
        <Box>
          {location && (
            <LineItem
              textAlign="left"
              label="Location"
              value={location}
              dataTestId="location-current"
            />
          )}
          {status && (
            <LineItem
              textAlign="left"
              label="Status"
              dataTestId="status"
              value={
                <StatusChip
                  label={status.label}
                  type={status.type}
                  style={{ margin: '5px 0' }}
                  size="small"
                />
              }
            />
          )}
          {canChangeApprover && isEditActive && (
            <LineItem
              textAlign="left"
              label=""
              dataTestId="change-approver"
              value={
                <div className={clx.autocomplete}>
                  <ChangeApprover
                    requestId={requestId}
                    editData={editData}
                    setEditData={setEditData}
                  />
                </div>
              }
            />
          )}
          {reason && (
            <LineItem
              textAlign="left"
              label="Change reason"
              dataTestId="change-reason"
              value={
                roles.canViewPractitionerChangeReasons
                  ? `${reason.code} - ${reason.description}`
                  : reason.description
              }
            />
          )}

          {canEdit && type && reason && employeeId && isEditActive && (
            <LineItem
              textAlign="left"
              label=""
              dataTestId="change-reason-edit"
              value={
                <div className={clx.autocomplete}>
                  <SelectReason
                    data={{
                      reasonCode: (editData && editData.reason) ?? undefined,
                      // Employee normally has other values but we only care about ID here, so casting to any
                      employee: { id: employeeId } as any,
                    }}
                    type={type}
                    effectiveDateLOA={effectiveDate as Date}
                    setData={(newReasonCode) =>
                      setEditData &&
                      setEditData({ ...editData, reason: newReasonCode })
                    }
                    filterReasonId={reason.id}
                  />
                </div>
              }
            />
          )}

          {!isDetail && primaryJob && (
            <LineItem
              textAlign="left"
              label="ADP Job Title"
              dataTestId="primary-job"
              value={primaryJob}
            />
          )}
        </Box>

        <Box>
          {positionId && (
            <LineItem
              label="Position ID"
              dataTestId="position-id"
              textAlign="right"
              value={positionId}
            />
          )}
          {effectiveDate && (
            <LineItem
              label={'Effective date'}
              dataTestId="effective-date"
              value={dayjs(effectiveDate).format('MM/DD/YYYY')}
              textAlign="right"
              minWidth="30%"
            />
          )}
          {isEditActive && canEdit && (
            <TextField
              id="date"
              label="Effective Date"
              type="date"
              defaultValue={(editData && editData.effectiveDate) ?? null}
              onChange={editEffectiveDateChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
          {isDetail && primaryJob && (
            <LineItem
              label="ADP Job Title"
              dataTestId="primary-job"
              value={primaryJob}
              textAlign="right"
              minWidth="30%"
            />
          )}
        </Box>
      </Grid>
      {children && (
        <Grid item component={DetailCallout} m={2}>
          {children}
        </Grid>
      )}
      {type === 'Rehire' && (
        <Grid>
          <InfoBoxNotification>
            {isReinstatement ? (
              <>
                <ReinstatementReviewInfoMessage />
              </>
            ) : (
              <>
                <RehireReviewInfoMessage />
              </>
            )}
          </InfoBoxNotification>
        </Grid>
      )}
      <Grid item style={{ paddingTop: '2px', paddingBottom: '6px' }}>
        {isEditActive && canEdit && setEditData ? (
          <Notes
            data={{ notes: notesValue }}
            label={'Special Instructions'}
            setData={(newNotes) =>
              setEditData && setEditData({ ...editData, notes: newNotes })
            }
          />
        ) : (
          <LineItem
            label={notesLabel}
            dataTestId="special-instructions"
            value={notes ?? '-'}
            textAlign="center"
          />
        )}
      </Grid>
    </Grid>
  )
}
