import { APIResult, getXsrfToken, isXsrfProtectionEnabled } from 'api'
import { xsrfTokenCookieName, xsrfTokenQueryName } from 'appConstants'
import { AxiosResponse } from 'axios'
import LoadingPage from 'components/LoadingPage'
import React from 'react'
import { useQuery, useQueryClient } from 'react-query'

const setXsrfTokenCookie = (token: string) => {
  document.cookie = `${xsrfTokenCookieName}=${token};samesite=strict;secure=true;path=/`
}

type XsrfTokenApiResponse = AxiosResponse<APIResult<string>>

export const saveXsrfToken = (data: XsrfTokenApiResponse) => {
  if (!isXsrfProtectionEnabled) return
  if (data?.data?.data) {
    setXsrfTokenCookie(data?.data?.data)
  }
}

export function useXsrfTokenUpdater() {
  const queryClient = useQueryClient()
  const refetchXsrfToken = async () => {
    if (!isXsrfProtectionEnabled) return
    const data = await queryClient.fetchQuery<
      unknown,
      unknown,
      XsrfTokenApiResponse
    >(xsrfTokenQueryName)
    saveXsrfToken(data)
  }
  return { refetchXsrfToken }
}

export function XsrfTokenProvider({
  children,
}: React.PropsWithChildren<unknown>) {
  const { isLoading, isSuccess, data } = useQuery(
    xsrfTokenQueryName,
    getXsrfToken,
    {
      refetchOnWindowFocus: false,
      enabled: isXsrfProtectionEnabled,
    }
  )

  if (!isXsrfProtectionEnabled) return children

  if (isLoading) return <LoadingPage />

  if (isSuccess) {
    saveXsrfToken(data)

    return children
  }
  throw new Error(
    `Failed to fetch XSRF token. Response code: ${data?.status} - Body: ${data?.data}`
  )
}
