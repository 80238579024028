export function ReturnUrl() {
  switch (process.env.REACT_APP_ENV_TARGET) {
    case 'dev':
      return 'https://dev-discount.wksconnect.com/'
    case 'prod':
      return 'https://discount.wksconnect.com/'
    default:
      return window.location.href
  }
}
