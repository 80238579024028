import { Typography } from '@mui/material'
import Box from '@mui/material/Box'
import dayjs from 'dayjs'
import '../DiscountApp.css'
import { discountTheme, footerStyle } from '../DiscountTheme'
import CollapsibleTerms from './CollapsibleTerms'
import ColoredLine from './ColoredLine'
import Header from './Header'
import ThumbIcon from './ThumbIcon'

interface SuccessPageProps {
  employeeName: string
  locationName: string
  discountCode: string
}
// eslint-disable-next-line import/no-default-export
export default function Success({
  employeeName,
  locationName,
  discountCode,
}: SuccessPageProps) {
  const dateFormat = 'MMMM DD, YYYY'
  return (
    <>
      <Header />
      <div className="content">
        <Box display="flex" flexDirection="column" alignItems="center">
          <Box display="flex" flexDirection="column" alignItems="center">
            <ColoredLine style={{ margin: '42px 0 20px 0' }} />
            <Typography
              style={{ color: discountTheme.palette.primary.light }}
              textAlign="center"
              marginBottom="15px"
            >
              {`Enjoy discounts at WKS locations!`}
            </Typography>
            <Typography
              style={{ color: discountTheme.palette.primary.light }}
              textAlign="center"
            >
              <b>{`50% off`}</b>
              {` at Krispy Kreme and Blaze Pizza`}
              <br />
              <b>{`25% off`}</b>
              {` at Wendy's, Denny's, and El Pollo Loco`}
            </Typography>
            <ColoredLine style={{ margin: '20px 0 42px 0' }} />
          </Box>
          <ThumbIcon thumbsUp={true} />
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            paddingTop="25px"
            paddingBottom="10px"
          >
            <Typography color="textSecondary">Note to cashier:</Typography>
            <Typography color="textSecondary">Write code on receipt</Typography>
            <Box padding="20px 0">
              <Typography
                variant="h3"
                color="textSecondary"
                data-testid="discount-code"
              >
                {discountCode}
              </Typography>
            </Box>
            <Typography color="textSecondary">
              {dayjs().format(dateFormat)}
            </Typography>
          </Box>
        </Box>
      </div>
      <div style={footerStyle}>
        <Typography color="textSecondary">
          {employeeName}
          <br />
          {locationName}
        </Typography>
        <br />
        <CollapsibleTerms />
      </div>
    </>
  )
}
