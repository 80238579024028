import { Hidden } from '@mui/material'
import WksLogo from '../../assets/WKS_LOGO_WORDMARK_A_mono_white.svg'

export function ResponsiveWksLogo() {
  return (
    <Hidden mdDown>
      <img src={WksLogo} height="35" alt="WKS Restaurant Group Logo" />
    </Hidden>
  )
}
