import { useMemo } from 'react'
import TextField from '@mui/material/TextField'
import { StepProps, StepInfo } from 'types/StepProps'

export const notesStep: StepInfo = {
  cardLabel: 'Special Instructions',
  stepLabel: 'Special Instructions',
  url: 'notes',
}

export const MAXIMUM_NOTES_LENGTH = 300

interface NotesProps extends StepProps<{ notes?: string }, string> {
  label?: string
  placeholder?: string
}

// eslint-disable-next-line import/no-default-export
export default function Notes({
  data: { notes = '' },
  setData,
  label = 'Add notes',
  placeholder = 'Optional',
}: NotesProps) {
  notes = notes.slice(0, MAXIMUM_NOTES_LENGTH)

  const notesAreValid = useMemo(
    () => notes.length >= 0 && notes.length < MAXIMUM_NOTES_LENGTH,
    [notes.length]
  )

  return (
    <TextField
      id="note"
      label={label}
      placeholder={placeholder}
      value={notes}
      onChange={(e) => {
        const cappedNotes = e.target.value.slice(0, MAXIMUM_NOTES_LENGTH)
        setData(cappedNotes)
      }}
      multiline
      rows={4}
      variant="outlined"
      InputLabelProps={{ shrink: true }}
      error={!notesAreValid}
      helperText={notesAreValid ? '' : 'limitation of 300 characters'}
      fullWidth
      autoFocus
    />
  )
}
