import { BASE_URL, isXsrfProtectionEnabled } from 'api'
import { xsrfTokenFormFieldName } from 'appConstants'
import { User } from 'context/Authenticate'
import dayjs from 'dayjs'
import { SnackbarKey, useSnackbar } from 'notistack'
import { useCallback, useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom'
import { readXsrfTokenFromCookie } from 'utils'
import { User as DiscountUser } from '../discount/DiscountAuthenticate'

const events = ['click', 'scroll', 'onkeypress', 'onmousedown']
const SESSION_KEY = 'lastTimeStamp'
const TIMEOUT_IN_SECONDS = 60 * 60
const SECONDS_LEFT_WHEN_ALERT_APPEARS = 60
const CHECKING_INTERVAL_IN_SECONDS = 30

// based on https://javascript.plainenglish.io/building-an-auto-logout-session-timeout-with-react-using-hooks-e7804ef973ec
export function useSessionTimeout(user: DiscountUser | User | null) {
  const warningInactiveInterval = useRef<any>()
  const startTimerInterval = useRef<any>()
  const warningSnackbar = useRef<SnackbarKey>()
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const location = useLocation()

  const postLogout = useCallback(() => {
    const isLocalhost = window.location?.host?.includes('localhost')
    const url = isLocalhost ? window.location?.origin : location.pathname
    const form = document.createElement('form')
    form.method = 'post'
    form.action = `${BASE_URL}/api/logout-proactive${
      url !== undefined ? '?returnUrl=' + url : ''
    }`
    if (isXsrfProtectionEnabled) {
      const input = document.createElement('input')
      input.type = 'hidden'
      input.name = xsrfTokenFormFieldName
      input.value = readXsrfTokenFromCookie()
      form.appendChild(input)
    }

    document.body.appendChild(form)
    form.submit()
  }, [location.pathname])

  const logOut = useCallback(() => {
    clearInterval(warningInactiveInterval.current)
    localStorage.removeItem(SESSION_KEY)
    closeSnackbar(warningSnackbar.current)
    postLogout()
  }, [closeSnackbar, postLogout])

  const warningCheck = useCallback(() => {
    // Need to use localStorage instead of sessionStorage in order to share across tabs
    const storedTimeStamp = localStorage.getItem(SESSION_KEY) ?? ''
    if (!storedTimeStamp) {
      logOut()
    } else {
      const parsedDate = dayjs(storedTimeStamp)
      const secondsPassed = dayjs().diff(parsedDate, 'second')

      if (
        secondsPassed >=
        TIMEOUT_IN_SECONDS - SECONDS_LEFT_WHEN_ALERT_APPEARS
      ) {
        if (!warningSnackbar.current) {
          warningSnackbar.current = enqueueSnackbar(
            'You will be logged out due to inactivity soon.',
            {
              variant: 'warning',
              persist: true,
            }
          )
        }
      } else {
        closeSnackbar(warningSnackbar.current)
        warningSnackbar.current = undefined
      }

      if (secondsPassed >= TIMEOUT_IN_SECONDS) {
        logOut()
      }
    }
  }, [enqueueSnackbar, closeSnackbar, logOut])

  // warning timer
  const warningInactive = useCallback(() => {
    clearTimeout(startTimerInterval.current)

    warningInactiveInterval.current = setInterval(
      warningCheck,
      CHECKING_INTERVAL_IN_SECONDS * 1000
    )

    warningCheck()
  }, [warningCheck])

  // start inactive check
  const timeChecker = useCallback(() => {
    startTimerInterval.current = setTimeout(
      () => {
        warningInactive()
      },
      (TIMEOUT_IN_SECONDS - SECONDS_LEFT_WHEN_ALERT_APPEARS) * 1000
    )
  }, [warningInactive])

  // reset interval timer
  const resetTimer = useCallback(() => {
    if (user) {
      const timeStamp = dayjs()
      localStorage.setItem(SESSION_KEY, timeStamp.toISOString())
    } else {
      localStorage.removeItem(SESSION_KEY)
    }
    timeChecker()
    closeSnackbar(warningSnackbar.current)
    warningSnackbar.current = undefined
    clearInterval(warningInactiveInterval.current)
  }, [user, timeChecker, closeSnackbar])

  // Life cycle hook
  useEffect(() => {
    events.forEach((event) => {
      window.addEventListener(event, resetTimer)
    })

    resetTimer()

    return () => {
      clearTimeout(startTimerInterval.current)
    }
  }, [resetTimer])

  return []
}
