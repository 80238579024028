import Box, { BoxProps } from '@mui/material/Box'
import React, { PropsWithChildren } from 'react'

export function FullScreenBox({
  children,
  ...props
}: PropsWithChildren<BoxProps>) {
  return (
    <Box
      height="calc(100vh - 120px)"
      width="100%"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      bgcolor="background.default"
      {...props}
    >
      {children}
    </Box>
  )
}
