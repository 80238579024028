export const API_URL = process.env.REACT_APP_API_URL
export const SENTRY_RELEASE = process.env.REACT_APP_SENTRY_RELEASE
export const GA_TRACKING_ID = process.env.REACT_APP_GA_TRACKING_ID
export const ENV_BASED_TEXT =
  {
    dev: 'Dev Environment',
    test: 'Test Environment',
    local: 'Local Environment',
  }[process.env.REACT_APP_ENV_TARGET ?? ''] || null
export const isProductionEnv = process.env.REACT_APP_ENV_TARGET === 'prod'
