import { OpenFile, Viewer } from '@react-pdf-viewer/core'
import '@react-pdf-viewer/core/lib/styles/index.css'
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout'
import '@react-pdf-viewer/default-layout/lib/styles/index.css'
import { useUser } from 'context/Authenticate'
import {
  MoreActionsPopover,
  ToolbarProps,
  ToolbarSlot,
} from '@react-pdf-viewer/toolbar'
import React from 'react'
import { GlobalWorkerOptions } from 'pdfjs-dist'

// The worker url below should be updated to match the version of pdfjs installed in package.json
// See https://react-pdf-viewer.dev/docs/basic-usage/
GlobalWorkerOptions.workerSrc =
  'https://unpkg.com/pdfjs-dist@3.7.107/build/pdf.worker.min.js'

const ToolbarWithoutDownload = (
  Toolbar: (props: ToolbarProps) => React.ReactElement
): React.ReactElement => {
  return (
    <Toolbar>
      {(slots: ToolbarSlot) => {
        const {
          CurrentPageInput,
          EnterFullScreen,
          GoToNextPage,
          GoToPreviousPage,
          NumberOfPages,
          Open,
          Print,
          ShowSearchPopover,
          Zoom,
          ZoomIn,
          ZoomOut,
        } = slots

        return (
          <div className="rpv-toolbar">
            <div className="rpv-toolbar-left">
              <div className="rpv-toolbar-item">
                <ShowSearchPopover />
              </div>
              <div className="rpv-core-display-hidden rpv-core-display-block-small">
                <div className="rpv-toolbar-item">
                  <GoToPreviousPage />
                </div>
              </div>
              <div className="rpv-toolbar-item">
                <CurrentPageInput /> / <NumberOfPages />
              </div>
              <div className="rpv-core-display-hidden rpv-core-display-block-small">
                <div className="rpv-toolbar-item">
                  <GoToNextPage />
                </div>
              </div>
            </div>
            <div className="rpv-toolbar-center">
              <div className="rpv-toolbar-item">
                <ZoomOut />
              </div>
              <div className="rpv-core-display-hidden rpv-core-display-block-small">
                <div className="rpv-toolbar-item">
                  <Zoom />
                </div>
              </div>
              <div className="rpv-toolbar-item">
                <ZoomIn />
              </div>
            </div>
            <div className="rpv-toolbar-right">
              <div className="rpv-core-display-hidden rpv-core-display-block-medium">
                <div className="rpv-toolbar-item">
                  <EnterFullScreen />
                </div>
              </div>
              <div className="rpv-core-display-hidden rpv-core-display-block-medium">
                <div className="rpv-toolbar-item">
                  <Open />
                </div>
              </div>
              <div className="rpv-core-display-hidden rpv-core-display-block-medium">
                <div className="rpv-toolbar-item">
                  <Print />
                </div>
              </div>
              <div className="rpv-toolbar-item">
                <MoreActionsPopover toolbarSlot={slots} />
              </div>
            </div>
          </div>
        )
      }}
    </Toolbar>
  )
}
export function UploadPdfDisplay({
  url,
  fileName,
}: {
  url: string
  fileName: string
}) {
  const user = useUser()
  const canDownload = user.roles.canDownload
  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    toolbarPlugin: {
      getFilePlugin: {
        fileNameGenerator: (file: OpenFile) => {
          return fileName
        },
      },
    },
    renderToolbar: canDownload ? undefined : ToolbarWithoutDownload,
  })

  return <Viewer fileUrl={url} plugins={[defaultLayoutPluginInstance]} />
}
