import { Box } from '@mui/material'
import { POSJobCode } from 'api'
import { GridLineItem } from 'components/LineItem'
import {
  StatusChangeDetailsPOSJobCode,
  StatusChangeResponseBase,
} from 'types/StatusChangeResponse'

export function POSJobCodeInfo({ posJobCode }: { posJobCode?: POSJobCode }) {
  return (
    <Box>
      <GridLineItem
        label="POS Job Code"
        value={`${posJobCode?.alohaPosJobCodeId}-${posJobCode?.longName}`}
        dataTestId="pos-job-code"
        textAlign="center"
      />
    </Box>
  )
}

export function POSJobCodeDetail({
  statusChangeResponse: response,
}: {
  statusChangeResponse: StatusChangeResponseBase
}) {
  const details = response.details as StatusChangeDetailsPOSJobCode
  return <POSJobCodeInfo posJobCode={details.posJobCode} />
}
