import { Box, Button } from '@mui/material'
enum HasTakenActionKeys {
  YES = 'YES',
  NO = 'NO',
}

// used in lazy import
// eslint-disable-next-line import/no-default-export
export default function YesOrNoQuestion({
  hasTakenAction,
  setData,
  handleNext,
  navigate,
  getPrevPage,
  isFinalState,
}) {
  return (
    <>
      <Button
        color={
          hasTakenAction === HasTakenActionKeys.NO ? 'secondary' : undefined
        }
        variant="contained"
        onClick={() => {
          setData(HasTakenActionKeys.NO)
        }}
        size="large"
      >
        NO
      </Button>
      &nbsp;&nbsp;&nbsp;&nbsp;
      <Button
        color={
          hasTakenAction === HasTakenActionKeys.YES ? 'secondary' : undefined
        }
        variant="contained"
        onClick={() => {
          setData(HasTakenActionKeys.YES)
        }}
        size="large"
      >
        YES
      </Button>
      <Box display="flex" justifyContent="space-between">
        <Button onClick={() => navigate(getPrevPage())}>Back</Button>

        <Button
          variant="contained"
          color="primary"
          onClick={handleNext}
          disabled={
            hasTakenAction === undefined ||
            hasTakenAction === '' ||
            hasTakenAction === HasTakenActionKeys.NO
          }
        >
          {isFinalState ? 'Finished' : 'Next'}
        </Button>
      </Box>
    </>
  )
}
