import Typography, { TypographyProps } from '@mui/material/Typography'
import React, { PropsWithChildren } from 'react'
import { theme } from 'theme'

export function EmployeeName({
  children,
  ...props
}: PropsWithChildren<TypographyProps>) {
  return (
    <Typography
      data-testid="employee-name"
      variant="h6"
      style={{ color: theme.palette.primary.light }}
      {...props}
    >
      {children}
    </Typography>
  )
}
