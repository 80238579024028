import { Box } from '@mui/material'
import { GridLineItem } from 'components/LineItem'
import {
  StatusChangeDetailsRehireReinstatement,
  StatusChangeResponseBase,
} from 'types/StatusChangeResponse'

import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { center } from 'types/PropProperty'
import { RateUnit } from 'types/RateUnit'
import { formatCurrency } from 'utils'

export function RehireReinstatementInfo({
  previousJobTitle,
  newJobTitle,
  previousRateOfPayType,
  newRateOfPayType,
  previousRateOfPay,
  newRateOfPay,
  terminationDate,
  isRehireable,
  terminationReasonCodeAndDesc,
  previousLocation,
  destinationLocation,
}) {
  return (
    <>
      <Box>
        <GridLineItem
          label={'Termination Date'}
          dataTestId="termination-date"
          value={terminationDate}
          textAlign="center"
        />
        <GridLineItem
          label="Termination Reason Code"
          value={terminationReasonCodeAndDesc}
          dataTestId="term-reason-code"
          textAlign="center"
        />
        <GridLineItem
          label="Is Rehireable?"
          value={isRehireable === true ? 'Yes' : 'No'}
          dataTestId="is-rehireable"
          textAlign="center"
        />
      </Box>
      <TableContainer
        sx={{ alignItems: center, justifyContent: center, display: 'flex' }}
      >
        <Table sx={{ maxWidth: 400 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell align="center">Old</TableCell>
              <TableCell align="center">New</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow
              key="location"
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                Location
              </TableCell>
              <TableCell align="center">{previousLocation}</TableCell>
              <TableCell align="center">{destinationLocation}</TableCell>
            </TableRow>
            <TableRow
              key="job-title"
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                Job Title
              </TableCell>
              <TableCell align="center">{previousJobTitle}</TableCell>
              <TableCell align="center" data-testid="new-job">
                {newJobTitle}
              </TableCell>
            </TableRow>
            <TableRow
              key="pay-type"
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                Rate of Pay Type
              </TableCell>
              <TableCell align="center">
                {previousRateOfPayType === RateUnit.HOURLY
                  ? 'Hourly'
                  : 'Salary'}
              </TableCell>
              <TableCell align="center">
                {newRateOfPayType === RateUnit.HOURLY ? 'Hourly' : 'Salary'}
              </TableCell>
            </TableRow>
            <TableRow
              key="pay"
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                Rate of Pay
              </TableCell>
              <TableCell align="center" data-testid="current-rate-fixed">
                {formatCurrency(previousRateOfPay)}
              </TableCell>
              <TableCell align="center" data-testid="rate-fixed">
                {formatCurrency(newRateOfPay)}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

export function RehireReinstatementDetail({
  statusChangeResponse: response,
}: {
  statusChangeResponse: StatusChangeResponseBase
}) {
  const details = response.details as StatusChangeDetailsRehireReinstatement
  return (
    <>
      <RehireReinstatementInfo
        terminationDate={details.terminationDate}
        isRehireable={details.isRehireable}
        terminationReasonCodeAndDesc={details.terminationReasonCodeAndDesc}
        previousLocation={details.previousLocation.desc}
        destinationLocation={details.destinationLocation.desc}
        previousJobTitle={`${details.existingAdpJobTitleCode} - ${details.existingJobTitleDescription}`}
        newJobTitle={`${details.newAdpJobTitleCode} -  ${details.newJobTitleDescription}`}
        previousRateOfPay={details.existingRate}
        newRateOfPay={details.newRate}
        previousRateOfPayType={details.existingRateUnit}
        newRateOfPayType={details.newRateUnit}
      />
    </>
  )
}
