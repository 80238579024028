import CurrencyTextField from '@lupus-ai/mui-currency-textfield'
import { Alert } from '@mui/material'
import Box from '@mui/material/Box'
import Collapse from '@mui/material/Collapse'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormGroup from '@mui/material/FormGroup'
import FormLabel from '@mui/material/FormLabel'
import Grid from '@mui/material/Grid'
import Radio from '@mui/material/Radio/Radio'
import RadioGroup from '@mui/material/RadioGroup/RadioGroup'
import Typography from '@mui/material/Typography'
import SpecifyPercentage from 'components/steps/SpecifyPercentage'
import { useClasses } from 'hooks/useClasses'
import { useMemo, useState } from 'react'
import { theme } from 'theme'
import { JobTitleRateCode } from 'types/Employee'
import { JobTitleRateUnit, RATE_UNIT_VERBOSE, RateUnit } from 'types/RateUnit'
import { StepProps } from 'types/StepProps'
import { roundToDecimalPlaces } from 'utils'
import SpecifyRate, { RateObject, formatter } from './SpecifyRate'
export enum rateChangeKeys {
  CHANGE = "Change the employee's rate of pay",
  NOCHANGE = "Do NOT change the employee's rate of pay",
}

export interface SpecifyRateDataBase extends RateObject {
  rateUnitChange?: boolean
  changeRate?: boolean
  ratePercentageChange?: string
  currentJobTitleRateCode: JobTitleRateCode
  newJobTitleRateCode: JobTitleRateCode
  rateUnit: RateUnit | undefined
}

export type SpecifyRateAskProps = StepProps<
  SpecifyRateDataBase,
  {
    rate?: number
    ratePercentageChange?: string
    changeRate?: boolean
    newRateUnit: RateUnit | undefined
    convertedRate?: number
  }
>

const styles = (theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
    paddingBottom: '20px',
  },
  formGroup: {
    alignItems: 'center',
  },
  gridRate: {
    position: 'relative',
    left: '0px',
    top: '0px',
  },
  typographySalaryLabel: {
    marginTop: '20px',
    marginRight: '40px',
  },
  currencyTextField: {
    backgroundColor: `#bdc3c7 !important`,
    borderRadius: '5px !important',
    '&.MuiFocused': {
      color: '#000000 !important',
    },
  },
})

export function SpecifyRateAsk({ data, setData }: SpecifyRateAskProps) {
  const classes: any = useClasses(styles(theme))

  const [firstInRate, setFirstInRate] = useState(true)
  const handleFirstInRate = (value) => {
    setFirstInRate(value)
  }
  const rateChangeKey = useMemo(() => {
    if (typeof data.changeRate === 'undefined') {
      return undefined
    } else if (data.changeRate === false) {
      return rateChangeKeys.NOCHANGE
    } else {
      return rateChangeKeys.CHANGE
    }
  }, [data.changeRate])

  const { isRateChangeMandatory, newRateUnit, convertedRate } = useMemo(() => {
    let newRateUnit: RateUnit | undefined, convertedRate: number | undefined

    let currentJobTitleRateCode: string | undefined =
      data.currentJobTitleRateCode
    if (currentJobTitleRateCode === JobTitleRateUnit.BOTH)
      currentJobTitleRateCode = data.rateUnit

    // Is a rate change mandatory based on Hourly/Salary or user choice.
    const isRateChangeMandatory =
      (currentJobTitleRateCode !== data.newJobTitleRateCode &&
        data.newJobTitleRateCode !== JobTitleRateUnit.BOTH) ||
      data.rateUnitChange

    // Rate conversion for Hourly to Salary.
    if (
      (currentJobTitleRateCode !== data.newJobTitleRateCode &&
        data.newJobTitleRateCode !== JobTitleRateUnit.BOTH) ||
      (data.rateUnitChange === undefined &&
        data.newJobTitleRateCode === JobTitleRateUnit.BOTH) ||
      data.rateUnitChange
    ) {
      if (data.rateUnit === RateUnit.HOURLY) {
        convertedRate = (data.currentRate ?? 0) * 40.0 * 2
        newRateUnit = RateUnit.SALARY
      } else {
        convertedRate = (data.currentRate ?? 0) / 2.0 / 40.0
        newRateUnit = RateUnit.HOURLY
      }
    } else {
      convertedRate = data.currentRate
      newRateUnit = data.rateUnit
    }

    convertedRate = Math.round(convertedRate * 100) / 100

    return {
      isRateChangeMandatory: isRateChangeMandatory,
      newRateUnit: newRateUnit,
      convertedRate: convertedRate,
    }
  }, [data])

  const handlePercentageChange = (percentage) => {
    if (percentage && percentage !== data.ratePercentageChange) {
      setData({
        rate: roundToDecimalPlaces(
          convertedRate + (convertedRate * percentage) / 100
        ),
        ratePercentageChange: percentage,
        changeRate: data.changeRate || isRateChangeMandatory,
        newRateUnit: newRateUnit,
      })
    }
  }

  const handleRateChange = (newRate) => {
    const percentageChange = roundToDecimalPlaces(
      ((newRate - convertedRate) / convertedRate) * 100
    )
    setData({
      rate: newRate,
      ratePercentageChange: percentageChange.toString(),
      changeRate: data.changeRate || isRateChangeMandatory,
      newRateUnit: newRateUnit,
    })
  }

  return (
    <>
      {isRateChangeMandatory ? (
        <Box className={classes.root}>
          <Alert severity="info">
            {`The rate type has changed to ${
              RATE_UNIT_VERBOSE[newRateUnit as RateUnit]
            }. Please change the rate of pay.`}
          </Alert>
        </Box>
      ) : (
        <FormControl component="fieldset">
          <FormLabel component="legend">
            Do you want to change the rate?
          </FormLabel>
          <FormGroup className={classes.formGroup}>
            <RadioGroup
              aria-label="make-primary"
              name="make-primary"
              value={rateChangeKey}
              onChange={(e) => {
                if (e.target.value === rateChangeKeys.CHANGE) {
                  setData({
                    rate: data.rate,
                    changeRate: true,
                    newRateUnit,
                  })
                } else {
                  setData({ rate: data.rate, changeRate: false, newRateUnit })
                }
              }}
            >
              <FormControlLabel
                value={rateChangeKeys.CHANGE}
                control={<Radio />}
                label={rateChangeKeys.CHANGE}
              />
              <FormControlLabel
                value={rateChangeKeys.NOCHANGE}
                control={<Radio />}
                label={rateChangeKeys.NOCHANGE}
              />
            </RadioGroup>
          </FormGroup>
        </FormControl>
      )}
      <Collapse
        in={rateChangeKey === rateChangeKeys.CHANGE || isRateChangeMandatory}
      >
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item xs={10} spacing={1}>
            <CurrencyTextField
              label="Current Rate"
              variant="outlined"
              inputProps={{
                inputMode: 'numeric',
                'data-testid': 'current-rate',
                style: {
                  color: theme.palette.primary.main,
                },
              }}
              value={convertedRate ? convertedRate.toFixed(2) : 0}
              currencySymbol="$"
              outputFormat="number"
              decimalCharacter="."
              digitGroupSeparator=","
              color="inherit"
              disabled={true}
              className={classes.currencyTextField}
              modifyValueOnWheel={false}
            />
          </Grid>
          <br />
          <SpecifyPercentage
            percent={data.ratePercentageChange}
            onPercentageChange={handlePercentageChange}
          />
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={1}
          >
            <Grid item xs={10}>
              <Grid container direction="row" alignItems="center">
                <Grid item xs={11} marginTop={'1.5rem'} marginLeft={'.8rem'}>
                  <SpecifyRate
                    data={{
                      ...data,
                      currentRate: convertedRate,
                      type: newRateUnit as RateUnit,
                    }}
                    setData={(val) => handleRateChange(val?.rate)}
                    firstInRate={firstInRate}
                    handleFirstInRate={handleFirstInRate}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {!!data.rate && newRateUnit === RateUnit.SALARY && (
            <Grid item>
              <Typography className={classes.typographySalaryLabel}>
                Est Annual Salary {formatter.format(data.rate * 26)}
              </Typography>
            </Grid>
          )}
        </Grid>
      </Collapse>
    </>
  )
}
