import { Avatar, Button, Menu, Typography } from '@mui/material'
import MenuItem from '@mui/material/MenuItem'
import { LogoutForm } from 'components/LogoutForm'
import { LogoutMenuButton } from 'components/LogoutMenuButton'
import { User } from 'context/Authenticate'
import { useClasses } from 'hooks/useClasses'
import React from 'react'
import ArrowDownLogo from '../assets/ARROW_DOWN.svg'
import PersonLogo from '../assets/AUTHENTICATE_PERSON.svg'
import { theme } from '../theme'

export function UserDropDown(props: { personInfo: User | undefined }) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }
  const styles = {
    menu: {
      '& .MuiPaper-root': {
        backgroundColor: theme.palette.primary.contrastText,
        marginTop: '2px',
      },
      '& .MuiList-padding': {
        padding: 0,
      },
    },
    menuItem: {
      width: '190px',
      padding: 0,
      '&:hover': {
        backgroundColor: theme.palette.secondary.light,
      },
    },
  }

  const classes: any = useClasses(styles)
  return (
    <>
      <Button
        aria-controls="user-menu"
        aria-haspopup="true"
        onClick={handleClick}
        style={{
          border: '1px solid ' + theme.palette.primary.contrastText,
          width: '190px',
          height: '50px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
        }}
        color="inherit"
        data-testid="logout-menu"
      >
        <Avatar
          alt="User Avatar"
          src={PersonLogo}
          style={{
            backgroundColor: props.personInfo?.isImpersonating
              ? theme.palette.error.dark
              : theme.palette.primary.contrastText,
          }}
        />
        <Typography
          variant="subtitle2"
          style={{
            display: 'flex',
            fontSize: '11px',
            marginLeft: '20px',
            color: props.personInfo?.isImpersonating
              ? theme.palette.error.dark
              : theme.palette.primary.contrastText,
          }}
          data-testid="logged-in-username"
        >
          {props.personInfo && props.personInfo?.name?.length > 30
            ? props.personInfo?.name.slice(0, 30) + '...'
            : props.personInfo?.name}
          <img src={ArrowDownLogo} alt="logout" />
        </Typography>
      </Button>
      <Menu
        id="user-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        className={classes.menu}
      >
        <MenuItem className={classes.menuItem} data-testid="logout-button">
          <LogoutForm>
            <LogoutMenuButton />
          </LogoutForm>
        </MenuItem>
        {props.personInfo?.isImpersonating ? (
          <MenuItem
            className={classes.menuItem}
            data-testid="exit-impersonate-button"
          >
            <LogoutForm logoutUrl="admin/impersonate/logout">
              <LogoutMenuButton buttonText="Exit Impersonation" />
            </LogoutForm>
          </MenuItem>
        ) : null}
      </Menu>
    </>
  )
}
