import CircularProgress from '@mui/material/CircularProgress'
import CssBaseline from '@mui/material/CssBaseline'
import { FullScreenBox } from 'components/FullScreenBox'

// eslint-disable-next-line import/no-default-export
export default function LoadingPage() {
  return (
    <>
      <CssBaseline />
      <FullScreenBox height="100%" position="absolute" top={0} left={0}>
        <CircularProgress size="3rem" />
      </FullScreenBox>
    </>
  )
}
