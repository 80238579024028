import { Box, Button } from '@mui/material'

export function StepButtons({
  disabled,
  handlePrev,
  handleNext,
  nextText = 'Next',
}: {
  disabled: boolean
  handlePrev: () => any
  handleNext: () => any
  nextText?: string
}) {
  return (
    <>
      <Box my={3} />
      <Box display="flex" justifyContent="space-between">
        <Button onClick={handlePrev}>Back</Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleNext}
          disabled={disabled}
          data-testid="proceed-in-workflow"
        >
          {nextText}
        </Button>
      </Box>
    </>
  )
}
