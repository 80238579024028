import Box from '@mui/material/Box'
import { postTerminationChange } from 'api'
import { EmployeeName } from 'components/EmployeeName'
import { TerminationInfo } from 'components/request-detail/TerminationDetail'
import { SharedRequestInfo } from 'components/SharedRequestInfo'
import { SubmitButton } from 'components/SubmitButton'
import { REQUEST_LIST } from 'link-paths'
import { useSnackbar } from 'notistack'
import React, { FormEvent } from 'react'
import { useMutation } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { StepInfo } from 'types/StepProps'
import {
  readyToSubmit,
  TerminationChangeState,
  WorkedDay,
} from 'types/TerminationChangeRequest'

export const reviewTerminationStep: StepInfo = {
  cardLabel: 'Review Request',
  stepLabel: 'Review',
  url: 'termination-review',
}

// eslint-disable-next-line import/no-default-export
export default function ReviewTermination({
  data,
}: {
  data: TerminationChangeState
}) {
  const [ready, setReady] = React.useState<boolean>(false)
  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate()

  React.useEffect(() => {
    setReady(readyToSubmit(data))
  }, [data])

  // TODO: Change to useReview
  const { mutateAsync, isLoading } = useMutation(postTerminationChange, {
    onSuccess: (result) => {
      enqueueSnackbar(
        `Termination change request submitted successfully ${
          result?.data.id ? `(id: ${result.data.id})` : ''
        }`,
        {
          variant: 'success',
        }
      )
      navigate(REQUEST_LIST)
    },
  })
  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    await mutateAsync(data as Required<TerminationChangeState>)
  }

  const workedDays = data.workedDays
    ? data.workedDays.filter((day: WorkedDay) => day.isEnabled)
    : null

  return (
    <Box component="form" mt="-24px" onSubmit={onSubmit}>
      <EmployeeName gutterBottom>{data.employee?.employeeName}</EmployeeName>
      <SharedRequestInfo
        positionId={data.employee?.adpPositionId}
        effectiveDate={data.effectiveDate}
        notes={data.notes}
        reason={data.reasonCode}
        location={data.employee?.homeLocation?.desc}
      >
        <TerminationInfo
          isVoluntary={data.reasonCode?.isVoluntary}
          eligibleForRehire={data.reasonCode?.isRehireable}
          replacementEmployeeName={data.replacement?.employeeName}
          workedDays={workedDays as WorkedDay[]}
          lastDate={data.lastDayWorked}
          rateUnit={data.employee?.rateUnit}
        />
      </SharedRequestInfo>
      <Box my={3} />
      <SubmitButton ready={ready} isLoading={isLoading} />
    </Box>
  )
}
