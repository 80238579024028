import { Box, Grid, Typography } from '@mui/material'
export function LineItem({
  label = '',
  value,
  dataTestId,
  box = {},
  textAlign,
  minWidth = 'auto',
  labelColor = 'textSecondary',
}) {
  return (
    <Box
      textAlign={textAlign ?? 'left'}
      {...box}
      minWidth={minWidth}
      lineHeight={0.4}
    >
      <Typography
        variant="subtitle1"
        color="textSecondary"
        style={{ color: labelColor }}
      >
        {label}
      </Typography>
      <Typography
        variant="subtitle2"
        style={{
          wordBreak: 'break-word',
          maxWidth: '180px',
          display: 'inline-flex',
          textAlign: textAlign ?? 'left',
        }}
        data-testid={dataTestId}
      >
        {value}
      </Typography>
    </Box>
  )
}
export function GridLineItem({ label, value, dataTestId, textAlign }) {
  return (
    <Grid item>
      <LineItem
        label={label}
        value={value}
        dataTestId={dataTestId}
        textAlign={textAlign}
      />
    </Grid>
  )
}
