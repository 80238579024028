import TrendingFlat from '@mui/icons-material/TrendingFlat'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import CROSS from 'assets/CROSS.svg'
import TICK from 'assets/TICK.svg'
import { LineItem } from 'components/LineItem'
import dayjs from 'dayjs'
import { theme } from 'theme'
import { hasLeaveOfAbsenceDocumentKeys } from 'types/LeaveOfAbsenceRequest'
import {
  StatusChangeDetailsLeaveOfAbsence,
  StatusChangeResponseBase,
} from 'types/StatusChangeResponse'
export function LeaveOfAbsenceInfo({
  effectiveDate,
  returnDate,
  hasLeaveOfAbsenceDocument,
  lastDayWorked,
  replacementEmployeeName,
}) {
  return (
    <Box display="flex" flexDirection="column">
      <Box
        display="flex"
        flexDirection="row"
        alignContent="center"
        justifyContent="space-evenly"
      >
        <Box display="flex" flexDirection="column">
          <Typography
            variant="h6"
            align="center"
            color="inherit"
            style={{ marginBottom: '10px' }}
          >
            Start Date
          </Typography>
          <Box
            component="span"
            color="grey.700"
            data-testid="loa-effectiveDate"
          >
            {dayjs(effectiveDate).format('MM/DD/YYYY')}
          </Box>
        </Box>
        <Box
          component="span"
          color="grey.700"
          data-testid="loa--trend-icon"
          style={{ marginTop: '20px' }}
        >
          <TrendingFlat fontSize="large" />
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          alignContent="center"
          justifyContent="center"
        >
          <Typography
            variant="h6"
            align="center"
            color="inherit"
            style={{ marginBottom: '10px' }}
          >
            End Date
          </Typography>
          <Box component="span" color="grey.700" data-testid="return-date">
            {dayjs(returnDate).format('MM/DD/YYYY')}
          </Box>
        </Box>
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        alignContent="center"
        justifyContent="space-evenly"
      >
        &nbsp;
        {lastDayWorked && (
          <LineItem
            label="Last Day Worked"
            dataTestId="last-day-worked"
            value={dayjs(lastDayWorked).format('MM/DD/YYYY')}
            textAlign="center"
            minWidth="30%"
          />
        )}
        &nbsp;
        {replacementEmployeeName && (
          <LineItem
            label="New manager of direct reports"
            value={replacementEmployeeName}
            dataTestId="replacement-employee-name"
            textAlign="center"
          />
        )}
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        alignContent="center"
        justifyContent="center"
      >
        {hasLeaveOfAbsenceDocument === hasLeaveOfAbsenceDocumentKeys.YES ||
        hasLeaveOfAbsenceDocument === true ? (
          <>
            <div>
              <img
                src={TICK}
                style={{
                  backgroundColor: theme.palette.success.main,
                  borderRadius: '10px',
                  marginRight: '5px',
                  marginTop: '14px',
                }}
                alt="tick"
              />
            </div>
            <Typography style={{ wordBreak: 'break-word', marginTop: '12px' }}>
              Employee has supporting documentation
            </Typography>
          </>
        ) : (
          <>
            <div>
              <img
                src={CROSS}
                style={{
                  backgroundColor: theme.palette.error.dark,
                  borderRadius: '10px',
                  marginRight: '5px',
                  marginTop: '14px',
                }}
                alt="cross"
              />
            </div>
            <Typography style={{ wordBreak: 'break-word', marginTop: '12px' }}>
              Employee does NOT have supporting documentation
            </Typography>
          </>
        )}
      </Box>
    </Box>
  )
}

export function LeaveOfAbsenceDetail({
  statusChangeResponse: response,
}: {
  statusChangeResponse: StatusChangeResponseBase
}) {
  const details = response.details as StatusChangeDetailsLeaveOfAbsence
  return (
    <LeaveOfAbsenceInfo
      effectiveDate={response.effectiveDate}
      returnDate={details.returnDate}
      hasLeaveOfAbsenceDocument={details.hasLeaveOfAbsenceDocument}
      lastDayWorked={details.lastDayWorkedUtc}
      replacementEmployeeName={details.replacementEmployeeName}
    />
  )
}
