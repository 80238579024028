import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import { Theme } from '@mui/material/styles'
import { DATE_FORMAT_DISPLAY } from 'appConstants'
import CROSS from 'assets/CROSS.svg'
import { CheckMarkOrCross } from 'components/CheckMarkOrCross'
import { GridLineItem } from 'components/LineItem'
import dayjs from 'dayjs'
import { useClasses } from 'hooks/useClasses'
import { theme } from 'theme'
import { RateUnit } from 'types/RateUnit'
import {
  StatusChangeDetailsTermination,
  StatusChangeResponseBase,
} from 'types/StatusChangeResponse'
const styles = (theme: Theme) => ({
  label: {
    fontSize: 16,
  },
  accodionDetails: {
    width: 475,
  },
  workedDays: { margin: '5px 0px' },
})

export function TerminationInfo({
  isVoluntary,
  eligibleForRehire,
  replacementEmployeeName,
  lastDate,
  workedDays,
  rateUnit,
  remainingWorkHours,
}: {
  isVoluntary
  eligibleForRehire
  replacementEmployeeName
  lastDate
  workedDays
  rateUnit
  remainingWorkHours?: number | undefined
}) {
  const clx: any = useClasses(styles(theme))
  return (
    <>
      <Box
        style={{
          textAlign: 'center',
          display: 'inline-block',
        }}
      >
        {isVoluntary === null ? (
          <Typography
            style={{ wordBreak: 'break-word', margin: '5px 0' }}
            align="left"
          >
            <img
              src={CROSS}
              style={{
                backgroundColor: theme.palette.error.dark,
                borderRadius: '10px',
                marginRight: '4px',
              }}
              alt="cross"
            />
            Missing reason code
          </Typography>
        ) : (
          <>
            <Typography
              style={{ wordBreak: 'break-word', margin: '5px 0' }}
              align="left"
              data-testid="termination-reason-is-voluntary"
            >
              <CheckMarkOrCross isCheckMark={isVoluntary} />
              This is{isVoluntary ? '' : ' NOT'} a voluntary termination.
            </Typography>
            <Typography
              style={{ wordBreak: 'break-word', margin: '5px 0' }}
              align="left"
              data-testid="termination-reason-can-rehire"
            >
              <CheckMarkOrCross isCheckMark={eligibleForRehire} />
              This employee is{eligibleForRehire ? '' : ' NOT'} eligible for
              rehire.
            </Typography>
          </>
        )}
      </Box>
      {!workedDays && remainingWorkHours !== undefined && (
        <GridLineItem
          label="Remaining work hours"
          value={remainingWorkHours}
          dataTestId="hours-remaining"
          textAlign="center"
        />
      )}
      {/* display lastdayworked only when NO worked days are selected */}
      {!workedDays && lastDate && (
        <GridLineItem
          label="Last day worked"
          value={dayjs(lastDate).format('MM/DD/YYYY')}
          dataTestId="last-day-worked"
          textAlign="center"
        />
      )}
      &nbsp;
      {replacementEmployeeName && (
        <GridLineItem
          label="New manager of direct reports"
          value={replacementEmployeeName}
          dataTestId="replacement-employee-name"
          textAlign="center"
        />
      )}
      {workedDays && (
        <Box className={clx.workedDays}>
          {rateUnit === RateUnit.HOURLY && (
            <HoulyWorkedDaysReviewPage days={workedDays} />
          )}
          {rateUnit === RateUnit.SALARY && (
            <SalaryWorkedDaysReviewPage days={workedDays} />
          )}
        </Box>
      )}
    </>
  )
}

export function TerminationDetail({
  statusChangeResponse: response,
}: {
  statusChangeResponse: StatusChangeResponseBase
}) {
  const details = response.details as StatusChangeDetailsTermination
  return (
    <TerminationInfo
      isVoluntary={details.isVoluntary}
      eligibleForRehire={details.eligibleForRehire}
      replacementEmployeeName={details.replacementEmployeeName}
      workedDays={details.workedDays}
      lastDate={details.lastDayWorkedUtc}
      rateUnit={details.newRateUnit}
      remainingWorkHours={details.remainingWorkHours}
    />
  )
}

function HoulyWorkedDaysReviewPage({ days }: { days }) {
  return (
    <>
      <TableContainer>
        <Table stickyHeader size="small">
          <TableHead>
            <TableCell align="center">Last Days Worked</TableCell>
            <TableCell align="center">Regular Hours</TableCell>
            <TableCell align="center">Overtime Hours</TableCell>
          </TableHead>
          <TableBody data-testid="hours-worked">
            {days.map((day, rowIndex) => (
              <TableRow key={rowIndex}>
                <TableCell component="th" scope="row" data-testid="worked-day">
                  <Typography align="center">
                    {dayjs(day.date).format(DATE_FORMAT_DISPLAY)}&nbsp;-&nbsp;
                    {dayjs(day.date as Date).format('dddd')}
                  </Typography>
                </TableCell>
                <TableCell align="center" data-testid="regular-hours">
                  {day.regularHours ?? 0}
                </TableCell>
                <TableCell align="center" data-testid="overtime-hours">
                  {day.overTimeHours ?? 0}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TableContainer />
      </TableContainer>
    </>
  )
}

function SalaryWorkedDaysReviewPage({ days }: { days }) {
  return (
    <>
      <TableContainer>
        <Table stickyHeader size="small">
          <TableHead>
            <TableCell align="center">Last Days Worked</TableCell>
          </TableHead>
          <TableBody>
            {days.map((day, rowIndex) => (
              <TableRow key={rowIndex}>
                <TableCell component="th" scope="row" align="center">
                  <Typography>
                    {dayjs(day.date).format(DATE_FORMAT_DISPLAY)}&nbsp;-&nbsp;
                    {dayjs(day.date as Date).format('dddd')}
                  </Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TableContainer />
      </TableContainer>
    </>
  )
}
