import { AppBar, useMediaQuery } from '@mui/material'
import { ReactNode } from 'react'
import { theme } from 'theme'

export function ResponsiveAppBar({ children }: { children: ReactNode }) {
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'))

  return (
    <AppBar color={isMdUp ? 'primary' : 'secondary'} position="static">
      {children}
    </AppBar>
  )
}
