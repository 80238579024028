import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormGroup from '@mui/material/FormGroup'
import FormLabel from '@mui/material/FormLabel'
import Radio from '@mui/material/Radio/Radio'
import RadioGroup from '@mui/material/RadioGroup/RadioGroup'
import { SpecifyRateDataBase } from 'components/steps/SpecifyRateAsk'
import React, { useMemo } from 'react'
import { RateUnit, RATE_UNIT_VERBOSE } from 'types/RateUnit'
import { StepProps } from 'types/StepProps'

export type SpecifyRateTypeAskProps = StepProps<
  SpecifyRateDataBase,
  {
    newRateUnit: RateUnit | undefined
    rateUnitChange?: boolean
  }
>

export function SpecifyRateTypeAsk({ data, setData }: SpecifyRateTypeAskProps) {
  const newRateUnit = useMemo(() => {
    if (data.currentJobTitleRateCode !== data.newJobTitleRateCode) {
      return data.rateUnit === RateUnit.HOURLY
        ? RateUnit.SALARY
        : RateUnit.HOURLY
    } else {
      return data.rateUnit
    }
  }, [data.currentJobTitleRateCode, data.newJobTitleRateCode, data.rateUnit])

  const changeText = `Change the employee's rate type to ${
    RATE_UNIT_VERBOSE[newRateUnit as RateUnit]
  }`
  const noChangeText = `Keep the employee's rate type of ${
    RATE_UNIT_VERBOSE[data.rateUnit as RateUnit]
  }`
  const rateChangeKey = useMemo(() => {
    if (typeof data.rateUnitChange === 'undefined') {
      return undefined
    } else if (data.rateUnitChange === false) {
      return noChangeText
    } else {
      return changeText
    }
  }, [changeText, data.rateUnitChange, noChangeText])

  return (
    <>
      <FormControl component="fieldset">
        <FormLabel component="legend">
          {`Do you want to change the employee from ${
            RATE_UNIT_VERBOSE[data.rateUnit as RateUnit]
          } to ${RATE_UNIT_VERBOSE[newRateUnit as RateUnit]}?`}
        </FormLabel>
        <FormGroup style={{ alignItems: 'center' }}>
          <RadioGroup
            aria-label="make-primary"
            name="make-primary"
            value={rateChangeKey}
            onChange={(e) => {
              if (e.target.value === changeText) {
                setData({ newRateUnit: newRateUnit, rateUnitChange: true })
              } else {
                setData({ newRateUnit: data.rateUnit, rateUnitChange: false })
              }
            }}
          >
            <FormControlLabel
              value={changeText}
              control={<Radio />}
              label={changeText}
            />
            <FormControlLabel
              value={noChangeText}
              control={<Radio />}
              label={noChangeText}
            />
          </RadioGroup>
        </FormGroup>
      </FormControl>
    </>
  )
}
