import * as Sentry from '@sentry/react'
import { FallbackComponent } from 'components/FallbackComponent'
import {
  IsAuthenticatedResponse,
  addApiInterceptors,
  getIsAuthenticated,
} from 'api'
import LoadingPage from 'components/LoadingPage'
import UnauthenticatedRouting from 'components/layout/UnauthenticatedRouting'
import { Authorization } from 'context/Authorization'
import { UNAUTHENTICATED_PATHS } from 'link-paths'
import React, { createContext, useCallback, useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { useLocation, useNavigate } from 'react-router-dom'
import { SavedWebError } from 'components/FallbackNotes'

export interface User {
  name: IsAuthenticatedResponse['name']
  id: IsAuthenticatedResponse['employeeId']
  roles: Authorization
  associateId: IsAuthenticatedResponse['associateId']
  issuer: IsAuthenticatedResponse['issuer']
  isImpersonating: IsAuthenticatedResponse['isImpersonating']
  primaryFranchisorAbbr: IsAuthenticatedResponse['primaryFranchisorAbbr']
  noAccessPageType: IsAuthenticatedResponse['noAccessPageType']
  supportNotes: IsAuthenticatedResponse['supportNotes']
}

const AuthenticationContext = createContext<User | undefined>(undefined)

export function AuthenticationProvider({
  children,
}: React.PropsWithChildren<unknown>) {
  const navigate = useNavigate()
  const location = useLocation()

  const [loggedInUser, setLoggedInUser] = useState<User | undefined>(undefined)

  const isLocalFile = window.location.protocol === 'file:'

  const { isLoading, isSuccess, isError, data } = useQuery(
    'is-authenticated',
    getIsAuthenticated,
    {
      retry: false,
      refetchOnWindowFocus: false,
      onError: undefined,
      enabled: !loggedInUser && !isLocalFile,
    }
  )

  const navigateTo = useCallback(
    (url, replace = false, queryParams = {}) => {
      navigate(url, { replace, state: queryParams })
    },
    [navigate]
  )

  useEffect(() => {
    if (isLocalFile) return

    if (isError) {
      const requestedPath = location.pathname + location.search
      if (
        requestedPath &&
        requestedPath !== '/' &&
        !UNAUTHENTICATED_PATHS.some((path) => requestedPath.startsWith(path))
      ) {
        localStorage.setItem('returnUrl', requestedPath)
        navigateTo('/', true)
      }
    }
  }, [isError, isLocalFile, location.pathname, location.search, navigateTo])

  if (isLocalFile) {
    return FallbackComponent(SavedWebError)({})
  }

  if (isSuccess && data && !loggedInUser) {
    const {
      name,
      employeeId,
      roles,
      associateId,
      issuer,
      isImpersonating,
      primaryFranchisorAbbr,
      noAccessPageType,
      supportNotes,
    } = data.data.data

    const auth = new Authorization(roles)

    addApiInterceptors()

    const user: User = {
      name,
      id: employeeId,
      roles: auth,
      associateId,
      issuer,
      isImpersonating,
      primaryFranchisorAbbr,
      noAccessPageType,
      supportNotes,
    }

    Sentry.setUser({ username: name, id: employeeId?.toString() })

    setLoggedInUser(user)

    const url = localStorage.getItem('returnUrl')
    if (url) {
      localStorage.removeItem('returnUrl')
      const queryParams = new URLSearchParams(location.search)
      navigateTo(url, false, Object.fromEntries(queryParams.entries()))
    }
  }

  if (isLoading) return <LoadingPage />

  if (isError) {
    const returnUrl = localStorage.getItem('returnUrl') || '/'
    return <UnauthenticatedRouting returnUrl={returnUrl} />
  }

  if (!loggedInUser) return null

  return (
    <React.StrictMode>
      <AuthenticationContext.Provider value={loggedInUser}>
        {children}
      </AuthenticationContext.Provider>
    </React.StrictMode>
  )
}

export const AuthenticationConsumer = AuthenticationContext.Consumer

export function useUser() {
  const context = React.useContext(AuthenticationContext)
  if (context === undefined) {
    throw new Error('useUser must be used within a AuthenticationProvider')
  }
  return context
}
