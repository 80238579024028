import NotInterestedIcon from '@mui/icons-material/NotInterested'
import { Typography } from '@mui/material'
import { FullScreenBox } from 'components/FullScreenBox'

// eslint-disable-next-line import/no-default-export
export default function NoRoleAccess({
  roleName,
}: {
  roleName: string | string[]
}) {
  return (
    <FullScreenBox
      sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
    >
      <NotInterestedIcon sx={{ fontSize: 72 }} />
      <Typography variant="h4" align="center">
        {Array.isArray(roleName)
          ? `User is missing the following roles:`
          : `User is missing the "${roleName}" role`}
      </Typography>
      {Array.isArray(roleName) && (
        <Typography component="ul" sx={{ mt: 2 }}>
          {roleName.map((role) => (
            <Typography component="li" key={role}>
              {role}
            </Typography>
          ))}
        </Typography>
      )}
    </FullScreenBox>
  )
}
