import { HELPDESK_EMAIL } from 'appConstants'

export const HelpdeskContact = () => (
  <>
    <a
      href={`mailto:${HELPDESK_EMAIL}`}
      target="_blank"
      rel="noopener noreferrer"
    >
      {HELPDESK_EMAIL}
    </a>{' '}
    or call/text 562-222-0990
  </>
)

export const ErrorOccurred = (
  <>
    An error has occurred. Please reload page. If this continues to happen,
    please contact WKS Help Desk at &nbsp;
    <HelpdeskContact />.
  </>
)

export const SavedWebError = (
  <>
    This seems to be a saved version of our website, which will not work
    correctly. Please delete the shortcut you made on your desktop and visit our
    website directly in your web browser.
  </>
)
