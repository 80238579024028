import CROSS from 'assets/CROSS.svg'
import TICK from 'assets/TICK.svg'
import { theme } from 'theme'

export const CheckMarkOrCross = ({ isCheckMark }: { isCheckMark: boolean }) => {
  const style = {
    borderRadius: '10px',
    marginRight: '4px',
    backgroundColor: isCheckMark
      ? theme.palette.success.main
      : theme.palette.error.dark,
  }

  const iconSrc = isCheckMark ? TICK : CROSS
  const iconAlt = isCheckMark ? 'tick' : 'cross'

  return <img src={iconSrc} style={style} alt={iconAlt} />
}
