import { Link, Typography } from '@mui/material'
import Box from '@mui/material/Box'
import dayjs from 'dayjs'
import CollapsibleTerms from './CollapsibleTerms'
import '../DiscountApp.css'
import { discountTheme, footerStyle } from '../DiscountTheme'
import ColoredLine from './ColoredLine'
import Header from './Header'
import ThumbIcon from './ThumbIcon'

interface NoAccessPageProps {
  employeeName: string
  locationName: string
  associateId: string
}
// eslint-disable-next-line import/no-default-export
export default function NoAccess({
  employeeName,
  locationName,
  associateId,
}: NoAccessPageProps) {
  const hrEmail = 'HR@wksusa.com'
  const hrEmailSubject =
    'WKS Discount - Redemption Question (' + associateId + ')'

  const dateFormat = 'MMMM DD, YYYY'
  return (
    <>
      <Header />
      <div className="content">
        <Box display="flex" flexDirection="column" alignItems="center">
          <ColoredLine style={{ margin: '42px 0 20px 0' }} />
          <Typography style={{ color: discountTheme.palette.primary.light }}>
            {"Sorry, you're not eligible"}
          </Typography>
          <ColoredLine style={{ margin: '20px 0 42px 0' }} />
          <ThumbIcon thumbsUp={false} />
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            paddingTop="25px"
            paddingBottom="10px"
          >
            <Typography color="textSecondary">
              {"Doesn't seem right?"}
            </Typography>
            <Typography color="textSecondary">
              Contact your manager or
            </Typography>
            <Typography color="textSecondary">
              <Link href={`mailto:${hrEmail}?subject=${hrEmailSubject}`}>
                Email Human Resources
              </Link>
            </Typography>
            <Typography color="textSecondary">
              {dayjs().format(dateFormat)}
            </Typography>
          </Box>
        </Box>
      </div>
      <div style={footerStyle}>
        <Typography color="textSecondary">
          {employeeName}
          <br />
          {locationName}
        </Typography>
        <br />
        <CollapsibleTerms />
      </div>
    </>
  )
}
