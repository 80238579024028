import {
  DatePickerProperties,
  StandardDatePicker,
} from 'components/StandardDatePicker'
import dayjs from 'dayjs'
import { Employee } from 'types/Employee'
import { StepInfo, StepProps } from 'types/StepProps'

export const effectiveDateStep: StepInfo = {
  cardLabel: 'Specify effective date',
  stepLabel: 'Effective date',
  url: 'effective-date',
}

export function getDefaultEffectiveDate() {
  return null // per #623
}

interface EffectiveDateProps
  extends StepProps<
    {
      effectiveDate?: Date | null
      fiscalWeekStartDate: Employee['fiscalWeekStartDate']
      dateRange?: Employee['dateRange']
    },
    Date
  > {
  DatePickerProps?: DatePickerProperties
  restrictToFiscalWeekStartDate?: boolean
}

// eslint-disable-next-line import/no-default-export
export default function EffectiveDate({
  data: { effectiveDate, fiscalWeekStartDate, dateRange },
  setData,
  DatePickerProps,
  restrictToFiscalWeekStartDate,
}: EffectiveDateProps) {
  return (
    <StandardDatePicker
      dateValue={
        effectiveDate === undefined || effectiveDate === null
          ? null
          : dayjs(effectiveDate)
      }
      DatePickerProps={DatePickerProps}
      restrictToFiscalWeekStartDate={restrictToFiscalWeekStartDate}
      fiscalWeekStartDate={fiscalWeekStartDate}
      onChange={setData}
      dateRange={dateRange}
    />
  )
}
