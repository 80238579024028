import { pick } from 'lodash'
import React from 'react'
import { useCallback } from 'react'
import { StepInfo } from 'types/StepProps'

export type KeysToClear = Record<string, { url: string; keys: string[] }>

export function useStateClearingReducer<T>(
  keysToClear: KeysToClear,
  steps: StepInfo[],
  initialState: T,
  setCompleted: React.Dispatch<React.SetStateAction<Record<string, boolean>>>
): [T, (action: Partial<T>) => void] {
  const [data, internalDispatch] = React.useReducer(
    (state: Partial<T>, action: Partial<T>) => {
      const stateWithClearedKeys = Object.keys(action).reduce(
        (newState, changedKey) => {
          const keysToReset = keysToClear[changedKey]?.keys ?? []
          return {
            ...state,
            ...pick(initialState, keysToReset),
          }
        },
        state
      )

      return {
        ...stateWithClearedKeys,
        ...action,
      }
    },
    initialState
  )

  const dispatch = useCallback(
    (action: Partial<T>) => {
      internalDispatch(action)
      const changedKeys = Object.keys(action)
      if (changedKeys.length && keysToClear[changedKeys[0]]) {
        const url = keysToClear[changedKeys[0]].url
        const indexOfStep = steps.findIndex((step) => step.url === url)
        const updated = {}
        steps.forEach((step, index) => {
          updated[step.url] = !!(index < indexOfStep)
        })
        setCompleted(updated)
      }
    },
    [internalDispatch, steps, setCompleted, keysToClear]
  )

  // We are clearing keys so Typescript throws an error if we don't cast back to T
  // Up to the developer to make sure the keys we're clearing are not required on the type
  return [data as T, dispatch]
}
