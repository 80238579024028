import { Alert } from '@mui/material'
import Box from '@mui/material/Box'
import React from 'react'

export const InfoBoxNotification = ({
  children,
}: {
  children: React.ReactNode
}) => {
  return (
    <>
      <Box />
      <Alert
        style={{ display: 'inline-flex' }}
        variant="outlined"
        severity="info"
        sx={{ maxWidth: '400px' }}
      >
        {children}
      </Alert>
      <Box my={3} />
    </>
  )
}
