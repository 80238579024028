import { Box, Stepper as MuiStepper } from '@mui/material'
import { styled } from '@mui/system'
import { ReactNode } from 'react'

const StyledStepper = styled(MuiStepper)(({ theme }) => ({
  padding: 16,
  top: 0,
  left: 0,
  width: '100%',
  height: '70px',
  backgroundColor: theme.palette.background.paper,
}))

interface StepperWorkflowProps {
  activeStep: number
  nonLinear?: boolean | undefined
  stepperChildren: ReactNode
  children: ReactNode
}

export function StepperWorkflow({
  activeStep,
  nonLinear,
  stepperChildren,
  children,
}: StepperWorkflowProps) {
  return (
    <Box display="flex" flexDirection="column" height="100%" width="100%">
      <StyledStepper activeStep={activeStep} nonLinear={nonLinear}>
        {stepperChildren}
      </StyledStepper>
      <Box
        flex="1" // Make the Box fill the available vertical space
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        {children}
      </Box>
    </Box>
  )
}

// eslint-disable-next-line import/no-default-export
export default StepperWorkflow
