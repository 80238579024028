import Box from '@mui/material/Box'
import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'
import { discountTheme } from '../DiscountTheme'
import ColoredLine from './ColoredLine'

const darkColorStyle = {
  color: discountTheme.palette.primary.dark,
}

// eslint-disable-next-line import/no-default-export
export default function TermsAndConditions() {
  const wksdiscountUrl = 'https://wksdiscount.com'
  const listOfLocationsUrl = 'https://wksusa.com/locations'
  const hrEmail = 'HR@wksusa.com'
  const hrEmailSubject = 'WKS Discount - Login'

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      style={darkColorStyle}
    >
      <ColoredLine />
      <Typography variant="body2" align="left">
        Off-duty team members and managers are entitled to a 50% discount to
        feed themselves and their immediate family at any WKS locations and
        across all WKS brands.
        <br />
        <br />
        For a list of locations visit{' '}
        <Link href={listOfLocationsUrl} variant="body2" color={'secondary'}>
          wksusa.com/locations
        </Link>
      </Typography>
      <ColoredLine />
      <Typography>How to redeem</Typography>
      <ColoredLine />
      <Typography variant="body2" align="left">
        Before you order, navigate to{' '}
        <Link href={wksdiscountUrl} color={'secondary'}>
          wksdiscount.com
        </Link>{' '}
        on a mobile device. You will be required to login using your ADP
        credentials. If you need help logging in, contact{' '}
        <Link
          href={`mailto:${hrEmail}?subject=${hrEmailSubject}`}
          color={'secondary'}
        >
          {hrEmail}
        </Link>
        <br />
        <br />
        If you are eligible, present your screen with the five-digit code to the
        cashier who will write down the code on your receipt. You may be asked
        to confirm your identity.
      </Typography>
      <ColoredLine />
      <Typography>Rules for redemption</Typography>
      <ColoredLine />
      <Typography component="div" variant="body2" align="left">
        <ul>
          <li>Lobby only. No drive-through.</li>
          <li>
            Place the order through an on-duty team member who enters it into
            the POS system.
          </li>
          <li>ALL items ordered must be included on the receipt.</li>
          <li>
            The cashier must write the five-digit code presented from
            wksdiscount.com on the receipt.
          </li>
          <li>Limit orders to a reasonable amount.</li>
          <li>
            Catering and large-group orders are excluded, benefit is for
            team-members and their immediate family only.
          </li>
          <li>Limit to one redemption per day.</li>
          <li>
            Not properly placing an order in the POS system, not paying for
            items, or obtaining a greater discount than allowed without manager
            approval constitutes theft and may result in disciplinary action up
            to, and including, termination.
          </li>
        </ul>
      </Typography>
    </Box>
  )
}
