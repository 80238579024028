import { Alert, Box, TextField } from '@mui/material'
import { getLegalLastName } from 'api'
import { StepButtons } from 'components/steps/StepButtons'
import { useState } from 'react'
import { useQuery } from 'react-query'

// eslint-disable-next-line import/no-default-export
export default function LegalLastName({
  data,
  setData,
  handleNext,
  handlePrevious,
}) {
  const [errorMessage, setErrorMessage] = useState('')
  const { isLoading, isFetching } = useQuery(
    ['legalLastName', data.employee],
    () => {
      return getLegalLastName(
        data.employee.id,
        data.effectiveDate,
        data.location.companyCode
      )
    },
    {
      refetchOnWindowFocus: false,
      enabled: !!data.employee || !!data.effectiveDate,
      onSuccess: (data) => {
        setData(data.data.isReinstatement)
      },
      onError: () => {
        setErrorMessage(
          'Employee search failed.  Please contact the help desk.'
        )
      },
    }
  )

  return (
    <Box maxWidth={400} margin="0 auto" paddingLeft="5px" paddingRight="5px">
      {errorMessage && (
        <Box padding={1} justifyContent="center">
          <Alert severity="error">{errorMessage}</Alert>
        </Box>
      )}
      <TextField
        id="legal-last-name"
        variant="outlined"
        value={data.legalLastName || ''}
        label="Legal Last Name"
        onChange={(e) => setData(e.target.value)}
        fullWidth
      />
      <StepButtons
        disabled={isLoading || isFetching || data.legalLastName === ''}
        handlePrev={handlePrevious}
        handleNext={handleNext}
      />
    </Box>
  )
}
