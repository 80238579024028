export enum RateUnit {
  HOURLY = 'H',
  SALARY = 'S',
}

export enum JobTitleRateUnit {
  BOTH = 'B',
}
export const RATE_UNIT_LABEL = {
  [RateUnit.HOURLY]: 'hourly',
  [RateUnit.SALARY]: 'per biweekly pay period',
}

export const RATE_UNIT_VERBOSE = {
  [RateUnit.HOURLY]: 'hourly',
  [RateUnit.SALARY]: 'salary',
}
