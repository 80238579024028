import { GA_TRACKING_ID } from 'env'
import { useEffect, useState } from 'react'
import { User } from './DiscountAuthenticate'
import ga4 from 'react-ga4'

// eslint-disable-next-line import/no-default-export
export default function UseEmployeeTracking(trackingUser: User | null) {
  const [initialized, setInitialized] = useState(false)
  const [user] = useState(trackingUser)

  function isLocalhost() {
    return window.location.href.includes('localhost')
  }

  useEffect(() => {
    if (!isLocalhost()) {
      ga4.initialize(GA_TRACKING_ID ?? '')
      setInitialized(true)
    }
  }, [])

  useEffect(() => {
    if (!isLocalhost() && initialized) {
      if (user !== null) {
        ga4.set({
          dimension1: user.discountCode,
          dimension2: user.locationName,
        })
        ga4.send({
          hitType: 'pageview',
          page: window.location.pathname + window.location.search,
        })
      }
    }
  }, [initialized, user])
}
