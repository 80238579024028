import { Grid } from '@mui/material'
import Typography from '@mui/material/Typography'
import { CheckMarkOrCross } from 'components/CheckMarkOrCross'

export const ReasonCodeDetails = ({ reasonCode }) => {
  return (
    <Grid container direction="column" spacing={1} alignItems="flex-start">
      <Grid item>
        <Typography
          variant="subtitle1"
          color={reasonCode?.isVoluntary ? 'inherit' : 'textSecondary'}
          data-testid="termination-reason-is-voluntary"
        >
          <CheckMarkOrCross isCheckMark={reasonCode?.isVoluntary} />
          This is{reasonCode?.isVoluntary ? '' : ' NOT'} a voluntary
          termination.
        </Typography>
      </Grid>
      <Grid item>
        <Typography
          variant="subtitle1"
          color={reasonCode?.isRehireable ? 'inherit' : 'textSecondary'}
          data-testid="termination-reason-can-rehire"
        >
          <CheckMarkOrCross isCheckMark={reasonCode?.isRehireable} />
          This employee is{reasonCode?.isRehireable ? '' : ' NOT'} eligible for
          rehire.
        </Typography>
      </Grid>
    </Grid>
  )
}
