import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Grid,
  Link,
  Typography,
} from '@mui/material'
import { postKkdKorberInvoices } from 'api'
import { useUser } from 'context/Authenticate'
import { useTitle } from 'hooks/useTitle'
import { ACCOUNTING_KKD_KORBER_SAGE_MAPPINGS } from 'link-paths'
import { useSnackbar } from 'notistack'
import NoRoleAccess from 'pages/NoRoleAccess'
import { useMutation } from 'react-query'

// eslint-disable-next-line import/no-default-export
export default function KKDKorberInvoices() {
  const mappingPageUrl = `https://v2.wksconnect.com${ACCOUNTING_KKD_KORBER_SAGE_MAPPINGS}`
  useTitle('KKD Korber Invoices')
  const emailSentTo = 'ar@wksusa.com'
  const { roles } = useUser()
  const { enqueueSnackbar } = useSnackbar()
  const { mutateAsync: callSubmit, isLoading } = useMutation(
    postKkdKorberInvoices,
    {
      onSuccess: (result) => {
        enqueueSnackbar(result.data.data, {
          variant: 'success',
        })
      },
    }
  )

  if (!roles.canViewAccountingArKkdKorberInvoices) {
    return <NoRoleAccess roleName={'Accounting - AR - KKD Korber Invoices'} />
  }

  return (
    <Card sx={{ width: '60%' }}>
      <CardHeader title="KKD Korber Invoices" />
      <CardContent>
        <Grid container direction="column" spacing={3}>
          <Grid item>
            <Typography>
              To update Korber Customer to Sage Customer Mapping, please{' '}
              <Link href={mappingPageUrl}>
                <u>CLICK HERE</u>
              </Link>
            </Typography>
          </Grid>
          <Grid item>
            <Typography>
              {`On pushing this button, the KKD Korber CSV files found in KKD Korber SFTP will be processed 
          into Sage CSV imports, pushed to a Google Drive Folder, and emailed to
          ${emailSentTo}.`}
            </Typography>
          </Grid>
          <br />
          <Grid item>
            <Box>
              <Button
                variant="contained"
                disabled={isLoading}
                onClick={callSubmit}
                data-testid="process-now"
              >
                {isLoading ? <CircularProgress size="1.5rem" /> : 'Process Now'}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}
