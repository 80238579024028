import {
  Card,
  CardMedia,
  CardActionArea,
  Link,
  Typography,
  Grid,
} from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'

import { styled } from '@mui/system'

const StyledCard = styled(Card)(({ theme }) => ({
  backgroundColor: theme?.palette?.primary?.light,
  alignItems: 'center',
  display: 'flex',
  margin: '5px',
  color: theme?.palette?.common?.white,
}))

const FlexDiv = styled('div')(() => ({
  alignItems: 'center',
  height: '20vh',
  minHeight: '30px',
  display: 'flex',
}))

export function EsignCard({ to = '/', image, name }) {
  return (
    <StyledCard>
      <CardActionArea>
        <Grid container justifyContent={'center'}>
          <Link component={RouterLink} to={to} color="inherit">
            <FlexDiv>
              <Grid item xs={6} p={'20px'}>
                <CardMedia component="img" image={image} alt="Esign Logo" />
              </Grid>
              <Grid item xs={6}>
                <Typography variant="h6">{name}</Typography>
              </Grid>
            </FlexDiv>
          </Link>
        </Grid>
      </CardActionArea>
    </StyledCard>
  )
}
