import { useMediaQuery } from '@mui/material'
import Mobile_Logo from 'assets/wks_connect_2_white.png'
import Larger_Logo from 'assets/wks_connect_2_yellow.png'
import { theme } from 'theme'

export function ResponsiveWksConnectLogo() {
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'))

  return (
    <img
      src={isMdUp ? Larger_Logo : Mobile_Logo}
      width="181"
      alt="WKS Connect Logo"
    />
  )
}
