import {
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material'
import { Theme } from '@mui/material/styles'
import { Reason } from 'api'
import { DATE_FORMAT_DISPLAY } from 'appConstants'
import dayjs from 'dayjs'
import React, { useEffect, useState } from 'react'
import { theme } from 'theme'
import { Employee } from 'types/Employee'
import { RateUnit } from 'types/RateUnit'
import { StepInfo, StepProps } from 'types/StepProps'
import { WorkedDay } from 'types/TerminationChangeRequest'
import { useClasses } from 'hooks/useClasses'

const daysInWeek = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
]
const dateFormat = 'MM/DD/YYYY'

const styles = (theme: Theme) => ({
  tablebody: {
    maxHeight: 300,
    overflow: 'auto',
  },
  helpText: {
    margin: 10,
    width: 430,
    textAlign: 'center',
  },
  numberFormat: {
    width: 80,
    '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
  },
  redNumberFormat: {
    width: 80,
    '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    backgroundColor: '#ffb74d',
  },
  bold: {
    fontWeight: 500,
  },
  tableHead: {
    backgroundColor: theme.palette.primary.dark,
  },
})

const getDatesBetweenDates = (startDate, endDate) => {
  let dates: any = []

  const theDate = new Date(startDate)
  while (theDate < endDate) {
    dates = [...dates, dayjs(theDate).format(dateFormat)]
    theDate.setDate(theDate.getDate() + 1)
  }
  dates = [...dates, dayjs(endDate).format(dateFormat)]
  return Array.from(new Set(dates))
}

const areSameDates = (date1, date2) => {
  return dayjs(date1).diff(date2, 'day') === 0
}

export const workedDaysStep = (rateUnit?: string): StepInfo => {
  return {
    cardLabel:
      `${rateUnit}` === RateUnit.HOURLY
        ? 'Enter Hours Worked'
        : 'Select Days Worked',
    stepLabel: 'Worked Days',
    url: 'worked-days',
  }
}

type workedDayProps = StepProps<
  {
    employee?: Employee
    workedDays?: WorkedDay[]
    lastDayWorked?: Date | null
    reasonCode?: Reason | undefined
  },
  WorkedDay[]
>

// eslint-disable-next-line import/no-default-export
export default function WorkedDays({
  data: { employee, workedDays, lastDayWorked, reasonCode },
  setData,
}: workedDayProps) {
  if (employee?.rateUnit === RateUnit.HOURLY) {
    return (
      <RenderHourlyWorkedDays
        workedDays={workedDays}
        setData={setData}
        lastDayWorked={lastDayWorked}
        location={employee.homeLocation?.state}
        reasonCodeIsVoluntary={reasonCode?.isVoluntary}
      />
    )
  } else if (employee?.rateUnit === RateUnit.SALARY) {
    return (
      <RenderSalaryWorkedDays
        workedDays={workedDays}
        setData={setData}
        lastDayWorked={lastDayWorked}
        employee={employee}
      />
    )
  } else {
    return <></>
  }
}

function RenderHourlyWorkedDays({
  workedDays,
  setData,
  lastDayWorked,
  location,
  reasonCodeIsVoluntary,
}: {
  workedDays
  setData
  lastDayWorked
  location
  reasonCodeIsVoluntary
}) {
  const clx: any = useClasses(styles(theme))

  const [days, setDays] = useState(workedDays !== undefined ? workedDays : [])

  useEffect(() => {
    if (workedDays === undefined) {
      // Checking if the last day is after 31 from today then cal betweeen dates by -30
      const betweenDays = dayjs(lastDayWorked as Date).isAfter(
        dayjs(new Date()).add(31, 'day')
      )
        ? getDatesBetweenDates(
            dayjs(new Date()).subtract(30, 'day'),
            lastDayWorked
          )
        : getDatesBetweenDates(new Date(), lastDayWorked)
      const hourlyWorkedDays = betweenDays.map((day) => ({
        date: day,
        regularHours: null,
        overTimeHours: null,
        isEnabled: false,
        isLastDay:
          day === dayjs(lastDayWorked).format(dateFormat) ? true : false,
      }))
      return setDays(hourlyWorkedDays)
    }
  }, [lastDayWorked, workedDays])

  const handleChangeHours =
    (rowIndex: number, key: string) =>
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = e.target.value
        ? +parseFloat(e.target.value).toFixed(2)
        : ''
      const regex = /^((?:[0-9]|1[0-9]|2[0-3])(?:\.\d{1,2})?|24(?:\.00?)?)$/
      if (regex.test(newValue.toString())) {
        const buffer = days.slice()
        buffer[rowIndex][key] = newValue
        buffer[rowIndex]['isEnabled'] = true
        setDays(buffer)
        setData(buffer)
      } else {
        const buffer = days.slice()
        buffer[rowIndex][key] = null
        // This map is to preserve data when navigated between steps and if lastday is
        // changed new set of dates are calculated
        const newDays = buffer.map((day) => {
          if (day.regularHours === null && day.overTimeHours === null) {
            return {
              regularHours: day.regularHours,
              overTimeHours: day.overTimeHours,
              date: day.date,
              isEnabled: false,
              isLastDay: areSameDates(day.date, lastDayWorked),
            }
          } else {
            return {
              regularHours: day.regularHours,
              overTimeHours: day.overTimeHours,
              date: day.date,
              isEnabled: true,
              isLastDay: areSameDates(day.date, lastDayWorked),
            }
          }
        })
        setDays(newDays)
        setData(newDays)
      }
    }

  return (
    <>
      {location === 'CA' && !reasonCodeIsVoluntary && (
        <Typography className={clx.helpText}>
          On the last day worked, the employee must be paid 2 hours or one half
          of their scheduled time for that day, whichever is greater.
        </Typography>
      )}
      <Typography className={clx.helpText}>30 minutes = 0.5 hours</Typography>
      <TableContainer className={clx.tablebody}>
        <Table stickyHeader size="small">
          <TableHead className={clx.tableHead}>
            <TableRow>
              <TableCell>Date</TableCell>
              <TableCell align="right">Regular Hours</TableCell>
              <TableCell align="right">Overtime Hours</TableCell>
            </TableRow>
          </TableHead>

          <TableBody data-testid="hours-worked">
            {days.map((day: WorkedDay, rowIndex: number) => (
              <TableRow key={rowIndex}>
                <TableCell scope="row" data-testid="worked-day">
                  <Typography
                    className={
                      areSameDates(day.date, lastDayWorked)
                        ? clx.bold
                        : undefined
                    }
                  >
                    {dayjs(day.date as Date).format(DATE_FORMAT_DISPLAY)}
                    &nbsp;-&nbsp;
                    {dayjs(day.date as Date).format('dddd')}
                    {areSameDates(day.date, lastDayWorked) ? '*' : ''}
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <TextField
                    value={day.regularHours ?? ''}
                    onChange={handleChangeHours(rowIndex, 'regularHours')}
                    name="numberformat"
                    id={`regular-hours-${rowIndex}`}
                    variant="outlined"
                    size="small"
                    className={
                      areSameDates(day.date, lastDayWorked) &&
                      !day.regularHours &&
                      !day.overTimeHours
                        ? clx.redNumberFormat
                        : clx.numberFormat
                    }
                    inputProps={{ 'data-testid': 'regular-hours' }}
                    type={'number'}
                  />
                </TableCell>
                <TableCell align="right">
                  <TextField
                    value={day.overTimeHours ?? ''}
                    onChange={handleChangeHours(rowIndex, 'overTimeHours')}
                    name="numberformat"
                    id={`overTime-hours-${rowIndex}`}
                    variant="outlined"
                    size="small"
                    className={
                      areSameDates(day.date, lastDayWorked) &&
                      !day.regularHours &&
                      !day.overTimeHours
                        ? clx.redNumberFormat
                        : clx.numberFormat
                    }
                    inputProps={{ 'data-testid': 'overTime-hours' }}
                    type={'number'}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

function RenderSalaryWorkedDays({
  workedDays,
  setData,
  lastDayWorked,
  employee,
}: {
  workedDays
  setData
  lastDayWorked
  employee
}) {
  const clx: any = useClasses(styles(theme))
  const [days, setDays] = useState(workedDays !== undefined ? workedDays : [])

  useEffect(() => {
    if (workedDays === undefined) {
      const fiscalWeekStartDateNumber = Number(
        Object.keys(daysInWeek).find(
          (key) => daysInWeek[key] === employee?.fiscalWeekStartDate
        )
      )
      const selectedDayNum = dayjs(lastDayWorked).day()
      let daysToDisplay

      if (selectedDayNum < fiscalWeekStartDateNumber) {
        daysToDisplay = 7 - (fiscalWeekStartDateNumber - selectedDayNum)
      } else {
        daysToDisplay = selectedDayNum - fiscalWeekStartDateNumber
      }

      const betweenDays = getDatesBetweenDates(
        dayjs(lastDayWorked).subtract(daysToDisplay, 'day').toDate(),
        lastDayWorked
      )
      const salaryWorkedDays = betweenDays.map((day) => ({
        isEnabled:
          day === dayjs(lastDayWorked).format(dateFormat) ? true : false,
        date: day,
      }))
      setDays(salaryWorkedDays)
    }
  }, [lastDayWorked, employee, workedDays])

  useEffect(() => {
    if (days !== workedDays) {
      setData(days)
    }
  }, [workedDays, days, setData])

  const enableDate =
    (rowIndex: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
      const buffer: WorkedDay[] = [...days]
      buffer[rowIndex]['isEnabled'] = e.target.checked
      setDays(buffer)
      setData(buffer)
    }

  return (
    <>
      <TableContainer className={clx.tablebody}>
        <Table stickyHeader size="small">
          <TableHead>
            <TableCell>Date</TableCell>
            <TableCell align="center">Working Day</TableCell>
          </TableHead>
          <TableBody>
            {days.map((day: WorkedDay, rowIndex: number) => (
              <TableRow key={rowIndex}>
                <TableCell align="left">
                  <Typography
                    className={
                      areSameDates(day.date, lastDayWorked)
                        ? clx.bold
                        : undefined
                    }
                  >
                    {dayjs(day.date as Date).format(DATE_FORMAT_DISPLAY)}
                    &nbsp;-&nbsp;
                    {dayjs(day.date as Date).format('dddd')}
                  </Typography>
                </TableCell>
                <TableCell scope="row" align="center">
                  <Checkbox
                    id="confirm"
                    checked={
                      areSameDates(day.date, lastDayWorked)
                        ? true
                        : day.isEnabled
                    }
                    disabled={areSameDates(day.date, lastDayWorked)}
                    onChange={enableDate(rowIndex)}
                    autoFocus
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}
