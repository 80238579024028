import Chip, { ChipProps } from '@mui/material/Chip'
import { useClasses } from 'hooks/useClasses'
import { theme } from 'theme'
import { Status } from 'types/StatusChangeResponse'

export const chipStyles = (theme) => ({
  approved1: {
    backgroundColor: '#ecf0f1',
    color: theme.palette.success.contrastText,
  },
  submitted: {
    backgroundColor: '#90CDF4',
  },
  notstarted: {
    backgroundColor: '#90CDF4',
  },
  started: {
    backgroundColor: '#90CDF4',
  },
  submittedtoadp: {
    backgroundColor: '#BEE3F8',
  },
  pendingapprover1: {
    backgroundColor: '#F6AD55',
  },
  pendingapprover2: {
    backgroundColor: '#FBD38D',
  },
  confirmedbyadp: {
    backgroundColor: '#9AE6B4',
  },
  completed: {
    backgroundColor: '#9AE6B4',
  },
  pendingcompletioninadp: {
    backgroundColor: theme.palette.colors.brewersLightBlue,
    color: '#234E52',
  },
  pendingapprovalbyhr: {
    backgroundColor: theme.palette.colors.brewersMedBlue,
    color: '#FFFFFF',
  },
  root: {
    height: '100%',
    minWidth: '150px',
    '& .MuiChip-label': {
      wordWrap: 'break-word',
      whiteSpace: 'normal',
      margin: '4px',
    },
  },
})

type RequestStatusChipProps = ChipProps & {
  label: string
  type?: Status
}

export function StatusChip(props: RequestStatusChipProps) {
  const clx: any = useClasses(chipStyles(theme))
  const classNameForType =
    clx[props.type?.toLocaleLowerCase().split(' ').join('') ?? '']

  return <Chip className={`${clx.root} ${classNameForType}`} {...props} />
}
