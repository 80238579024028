import { Alert } from '@mui/material'
import { HelpdeskContact } from 'components/FallbackNotes'
import React from 'react'

export const LocalStorageErrorMessage = () => (
  <Alert severity="error">
    Please enable cookies and local storage in your browser to use this site. If
    this error persists after enabling cookies and local storage, please contact
    the WKS Help Desk at <HelpdeskContact />.
  </Alert>
)

export function useLocalStorageAvailable() {
  const isAvailable = React.useMemo(() => {
    try {
      const test = '__storage_test__'
      window.localStorage.setItem(test, test)
      window.localStorage.removeItem(test)
      return true
    } catch (e) {
      return false
    }
  }, [])

  return isAvailable
}
