import { Box } from '@mui/material'
import { ReactNode } from 'react'

export function Main({ children }: { children: ReactNode }) {
  return (
    <Box
      component="main"
      display="flex"
      flex="1" // Make the Box fill the available vertical space
      flexGrow="1"
      alignItems="center"
      textAlign="center"
      justifyContent="center"
    >
      {children}
    </Box>
  )
}
