import { GridLineItem } from 'components/LineItem'
import { ArrowDownward } from '@mui/icons-material'
import { Box, Grid, Typography } from '@mui/material'
import {
  StatusChangeResponseBase,
  StatusChangeDetailsBorrow,
} from 'types/StatusChangeResponse'
import dayjs from 'dayjs'
import { center } from 'types/PropProperty'
import RemoveIcon from '@mui/icons-material/Remove'

export function BorrowInfo({ locationTo, locationFrom, startDate, endDate }) {
  return (
    <Box>
      <Grid
        container
        direction="row"
        spacing={2}
        justifyContent={center}
        alignItems="flex-end"
        mb={3}
      >
        <GridLineItem
          label={'Start Date'}
          dataTestId="start-date"
          value={dayjs(startDate).format('MM/DD/YYYY')}
          textAlign="center"
        />
        <Grid item>
          <Typography align="center" alignItems="flex-end">
            <RemoveIcon />
          </Typography>
        </Grid>
        <GridLineItem
          label={'End Date'}
          dataTestId="end-date"
          value={dayjs(endDate).format('MM/DD/YYYY')}
          textAlign="center"
        />
      </Grid>
      <GridLineItem
        label="Current Location"
        value={locationFrom}
        dataTestId="location-current"
        textAlign="center"
      />
      <ArrowDownward />
      <GridLineItem
        label="Location to be Borrowed To"
        value={locationTo}
        dataTestId="location-proposed"
        textAlign="center"
      />
    </Box>
  )
}

export function BorrowDetail({
  statusChangeResponse: response,
}: {
  statusChangeResponse: StatusChangeResponseBase
}) {
  const details = response.details as StatusChangeDetailsBorrow
  return (
    <BorrowInfo
      locationFrom={response.location.desc}
      locationTo={details.borrowingLocation?.desc}
      startDate={response.effectiveDate}
      endDate={details.endDate}
    />
  )
}
