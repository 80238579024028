import { Box, Typography } from '@mui/material'
import { FullScreenBox } from 'components/FullScreenBox'

export function FallbackComponent(notes: React.ReactNode) {
  function FallbackWithErrorStack({
    error,
    componentStack,
  }: {
    error?: Error
    componentStack?: string
  }) {
    const isLocal = process.env.REACT_APP_ENV_TARGET === 'local'

    return (
      <FullScreenBox>
        <Box mx={18}>
          <Typography variant="h5">{notes}</Typography>
          {isLocal && (
            <Typography>
              {error?.stack}
              {componentStack}
            </Typography>
          )}
        </Box>
      </FullScreenBox>
    )
  }

  return FallbackWithErrorStack
}
