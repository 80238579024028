import { Box, Button, Grid } from '@mui/material'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import { postRequestComplete, postRequestSaveToADP } from 'api'
import { useUser } from 'context/Authenticate'
import { useSnackbar } from 'notistack'
import { goBackToRequestListPage } from 'pages/StatusChangeDetail'
import { useState } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { useLocation, useNavigate } from 'react-router-dom'
import { CompleteState } from 'types/Complete'
import { QueryNames } from 'types/Queries'
import { StatusChangeResponseBase } from 'types/StatusChangeResponse'

export function CompleteSection({
  details,
  disabled,
}: {
  details: StatusChangeResponseBase
  disabled: boolean
}) {
  return (
    <>
      {details.canSaveToADP && (
        <Grid item>
          <SaveToADP
            id={details.id}
            pendingRequestsInAdpOnComplete={
              details.pendingRequestsInAdpOnComplete
            }
            saveToADPMessage={details.saveToADPMessage}
          />
        </Grid>
      )}
      <Grid item>
        <Complete
          id={details.id}
          pendingRequestsInAdpOnComplete={
            details.pendingRequestsInAdpOnComplete
          }
          disabled={disabled}
        />
      </Grid>
    </>
  )
}

/**
 * Logic and requests for completed button (shown only to users with correct role)
 */
function Complete({
  id,
  pendingRequestsInAdpOnComplete,
  disabled,
}: {
  id: StatusChangeResponseBase['id']
  pendingRequestsInAdpOnComplete: StatusChangeResponseBase['pendingRequestsInAdpOnComplete']
  disabled: boolean
}) {
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const location = useLocation()
  const user = useUser()
  const { enqueueSnackbar } = useSnackbar()

  const { mutateAsync: complete, isLoading } = useMutation(
    postRequestComplete,
    {
      onSuccess: () => {
        enqueueSnackbar(`Request (id: ${id}) has been completed`, {
          variant: 'success',
        })
        queryClient.removeQueries(QueryNames.Requests)
        queryClient.removeQueries(QueryNames.RequestDetail)

        goBackToRequestListPage(navigate, location, user)
      },
    }
  )
  return (
    <Button
      variant="contained"
      color="primary"
      fullWidth
      disabled={isLoading || disabled}
      onClick={() =>
        complete({
          id,
          pendingRequestsInAdpOnComplete,
        } as Required<CompleteState>)
      }
    >
      I updated ADP manually
    </Button>
  )
}

/**
 * Save changes to ADP (shown only to users with correct role)
 */

interface SaveToADPProps {
  id: StatusChangeResponseBase['id']
  pendingRequestsInAdpOnComplete: StatusChangeResponseBase['pendingRequestsInAdpOnComplete']
  saveToADPMessage: string | undefined
}

function SaveToADP({
  id,
  pendingRequestsInAdpOnComplete,
  saveToADPMessage,
}: SaveToADPProps) {
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const location = useLocation()
  const user = useUser()
  const { enqueueSnackbar } = useSnackbar()

  const { mutateAsync, isLoading } = useMutation(postRequestSaveToADP, {
    onSuccess: () => {
      enqueueSnackbar(`Request (id: ${id}) has been saved to ADP`, {
        variant: 'success',
      })
      queryClient.removeQueries(QueryNames.Requests)
      queryClient.removeQueries(QueryNames.RequestDetail)
      goBackToRequestListPage(navigate, location, user)
    },
    onError: () => {
      queryClient.removeQueries(QueryNames.RequestDetail)
    },
  })

  const [open, setOpen] = useState(false)
  const handleClose = () => {
    setOpen(false)
  }

  const handleSaveADP = () => {
    if (saveToADPMessage) {
      setOpen(true)
    } else {
      mutateAsync({
        id,
        pendingRequestsInAdpOnComplete,
      } as Required<CompleteState>)
    }
  }
  const handleSave = () => {
    mutateAsync({
      id,
      pendingRequestsInAdpOnComplete,
    } as Required<CompleteState>)
    setOpen(false)
  }

  return (
    <>
      <Button
        variant="contained"
        color="secondary"
        fullWidth
        disabled={isLoading}
        onClick={handleSaveADP}
      >
        {isLoading ? 'Processing...' : 'Save changes via ADP API'}
      </Button>
      {open && (
        <Dialog
          onClose={handleClose}
          open={open}
          aria-labelledby="save-dialog-title"
          data-testid="save-dialog"
        >
          <DialogTitle
            id="reject-dialog-title"
            data-testid="reject-dialog-title"
          >
            Are you sure you want to Save Changes Via ADP API?
          </DialogTitle>
          <Box p={2}>{saveToADPMessage}</Box>
          <Box p={2} pt={0} display="flex" flexDirection="column">
            <Box pt={2} display="flex" justifyContent="center">
              <Button onClick={handleClose}>Cancel</Button>
              <Box pl={2} />
              <Button
                variant="contained"
                disableElevation
                onClick={handleSave}
                data-testid="dialog-save"
              >
                Save
              </Button>
            </Box>
          </Box>
        </Dialog>
      )}
    </>
  )
}
