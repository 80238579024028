import FileUploadIcon from '@mui/icons-material/CloudUploadOutlined'
import { Box, Button, Card, Container, Grid, Typography } from '@mui/material'
import LinearProgress from '@mui/material/LinearProgress'
import { postTalentReefFileUpload } from 'api'
import { UploadDropzone } from 'components/UploadDropzone'
import { useUser } from 'context/Authenticate'
import { useTitle } from 'hooks/useTitle'
import { HOME_PATH } from 'link-paths'
import NoRoleAccess from 'pages/NoRoleAccess'
import { useEffect, useState } from 'react'
import { useMutation } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { downloadFileContentOnBrowser } from 'utils'

const buttonStyle = {
  bgcolor: 'primary.main',
  color: 'white',
  borderRadius: 3,
  coloredShadow: 'primary.main',
  boxShadow: 8,
  textShadow: '2px 2px 4px #000000',
  '&:hover': {
    bgcolor: 'secondary.light',
    color: 'primary.dark',
    textShadow: 'none',
  },
  '&:disabled': {
    bgcolor: 'colors.gray100',
    color: 'primary.dark',
    textShadow: 'none',
  },
}

// used in lazy import
// eslint-disable-next-line import/no-default-export
export default function UploadTalentReefCsv() {
  const navigate = useNavigate()
  useTitle('Upload Talent Reef Roster CSV')
  const [files, setFiles] = useState<File[]>([])
  const [progress, setProgress] = useState(0)
  const [userMessages, setUserMessages] = useState<string[]>([])
  const {
    mutateAsync: uploadRosterFile,
    isLoading,
    isSuccess,
    isError,
  } = useMutation(postTalentReefFileUpload, {
    onSuccess: (data) => {
      if (
        data?.data.data.warnings !== undefined &&
        data?.data.data.warnings.length > 0
      ) {
        setUserMessages([...(data?.data.data.warnings ?? [])])
      } else {
        setUserMessages(['Successfully uploaded contents'])
      }
      for (const item of data.data.data.files) {
        downloadFileContentOnBrowser(item)
      }
    },
    onError: (data: any) => {
      setUserMessages([('Fatal Error: ' + data?.message) as string])
    },
  })

  useEffect(() => {
    if (isLoading) {
      const timer = setInterval(() => {
        setProgress((oldProgress) => {
          if (oldProgress === 100) {
            return 0
          }
          const diff = Math.random() * 10
          return Math.min(oldProgress + diff, 100)
        })
      }, 750)

      return () => {
        clearInterval(timer)
      }
    }
  }, [isLoading])

  const processFile = async (files: File[]) => {
    if (files?.length > 0) {
      setUserMessages([])
      setProgress(0)
      await uploadRosterFile({
        files,
      })
    }
  }

  const { roles } = useUser()
  if (!roles.canExportRosterCorrectionFile) {
    return (
      <NoRoleAccess
        roleName={'Menu - Payroll - ADP and TalentReef Roster Correction'}
      />
    )
  }

  return (
    <Container component="section" maxWidth="md" sx={{ my: 5 }}>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <Card sx={{ minWidth: 845, maxWidth: 845 }}>
          <Box
            mx={2}
            my={2}
            py={3}
            px={2}
            sx={(theme) => ({
              bgcolor: 'primary.light',
              borderRadius: 3,
              coloredShadow: 'primary.light',
              boxShadow: 8,
              textShadow: '2px 2px 4px #000000',
            })}
          >
            <Typography variant="h6" color="white">
              Upload Talent Reef CSV
            </Typography>
          </Box>
          <Grid item justifyContent="center" alignItems="center" mx={8}>
            <Grid container direction="column" spacing={3}>
              <Grid item>
                <UploadDropzone
                  onChange={setFiles}
                  initialFiles={files}
                  filesLimit={200}
                  acceptedFileExtensions={[`.csv`]}
                />
              </Grid>
              <Grid item>
                <Button
                  sx={buttonStyle}
                  onClick={() => processFile(files)}
                  disabled={files?.length === 0 || isLoading}
                >
                  <FileUploadIcon />
                  &nbsp;
                  {!isLoading ? 'Upload' : 'processing...'}
                </Button>
              </Grid>
              {isLoading && (
                <Grid item>
                  <LinearProgress variant="determinate" value={progress} />
                </Grid>
              )}
              <Grid item>
                {userMessages.map((msg, idx) => {
                  return (
                    <Typography key={idx} variant="body2" color="textSecondary">
                      {msg}
                    </Typography>
                  )
                })}
              </Grid>
              <Grid item>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  my={2}
                  py={2}
                >
                  <Button sx={buttonStyle} onClick={() => navigate(HOME_PATH)}>
                    Back
                  </Button>
                  <Button
                    sx={buttonStyle}
                    onClick={() => navigate(HOME_PATH)}
                    disabled={isLoading || !isSuccess || isError}
                  >
                    Finished
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </Container>
  )
}
