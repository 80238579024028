import { Button, CircularProgress } from '@mui/material'

export function SubmitButton({ ready, isLoading }) {
  return (
    <Button
      variant="contained"
      color="secondary"
      type="submit"
      fullWidth
      disabled={!ready || isLoading}
    >
      {isLoading ? <CircularProgress size="1rem" /> : 'Submit Request'}
    </Button>
  )
}
