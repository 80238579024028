import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt'
import { Typography } from '@mui/material'
import { LineItem } from 'components/LineItem'
import { useClasses } from 'hooks/useClasses'
import styled from 'styled-components'
import { RateUnit } from 'types/RateUnit'
import {
  StatusChangeDetails,
  StatusChangeResponseBase,
} from 'types/StatusChangeResponse'
import { formatCurrency } from 'utils'

const formatterNoCents = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
})

const DetailRow = styled.div`
  display: flex;
  width: 100%;
`
const Wrapper = styled.div`
  width: 40%;
`
const ArrowWrapper = styled.div`
  width: 20%;
`
const styles = {
  arrowRight: {
    fontSize: 80,
  },
  proposedWageText: {
    fontSize: 22,
  },
}
interface JobInfo {
  description: string
  rate: number
  rateUnit: RateUnit.HOURLY | RateUnit.SALARY
}
interface HourlySalaryDetails {
  oldData: JobInfo
  newData: JobInfo
}

export function HourlySalaryInfo({ oldData, newData }: HourlySalaryDetails) {
  const classes: any = useClasses(styles)
  const wageType = (data: JobInfo) => {
    return data.rateUnit === RateUnit.HOURLY ? 'hourly' : 'biweekly'
  }
  const payType = (data: JobInfo) => {
    return data.rateUnit === RateUnit.HOURLY ? 'Hourly' : 'Salary'
  }
  const formattedWage = (data: JobInfo) => {
    if (data.rateUnit === RateUnit.HOURLY) return formatCurrency(data.rate)
    else {
      const hasCents = data.rate % 1 !== 0
      return hasCents
        ? formatCurrency(data.rate)
        : formatterNoCents.format(data.rate)
    }
  }
  const proposedWageText = () => {
    return (
      <Typography className={classes.proposedWageText}>
        {formattedWage(newData)}
        &nbsp;
        {wageType(newData)}
      </Typography>
    )
  }
  return (
    <>
      <DetailRow>
        <Wrapper>
          <LineItem
            textAlign="right"
            label={`${payType(oldData)}`}
            value={oldData.description}
            dataTestId="job-desc-current"
          />
        </Wrapper>
        <ArrowWrapper>
          <ArrowRightAltIcon className={classes.arrowRight} />
        </ArrowWrapper>
        <Wrapper>
          <LineItem
            textAlign="left"
            label={`${payType(newData)}`}
            value={newData.description}
            dataTestId="job-desc-proposed"
          />
        </Wrapper>
      </DetailRow>

      <LineItem
        textAlign="center"
        label="Current wage"
        value={`${formattedWage(oldData)} ${wageType(oldData)}`}
        dataTestId="wage-current"
      />

      <LineItem
        textAlign="center"
        label="Proposed wage"
        value={proposedWageText()}
        dataTestId="wage-proposed"
      />
      {newData.rateUnit === RateUnit.SALARY && (
        <LineItem
          textAlign="center"
          value={`${formatterNoCents.format(
            newData.rate * 26
          )} est. annual salary`}
          dataTestId="est-annual-salary"
        />
      )}
    </>
  )
}

export function HourlySalaryDetail({
  statusChangeResponse: response,
}: {
  statusChangeResponse: StatusChangeResponseBase
}) {
  const details = response.details as StatusChangeDetails

  return (
    <HourlySalaryInfo
      oldData={{
        description: details.existingJobTitleDescription,
        rate: details.existingRate,
        rateUnit: details.existingRateUnit,
      }}
      newData={{
        description: details.newJobTitleDescription,
        rate: details.newRate,
        rateUnit: details.newRateUnit,
      }}
    />
  )
}
