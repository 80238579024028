import { Button } from '@mui/material'
import { getAdpLoginUrl } from 'api'
import { theme } from 'theme'
import ADP from '../../assets/adp_logo.svg'
import { XsrfTokenInput } from 'components/xsrf/XsrfTokenInput'

const buttonStyle = {
  backgroundColor: theme.palette.colors.gray100,
  border: `1.5px solid ${theme.palette.colors.gray200}`,
  margin: '20px',
}
interface Props {
  returnUrl?: string
}
export function AdpLoginButton({ returnUrl }: Props) {
  return (
    <form action={getAdpLoginUrl(returnUrl || '')} method="post">
      <XsrfTokenInput />
      <Button
        variant="outlined"
        style={buttonStyle}
        startIcon={<img src={ADP} height="16px" alt="ADP Logo" />}
        type="submit"
      >
        Sign-in with ADP
      </Button>
    </form>
  )
}
