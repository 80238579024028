import { discountTheme } from '../DiscountTheme'

// interface Props {
//   style?: CSSProperties
// }
// eslint-disable-next-line import/no-default-export
export default function ColoredLine({ style = {} }) {
  const lineStyle = {
    border: 'none',
    background: discountTheme.palette.secondary.main,
    height: 2,
    width: '200px',
    margin: '20px',
    accentColor: 'none',
  }
  return <hr style={{ ...lineStyle, ...style }} />
}
