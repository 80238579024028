import { CssBaseline } from '@mui/material'
import * as Sentry from '@sentry/react'
import { useSessionTimeout } from 'hooks/useSessionTimeout'
import React from 'react'
import NoAccess from './components/NoAccess'
import Success from './components/Success'
import './DiscountApp.css'
import { useUser } from './DiscountAuthenticate'
import UseEmployeeTracking from './UseEmployeeTracking'

function LayoutInternal() {
  const user = useUser()
  UseEmployeeTracking(user)
  useSessionTimeout(user)

  return (
    <React.Suspense fallback={<div></div>}>
      <CssBaseline />
      {user.discountCode ? (
        <Success
          employeeName={user.name}
          locationName={user.locationName}
          discountCode={user.discountCode}
        />
      ) : (
        <NoAccess
          employeeName={user.name}
          locationName={user.locationName}
          associateId={user.associateId}
        />
      )}
    </React.Suspense>
  )
}

export const DiscountLayout = Sentry.withProfiler(LayoutInternal)
