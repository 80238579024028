import { ArrowDownward } from '@mui/icons-material'
import { LineItem } from 'components/LineItem'
import {
  StatusChangeResponseBase,
  StatusChangeDetailsTransfer,
} from 'types/StatusChangeResponse'

export function TransferInfo({
  locationTo,
  locationFrom,
  replacementEmployeeName,
}) {
  return (
    <>
      <LineItem
        label="Current Location"
        value={locationFrom}
        dataTestId="location-current"
        textAlign="center"
      />
      <ArrowDownward />
      <LineItem
        label="Proposed Location"
        value={locationTo}
        dataTestId="location-proposed"
        textAlign="center"
      />
      &nbsp;
      {replacementEmployeeName && (
        <LineItem
          label="New manager of direct reports"
          value={replacementEmployeeName}
          dataTestId="replacement-employee-name"
          textAlign="center"
        />
      )}
    </>
  )
}

export function TransferDetail({
  statusChangeResponse: response,
}: {
  statusChangeResponse: StatusChangeResponseBase
}) {
  const details = response.details as StatusChangeDetailsTransfer

  return (
    <TransferInfo
      locationTo={details.transferToLocation?.desc}
      locationFrom={response.location.desc}
      replacementEmployeeName={details.replacementEmployeeName}
    />
  )
}
