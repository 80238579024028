import {
  Button,
  CssBaseline,
  Link,
  ThemeProvider,
  Typography,
} from '@mui/material'
import Box from '@mui/material/Box'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import { ReturnUrl } from 'discount/DiscountHelper'
import { AdpLoginButton } from '../components/AdpLoginButton'
import '../DiscountApp.css'
import {
  discountButtonStyle,
  discountTheme,
  footerStyle,
} from '../DiscountTheme'
import UseEmployeeTracking from '../UseEmployeeTracking'
import CollapsibleTerms from './CollapsibleTerms'
import ColoredLine from './ColoredLine'
import Header from './Header'

// eslint-disable-next-line import/no-default-export
export default function DiscountLandingPage() {
  const hrEmail = 'HR@wksusa.com'
  const hrEmailSubject = 'WKS Discount - Login'

  UseEmployeeTracking(null)

  return (
    <ThemeProvider theme={discountTheme}>
      <CssBaseline />
      <Header displaySignOutButton={false} />
      <div className="content">
        <Box display="flex" flexDirection="column" alignItems="center">
          <ColoredLine style={{ margin: '42px 0 20px 0' }} />
          <Typography style={{ color: discountTheme.palette.primary.light }}>
            Ready to eat?
          </Typography>
          <ColoredLine style={{ margin: '20px 0 42px 0' }} />

          <AdpLoginButton returnUrl={ReturnUrl()} />
          <Box paddingTop="25px" paddingBottom="10px">
            <Typography variant="body2">
              Need help? &nbsp;
              <Link href={`mailto:${hrEmail}?subject=${hrEmailSubject}`}>
                Email Human Resources
              </Link>
            </Typography>
          </Box>
        </Box>
      </div>
      <div style={{ ...footerStyle, minHeight: '200px' }}>
        <Box paddingBottom="16px">
          <Typography variant="body2">
            The WKS Family Discount is for current team members and their
            immediate family members. Team members must login to redeem.
            Cashiers must record the five-digit code on the receipt at time of
            redemption.
          </Typography>
        </Box>
        <Button
          variant="outlined"
          style={discountButtonStyle}
          type="submit"
          href="http://www.wksusa.com/locations"
        >
          <LocationOnIcon />
          &nbsp;WKS Locations
        </Button>
        <br />
        <CollapsibleTerms />
      </div>
    </ThemeProvider>
  )
}
