import { useMemo } from 'react'
import { css } from '@emotion/css'
import { useTheme } from '@emotion/react'

// Custom hook to help migrate from MUI V4 to MUI V5.  Do not use with new components.
export const useClasses = (stylesElement) => {
  const theme = useTheme()
  return useMemo(() => {
    const rawClasses =
      typeof stylesElement === 'function' ? stylesElement(theme) : stylesElement
    const prepared = {}

    Object.entries(rawClasses).forEach(([key, value]) => {
      prepared[key] = css([value as []])
    })

    return prepared
  }, [stylesElement, theme])
}
