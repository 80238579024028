import { CircularProgress, useMediaQuery, useTheme } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { searchReplacementEmployees } from 'api'
import { createEmployeeSearchFilterOptions } from 'components/steps/AutoCompleteComponent'
import React, { useState } from 'react'
import { useQuery } from 'react-query'
import { Employee, EmployeeSearch } from 'types/Employee'
import { StepInfo, StepProps } from 'types/StepProps'
import { useDebounce } from 'use-debounce'

export const selectReplacementStep = (name?: string): StepInfo => {
  return {
    cardLabel: `Who should become the new manager of ${name}'s direct reports?`,
    stepLabel: 'Select Replacement',
    url: 'select-replacement',
  }
}

type SelectReplacementProps = StepProps<
  { employee?: Employee; replacement?: EmployeeSearch },
  EmployeeSearch
>

function EmployeeReplaceDetails({ employee }: { employee: EmployeeSearch }) {
  const { employeeName, homeLocationDesc } = employee
  return (
    <Box p={2} textAlign="left">
      <Typography variant="h5" component="p">
        {employeeName}
      </Typography>
      <Typography variant="subtitle2" color="textSecondary">
        Location{' '}
      </Typography>
      <Typography gutterBottom>{homeLocationDesc}</Typography>
    </Box>
  )
}

// eslint-disable-next-line import/no-default-export
export default function SelectReplacement({
  data: { employee, replacement },
  setData,
}: SelectReplacementProps) {
  const [search, setSearch] = useState('')
  const [debouncedSearch] = useDebounce(search, 100)
  const { isFetching, data } = useQuery(
    ['replacement-employees', debouncedSearch, employee?.id],
    () => searchReplacementEmployees(debouncedSearch, employee?.id)
  )
  const onHandleChange = (_, value, reason) => {
    if (reason === 'clear') {
      setData(undefined)
    } else {
      setData(value)
    }
  }
  const { breakpoints } = useTheme()
  const matches = useMediaQuery(breakpoints.up('sm'))

  return (
    <>
      <Box
        display="flex"
        flexDirection={matches ? 'row' : 'column'}
        justifyContent="space-between"
        alignContent="center"
        alignItems="center"
        minWidth={800}
      >
        <Box mr={2} width="40%">
          <Autocomplete<EmployeeSearch>
            id="select-termination-reason"
            options={data?.data ?? []}
            value={replacement ?? null}
            fullWidth
            getOptionLabel={(opt: EmployeeSearch) =>
              `${opt.employeeName} - ${opt.adpPositionId}`
            }
            isOptionEqualToValue={(option, value) => option?.id === value?.id}
            onInputChange={(_, value) => setSearch(value)}
            onChange={onHandleChange}
            noOptionsText="Start typing to search..."
            autoHighlight
            filterOptions={createEmployeeSearchFilterOptions}
            loading={isFetching}
            renderInput={(params) => (
              <TextField
                autoFocus
                variant="outlined"
                label="Replacement"
                data-testid="replacement-select"
                {...params}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {isFetching ? <CircularProgress size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
              />
            )}
          />
        </Box>
        <Box flexGrow={1}>
          {!replacement && (
            <Typography color="textSecondary">No employee selected</Typography>
          )}
          {replacement && <EmployeeReplaceDetails employee={replacement} />}
        </Box>
      </Box>
    </>
  )
}
