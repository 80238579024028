import CurrencyTextField from '@lupus-ai/mui-currency-textfield'

interface SpecifyPercentageProps {
  onPercentageChange: (percentage: string) => void
  percent?: string
}

// eslint-disable-next-line import/no-default-export
export default function SpecifyPercentage({
  onPercentageChange,
  percent,
}: SpecifyPercentageProps) {
  const handleChange = (_: any, value: string) => {
    // Call the parent's callback with the new percentage
    onPercentageChange(value)
  }
  const validPercent =
    percent !== undefined && !isNaN(Number(percent)) ? percent : '0.00'

  return (
    <>
      <CurrencyTextField
        label="Change Percentage"
        variant="outlined"
        inputProps={{
          inputMode: 'numeric',
          'data-testid': 'change-percentage',
        }}
        currencySymbol="%"
        minValue={-100} // Allow negative percentages
        maxValue={100} // Limit to 100%
        outputFormat="string"
        decimalCharacter="."
        digitGroupSeparator=","
        onChange={handleChange}
        color="inherit"
        value={validPercent}
        modifyValueOnWheel={false}
      />
    </>
  )
}
