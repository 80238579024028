import AccessibilityIcon from '@mui/icons-material/Accessibility'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import AssignmentReturnIcon from '@mui/icons-material/AssignmentReturn'
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle'
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox'
import LocalAtmIcon from '@mui/icons-material/LocalAtm'
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn'
import MonitorIcon from '@mui/icons-material/Monitor'
import PersonAdd from '@mui/icons-material/PersonAdd'
import TransferWithinAStationIcon from '@mui/icons-material/TransferWithinAStation'
import { Container, Grid, Typography } from '@mui/material'
import { useUser } from 'context/Authenticate'
import { useTitle } from 'hooks/useTitle'
import NoRoleAccess from 'pages/NoRoleAccess'
import { HomePageCard } from '../components/HomePageCard'
import {
  BORROW_REQUEST_PATH,
  HOURLY_SALARY,
  LEAVE_OF_ABSENCE_PATH,
  POS_JOB_CODE_REQUEST_PATH,
  REHIRE_PATH,
  RETURN_FROM_LEAVE_PATH,
  TERMINATION_PATH,
  TITLE_JOB_CODE_CHANGE_PATH,
  TRANSFER_PATH,
  WAGE_CHANGE_PATH,
} from '../link-paths'
export interface HomeCard {
  name: string
  icon: any
  path: string
  role?: string
  isNewFeature?: boolean
}
const cardValues: HomeCard[] = [
  {
    path: WAGE_CHANGE_PATH,
    name: 'Wage Rate Change',
    icon: MonetizationOnIcon,
    role: 'canCreateEditAndBasic',
  },
  {
    path: TITLE_JOB_CODE_CHANGE_PATH,
    name: 'Title Change',
    icon: AccessibilityIcon,
    role: 'canCreateEditAndBasic',
  },
  {
    path: TRANSFER_PATH,
    name: 'Transfer',
    icon: TransferWithinAStationIcon,
    role: 'canCreateTransfer',
  },
  {
    path: TERMINATION_PATH,
    name: 'Termination',
    icon: IndeterminateCheckBoxIcon,
    role: 'canCreateEditAndBasic',
  },
  {
    path: LEAVE_OF_ABSENCE_PATH,
    name: 'Leave of Absence',
    icon: AccessTimeIcon,
    role: 'canCreateEditAndBasic',
  },
  {
    path: REHIRE_PATH,
    name: 'Rehire/Reinstatement',
    icon: PersonAdd,
    role: 'canViewCreateRehire',
    isNewFeature: true,
  },
  {
    path: RETURN_FROM_LEAVE_PATH,
    name: 'Return from Leave',
    icon: AssignmentReturnIcon,
    role: 'canCreateEditAndBasic',
  },
  {
    path: HOURLY_SALARY,
    name: 'Salary to Hourly \n Hourly to Salary',
    icon: LocalAtmIcon,
    role: 'canCreateEditAndBasic',
  },
  {
    path: BORROW_REQUEST_PATH,
    name: 'Borrow',
    icon: ChangeCircleIcon,
    role: 'canViewCreateBorrow',
    isNewFeature: true,
  },
  {
    path: POS_JOB_CODE_REQUEST_PATH,
    name: 'POS Job Code Change',
    icon: MonitorIcon,
    role: 'canViewCreatePOSJobCode',
    isNewFeature: true,
  },
]

export function EmploymentChangeHome() {
  const { roles } = useUser()
  useTitle('Employment Change')

  if (!roles.canViewEmploymentChange) {
    return <NoRoleAccess roleName={'Menu - Employment Change'} />
  }
  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Employment Change
      </Typography>
      <Grid container direction="row">
        {cardValues.map((values) => {
          return !values.role || roles[values.role] ? (
            <Grid item md={4} xs={6} key={values.name}>
              <HomePageCard
                icon={<values.icon />}
                buttonText={values.name}
                to={values.path}
                isNewFeature={values.isNewFeature}
              />
            </Grid>
          ) : null
        })}
      </Grid>
    </Container>
  )
}
