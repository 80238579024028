/* eslint-disable @typescript-eslint/no-unused-vars */
import CurrencyTextField from '@lupus-ai/mui-currency-textfield'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid'
import { styled } from '@mui/material/styles'
import Switch, { SwitchProps } from '@mui/material/Switch'
import Typography from '@mui/material/Typography'
import { useClasses } from 'hooks/useClasses'
import React, { useCallback, useState } from 'react'
import { theme } from 'theme'
import { RateUnit } from 'types/RateUnit'
import { StepInfo, StepProps } from 'types/StepProps'
export const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
})

const styles = (theme) => ({
  root: {
    '& .MuiInputBase-root': {
      color: '#2980b9',
    },
    '& .Mui-disabled': {
      color: theme.palette.secondary.dark,
    },
  },
})

export const specifyRateStep: StepInfo = {
  stepLabel: 'New Rate/Wage',
  cardLabel: 'Specify new',
  url: 'specify-wage',
}

const MAXIMUM_SALARY = 38000
const MAXIMUM_HOURLY = 100
const MINIMUM_SALARY = 0
const MINIMUM_HOURLY = 0

export interface RateObject {
  type: RateUnit
  rate?: number
  currentRate: number
  primaryRate?: number
}
export type SpecifyRateProps = StepProps<RateObject, { rate?: number }> & {
  firstInRate?: boolean
  handleFirstInRate?: (boolean) => void
}
// All steps in the Rate Change flow are lazily imported (requires default)
// eslint-disable-next-line import/no-default-export
export default function SpecifyRate({
  data,
  setData,
  firstInRate,
  handleFirstInRate,
}: SpecifyRateProps) {
  const classes: any = useClasses(styles(theme))

  const { currentRate, rate, type, primaryRate } = data
  const maxValue = type === RateUnit.HOURLY ? MAXIMUM_HOURLY : MAXIMUM_SALARY
  const minValue = type === RateUnit.HOURLY ? MINIMUM_HOURLY : MINIMUM_SALARY
  const oldRate = React.useRef(currentRate)
  const getValidRate = useCallback(
    (unvalidatedRate: number | undefined) => {
      let rate = unvalidatedRate
      if (rate === undefined || rate === null || isNaN(rate)) {
        rate = oldRate.current
      }
      if (rate > maxValue) {
        rate = maxValue
      } else if (rate < minValue) {
        rate = minValue
      }
      return rate
    },
    [oldRate, minValue, maxValue]
  )

  const [isFirst, setIsFirst] = useState(true)

  React.useEffect(() => {
    let newRate = rate
    if (!newRate && newRate !== 0) {
      newRate = currentRate
    }
    if (newRate !== rate) {
      setData({ rate: getValidRate(newRate) })
    }
  }, [currentRate, rate, setData, getValidRate, data.type])

  return (
    <Grid
      container
      direction="column"
      justifyContent="space-between"
      alignContent="center"
      alignItems="center"
      spacing={4}
    >
      <Grid item xs={2}>
        <CurrencyTextField
          label={`New Rate - ${
            type === RateUnit.SALARY ? 'Biweekly' : 'Hourly'
          }`}
          variant="outlined"
          maximumValue={`${maxValue}`}
          inputProps={{
            inputMode: 'numeric',
            'data-testid': 'new-rate',
          }}
          value={getValidRate(rate ?? currentRate)}
          currencySymbol="$"
          minimumValue={`${minValue}`}
          outputFormat="number"
          decimalCharacter="."
          digitGroupSeparator=","
          onChange={(_: unknown, value: number) => {
            setIsFirst(false)
            setData({ rate: value })
            if (handleFirstInRate) handleFirstInRate(isFirst)
          }}
          error={
            !firstInRate &&
            (!rate
              ? primaryRate === undefined || primaryRate === rate
              : rate === currentRate)
          }
          helperText={
            !firstInRate &&
            ((!rate && primaryRate !== undefined && primaryRate === rate) ||
            (rate && rate === currentRate)
              ? 'New rate must be different from original rate.'
              : '')
          }
          color="inherit"
          autoFocus
          id="red"
          className={classes.root}
          modifyValueOnWheel={false}
        />
        <br />
        {type === RateUnit.SALARY && oldRate.current !== 0 && primaryRate ? (
          <Typography
            component={Box}
            variant="body2"
            style={{
              minHeight: '30px',
              display: 'inline-block',
              marginLeft: '40px',
            }}
          >
            {`Est Annual Salary ${formatter.format(primaryRate * 26)}`}
          </Typography>
        ) : null}
      </Grid>
    </Grid>
  )
}
