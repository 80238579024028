import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Grid,
  TextField,
  Typography,
  useTheme,
} from '@mui/material'
import { useEffect, useState } from 'react'
import { useMutation } from 'react-query'

import { syncEmployeeFromADP } from 'api'
import { useUser } from 'context/Authenticate'
import { useTitle } from 'hooks/useTitle'
import NoRoleAccess from 'pages/NoRoleAccess'
import { SyncEmployeeInfo } from 'types/Employee'

const employeeInfoFields = [
  {
    key: 'nameAndPositionId',
    title: 'Name and Position ID',
  },
  { key: 'jobTitleDisplayName', title: 'Job Title' },
  {
    key: 'positionStatus',
    title: 'Position Status',
    format: (info: SyncEmployeeInfo) => {
      return (
        <span
          style={{
            color: info.positionStatus !== 'Active' ? 'red' : 'inherit',
          }}
        >
          {info.positionStatus}
        </span>
      )
    },
  },
  {
    key: 'homeLocationDisplayName',
    title: 'Home Location',
  },
  {
    key: 'reportsToNameAndPositionId',
    title: 'Reports To',
    format: (info: SyncEmployeeInfo) => {
      return (
        <>
          {info.reportsToNameAndPositionId}
          {info.sentReportToCorrectionQueueMessage && (
            <span style={{ fontStyle: 'italic' }}> (pending)</span>
          )}
        </>
      )
    },
  },
  { key: 'personalEmail', title: 'Personal Email' },
  { key: 'personalMobile', title: 'Personal Mobile' },
  { key: 'ssnLastFour', title: 'SSN Last 4' },
  { key: 'primaryAddressFormatted', title: 'Primary Address' },
  { key: 'paySelection', title: 'Pay Selection' },
]

// eslint-disable-next-line import/no-default-export
export default function SyncEmployeeFromADP() {
  const theme = useTheme()
  useTitle('Employee')
  const { roles } = useUser()
  const [searchTerm, setSearchTerm] = useState('')
  const [employeeInfo, setEmployeeInfo] = useState<SyncEmployeeInfo | null>(
    null
  )
  const [errors, setErrors] = useState<string[]>([])

  useEffect(() => {
    setEmployeeInfo(null)
    setErrors([])
  }, [searchTerm])

  const { mutate: syncEmployee, isLoading } = useMutation<
    SyncEmployeeInfo,
    { response: { data: { errors: [{ detail: string }] } } },
    { searchTerm: string }
  >(({ searchTerm }) => syncEmployeeFromADP({ searchTerm }), {
    onSuccess: (data) => {
      setEmployeeInfo(data)
      setErrors(data.eSignRequiredFieldsErrorMessage)
    },
    onError: (errorData) => {
      const errorDetails = errorData.response.data.errors.map(
        (err) => err.detail
      )
      setErrors(errorDetails)
      setEmployeeInfo(null)
    },
  })

  const handleSyncButtonClick = () => {
    if (searchTerm.length >= 9) {
      syncEmployee({ searchTerm })
    }
  }

  if (!roles.canViewMenuHREmployee) {
    return <NoRoleAccess roleName={'Menu - HR - Employee'} />
  }
  return (
    <Card sx={{ width: '45%' }}>
      <CardHeader title="Employee" />
      <CardContent>
        <Grid container spacing={2} direction="column">
          <Grid item xs={12}>
            <TextField
              label="Search Employee By PositionID"
              variant="outlined"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              inputProps={{
                maxLength: 9,
              }}
              style={{ width: '20rem' }}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              onClick={handleSyncButtonClick}
              disabled={isLoading || searchTerm.length < 9}
            >
              {isLoading ? (
                <CircularProgress size={24} />
              ) : (
                'Sync Down From ADP'
              )}
            </Button>
          </Grid>
          {errors.length > 0 && (
            <Grid item xs={12}>
              <Box mt={2} mb={2} textAlign="left">
                {errors.map((error, index) => (
                  <Box key={index} display="block">
                    <Typography
                      color="error"
                      data-testid={`employeeInfo-error-${index}`}
                    >
                      {error}
                      {index < errors.length - 1 && (
                        <>
                          <br />
                          <br />
                        </>
                      )}
                    </Typography>
                  </Box>
                ))}
              </Box>
            </Grid>
          )}
          {employeeInfo && (
            <Grid item container spacing={2}>
              {employeeInfoFields.map(({ key, title, format }, index) => (
                <Grid item xs={12} sm={4} key={index}>
                  <Box textAlign="left">
                    <Typography
                      variant="subtitle1"
                      style={{ color: theme.palette.secondary.dark }}
                    >
                      {title}
                    </Typography>
                    <Typography
                      component="div"
                      data-testid={`employeeInfo-${key}`}
                      style={{ wordBreak: 'break-all' }}
                    >
                      {format ? format(employeeInfo) : employeeInfo[key]}
                    </Typography>
                  </Box>
                </Grid>
              ))}
            </Grid>
          )}
        </Grid>
      </CardContent>
    </Card>
  )
}
