import { Box, Button } from '@mui/material'
import { LogoutForm } from 'components/LogoutForm'
import { useClasses } from 'hooks/useClasses'
import { ReturnUrl } from '../DiscountHelper'
import { discountButtonStyle } from '../DiscountTheme'

const styles = {
  signOutBtn: {
    float: 'right',
    marginLeft: 'auto',
  },
}

export function SignOutButton() {
  const classes: any = useClasses(styles)
  return (
    <Box className={classes.signOutBtn}>
      <LogoutForm returnUrl={ReturnUrl()}>
        <Button
          variant="outlined"
          style={{
            ...discountButtonStyle,
            width: '130px',
            height: '30px',
          }}
          type="submit"
        >
          Sign out
        </Button>
      </LogoutForm>
    </Box>
  )
}
