import { Box } from '@mui/material'
import { ReactNode } from 'react'

export function FullHeightBox({ children }: { children: ReactNode }) {
  return (
    <Box display="flex" minHeight="100vh" flexDirection="column">
      {children}
    </Box>
  )
}
