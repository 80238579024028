import ThumbDownIcon from '@mui/icons-material/ThumbDown'
import ThumbUpIcon from '@mui/icons-material/ThumbUp'
import '../DiscountApp.css'

interface ThumbIconProps {
  thumbsUp: boolean
}
// eslint-disable-next-line import/no-default-export
export default function ThumbIcon({ thumbsUp }: ThumbIconProps) {
  const thumbStyle = {
    borderRadius: '30px',
    backgroundColor: thumbsUp ? 'green' : 'red',
    padding: '13px',
    height: '70px',
    width: '70px',
    fill: 'white',
  }
  return thumbsUp ? (
    <ThumbUpIcon style={thumbStyle} />
  ) : (
    <ThumbDownIcon style={thumbStyle} />
  )
}
