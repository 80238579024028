import { Box, Typography } from '@mui/material'
import LogoutLogo from '../assets/LOGOUT_ICON.svg'

export function LogoutMenuButton({ buttonText = 'Logout' }) {
  return (
    <button
      type="submit"
      style={{
        border: 'none',
        width: '100%',
        padding: '10px',
        outline: 'none',
        backgroundColor: 'transparent',
      }}
    >
      <Box display="flex" flexDirection="row">
        <Box>
          <img
            src={LogoutLogo}
            alt="logout"
            style={{
              marginLeft: '5px',
            }}
          />
        </Box>
        <Box style={{ marginLeft: '11px' }}>
          <Typography variant="subtitle2" style={{ fontSize: '15px' }}>
            {buttonText}
          </Typography>
        </Box>
      </Box>
    </button>
  )
}
