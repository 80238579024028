import { Button } from '@mui/material'
import { getAdpLoginUrl } from 'api'
import ADP from '../../assets/adp_logo_white.svg'
import { discountButtonStyle } from '../DiscountTheme'
import { XsrfTokenInput } from 'components/xsrf/XsrfTokenInput'

interface Props {
  returnUrl: string
}
export function AdpLoginButton({ returnUrl }: Props) {
  return (
    <form action={getAdpLoginUrl(returnUrl)} method="POST">
      <XsrfTokenInput />
      <Button
        variant="outlined"
        style={discountButtonStyle}
        startIcon={<img src={ADP} height="16px" alt="ADP Logo" />}
        type="submit"
      >
        Sign-in with ADP
      </Button>
    </form>
  )
}
