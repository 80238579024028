import PersonIcon from '@mui/icons-material/Person'
import PhoneIcon from '@mui/icons-material/Phone'
import StoreIcon from '@mui/icons-material/Store'
import { Box, Container, Grid, Typography } from '@mui/material'
import Link from '@mui/material/Link'
import { FullScreenBox } from 'components/FullScreenBox'
import { useClasses } from 'hooks/useClasses'
import { useTitle } from 'hooks/useTitle'
import React, { useMemo } from 'react'
import { HomePageCard } from '../components/HomePageCard'
import { useUser } from '../context/Authenticate'
import {
  CHANGE_OF_STATUS_PATH,
  ESIGN,
  STOREALIGNMENT_PATH,
} from '../link-paths'
import { EsignCard } from 'components/EsignCard'
import EsignLogo from '../assets/EsignLogo.png'

const ESignMainDashboard = React.lazy(
  () => import('components/EsignMainDashboard')
)

const cardValues = [
  {
    path: CHANGE_OF_STATUS_PATH,
    name: 'Employment Change',
    role: 'canViewEmploymentChange',
    icon: PersonIcon,
  },
  {
    path: STOREALIGNMENT_PATH,
    name: 'Store Alignment',
    role: 'canViewStoreAlignments',
    icon: StoreIcon,
  },
]

const imageCardValues = [
  {
    path: ESIGN,
    name: 'eSign',
    role: 'canViewHrEsign',
    image: EsignLogo,
  },
]

export const handleNextStep = (
  completed,
  setCompleted,
  steps,
  step,
  navigate,
  getNextPage
) => {
  const updated = completed
  updated[steps[step].url] = true
  if (step === steps.length - 2) {
    updated[steps[step + 1].url] = true
  }
  setCompleted(updated)
  navigate(getNextPage())
}

const styles = {
  grid: {
    paddingTop: '25px',
  },
  gradientLine: {
    height: '2px',
    borderRadius: '1px',
    background: 'linear-gradient(90deg, #13293b, #00A6ED)',
    margin: '1.5rem 0 0 0',
  },
}

function MissingUserPermissions() {
  const helpdeskEmail = 'helpdesk@wksusa.com'
  return (
    <FullScreenBox>
      <Box mx={18}>
        <Typography variant="h5">
          Oops! It looks we need to give you some permissions. Please contact
          the WKS Help Desk at{' '}
          <a
            href={`mailto:${helpdeskEmail}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {helpdeskEmail}
          </a>
        </Typography>
      </Box>
    </FullScreenBox>
  )
}

export const missingUserPermissions = <MissingUserPermissions />

export function Home() {
  useTitle('Home')
  const classes: any = useClasses(styles)
  const { roles } = useUser()

  const hasAnyRequiredRoles = useMemo(
    () =>
      cardValues.some((cardValue) => roles[cardValue.role]) ||
      imageCardValues.some((cardValue) => roles[cardValue.role]),
    [roles]
  )
  return (
    <Container>
      {roles.canViewHrEsignList && (
        <>
          <ESignMainDashboard />
          <Box className={classes.gradientLine} />
        </>
      )}
      <Grid container justifyContent="center">
        <Grid
          container
          direction="row"
          justifyContent="center"
          spacing={4}
          className={classes.grid}
        >
          {hasAnyRequiredRoles ? (
            <>
              {cardValues.map((values) => {
                return (
                  roles[values.role] && (
                    <Grid
                      item
                      alignContent="center"
                      md={4}
                      xs={6}
                      key={values.name}
                    >
                      <HomePageCard
                        icon={<values.icon />}
                        buttonText={values.name}
                        to={values.path}
                      />
                    </Grid>
                  )
                )
              })}
              {imageCardValues.map((values) => {
                return (
                  roles[values.role] && (
                    <Grid
                      item
                      alignContent="center"
                      md={4}
                      xs={6}
                      key={values.name}
                    >
                      <EsignCard
                        to={values.path}
                        image={values.image}
                        name={values.name}
                      />
                    </Grid>
                  )
                )
              })}
            </>
          ) : (
            <>{missingUserPermissions}</>
          )}
        </Grid>
        {roles.canViewHrAndFaqLinks && (
          <Grid item>
            <Box sx={{ margin: '60px' }}>
              <Typography mb={1} sx={{ scrollMarginTop: 100 }} fontSize="lg">
                {"Do you need someone's contact information?"}
              </Typography>
              <Typography>
                {'Check out the '}
                <Link
                  href="https://docs.google.com/spreadsheets/d/1HOr7tfPP4UJl7QMckiMP21VxDjY2D4G3PAJW4sk8PeY/edit#gid=389260281"
                  underline="always"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <PhoneIcon color="info" /> {'Support Center Phone List'}
                </Link>
              </Typography>
            </Box>
          </Grid>
        )}
      </Grid>
    </Container>
  )
}
