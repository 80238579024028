import { Link, Typography } from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'
import { TITLE_JOB_CODE_CHANGE_PATH } from '../link-paths'

export const BorrowInfoMessage = () => {
  return (
    <>
      <Typography align="left">
        Borrow requests do not require approval.
      </Typography>
      <Typography align="left">
        Most requests will be processed by Aloha within one hour.
      </Typography>
      <Typography align="left">
        <b>
          The borrowed employee will be in the POS system at the end-of-day.
        </b>
      </Typography>
    </>
  )
}

export const POSJobCodeInfoMessage = () => {
  return (
    <>
      <Typography align="left" paragraph>
        POS Job Code requests do not require approval.{' '}
        <b>Please allow 1 - 5 hours for Aloha to be updated</b>. You will
        receive an email when the request has been completed.
      </Typography>
      <Typography align="left">
        Changing the Aloha Job Code will not change the employee{"'"}s title in
        ADP. To change the Job Title, please complete a{' '}
        <u>
          <Link
            target="_blank"
            rel="noopener noreferrer"
            component={RouterLink}
            to={TITLE_JOB_CODE_CHANGE_PATH}
          >
            Job Title Request
          </Link>
        </u>
        .
      </Typography>
    </>
  )
}

export const RehireFranchisorsInfoMessage = () => {
  return (
    <>
      <Typography align="left">
        Before you make a job offer, please ask the candidate if they previously
        worked for ANY of our brands.
      </Typography>
    </>
  )
}

export const RehireEmployeeInfoMessage = () => {
  return (
    <>
      If the employee you are looking for is not in this list, you may make a
      job offer to the candidate.
    </>
  )
}

export const RehireReviewInfoMessage = () => {
  return (
    <>
      This is a Rehire. The employee MUST complete a TalentReef application.
      Please go to{' '}
      <Link href="https://jobappnetwork.com/" color="inherit">
        TalentReef
      </Link>{' '}
      to onboard the new employee
    </>
  )
}
export const ReinstatementReviewInfoMessage = () => {
  return (
    <>
      Reinstatements require HR Approval <b>BEFORE</b> the employee can work
      again. HR must verify that the most recent Employee Handbook has been
      signed <b>BEFORE</b> the employee starts work again. You will get an email
      after HR Approval and the employee is rehired in ADP.
    </>
  )
}

export const ReinstatementEligibilityInfoMessage = () => {
  return (
    <>
      I understand that <b>BEFORE</b> I can place this employee on the schedule,
      HR Approval is required. HR must verify that the most recent Employee
      Handbook has been signed <b>BEFORE</b> the employee starts work again.
    </>
  )
}
