import {
  DatePickerProperties,
  StandardDatePicker,
} from 'components/StandardDatePicker'
import { Employee } from 'types/Employee'
import { StepInfo, StepProps } from 'types/StepProps'
import dayjs from 'dayjs'

export const lastDayWorkedStep: StepInfo = {
  cardLabel: 'Last Day of Work?',
  stepLabel: 'Last Day Worked',
  url: 'last-day-worked',
}

interface LastDayWorkedProps
  extends StepProps<
    {
      lastDayWorked: Date | null
      dateRange?: Employee['dateRange']
    },
    Date
  > {
  DatePickerProps?: DatePickerProperties
}
// eslint-disable-next-line import/no-default-export
export default function LastDayWorked({
  data: { lastDayWorked, dateRange },
  setData,
  DatePickerProps,
}: LastDayWorkedProps) {
  return (
    <StandardDatePicker
      dateValue={dayjs(lastDayWorked)}
      DatePickerProps={DatePickerProps}
      onChange={setData}
      dateRange={dateRange}
    />
  )
}
